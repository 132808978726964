export const SALES_RATE_TYPE = [
  { key: 'forecast', value: 'forecast' },
  { key: 'historical sales', value: 'historical' },
]
export const MIN_AVG_BATCHES_PER_CYCLE = [
  1.0,
  1.1,
  1.2,
  1.3,
  1.4,
  1.5,
  1.6,
  1.7,
  1.8,
  1.9,
  2.0,
].map((value) => ({ key: value.toString(), value }))

export const PLAN_TEMPLETE_BASED_ON_OPTIONS = [
  { key: 'Line only', value: 'Line only' },
  { key: 'Combined maker and line', value: 'Combined maker and line' },
]
