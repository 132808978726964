import { Table } from 'supplyvue-ui'
import { Column } from 'react-table'
import {
  InventoryDriverTableProps,
  InventoryDriverColumn,
} from './InventoryDrivers.types'
import { Loader } from 'Common/Loader/Loader'

export const InventoryDriverTable = ({
  data,
  isLoading,
}: InventoryDriverTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 300
    const availableHeight = window.innerHeight - 690
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const columns: Column<InventoryDriverColumn>[] = [
    {
      Header: 'Scenario',
      accessor: 'scenario',
      disableFilters: true,
      disableSortBy: true,
      columnWidth: 120,
    },
    {
      Header: 'Average cycle speed(days)',
      accessor: 'averageCycleSpeed',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props) => props.value,
      columnWidth: 120,
    },
    {
      Header: 'Average FOQ quantity(days)',
      accessor: 'averageFOQQuantity',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props) => props.value,
      columnWidth: 140,
    },
    {
      Header: 'FOQ volume %',
      accessor: 'FOQVolume',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props) => props.value,
    },
    {
      Header: 'Weighted service level',
      accessor: 'weightedServiceLevel',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props) => props.value,
    },
    {
      Header: 'Planning period',
      accessor: 'planningPeriod',
      disableFilters: true,
      disableSortBy: true,
      numeric: true,
      Cell: (props) => props.value,
    },
  ]
  if (isLoading) {
    return <Loader />
  }
  return (
    <Table
      data={data}
      height={getTableHeight()}
      columns={columns}
      cellPadding="5px 5px"
    />
  )
}
