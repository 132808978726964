import { WorkcenterSettingsView } from 'Features/PlanTemplate/WorkcenterSettings/WorkcenterSettings.view'
import { ProductSettingsView } from 'Features/PlanTemplate/ProductSettings/ProductSettings.view'
import { ProductChangeoverView } from 'Features/PlanTemplate/ProductChangeover/ProductChangeover.view'
import { BatchSettingsView } from 'Features/PlanTemplate/BatchSettings/BatchSettings.view'
import { BatchChangeoverView } from 'Features/PlanTemplate/BatchChangeover/BatchChangeover.view'
import { StepContentProps } from './StepContent.types'

import { WORKCENTER_STEPS } from '../StepCard/StepCard.constants'
import { PlanTemplateGenerator } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.view'
import { CURRENT_TEMPLATE_PARAMETERS } from 'Features/PlanTemplate/WorkcenterSettings/WorkcenterSettings.constants'

export const StepContentView = ({
  step,
  scenarioSettingsState,
  workcentreSettingsState,
  updateWorkcentreSettingsState,
  dropdownOptions,
  batchGroupingCodes,
  downloadFile,
  uploadFile,
  isFileUploading,
  uploadFileData,
  setUploadedFile,
  setIsPrimaryButtonDisabled,
  planTemplateGenerationState,
  updatePlanTemplateGenerationState,
  handleCalculatePlanTemplate,
  isCalculating,
  handlePlanDetailsClick,
  handleEditableMatrixCellUpdate,
  batchGroupingChangeOversSummary,
  productChangeOversSummary,
  batchChangeovers,
  productChangeovers,
  graphData,
}: StepContentProps) => {
  const {
    settings,
    productSettings,
    batchGroupingSettings,
    snapshotDetails,
    settingsOptions,
  } = workcentreSettingsState

  const { batchChangeoverData, isBatchChangeoverFetched } = batchChangeovers

  const { productChangeoverData, isProductChangeoverFetched } =
    productChangeovers
  /** Begin - Workcentre settings tab handlers */
  const handleApplyDefaultsClick = () => {
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.SETTINGS,
      key: 'salesRateType',
      value: scenarioSettingsState.salesRateType,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.SETTINGS,
      key: 'salesRatePeriod',
      value: scenarioSettingsState.salesRatePeriod,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.SETTINGS,
      key: 'minimumAverageBatchesPerProductionCycle',
      value: scenarioSettingsState.minimumAverageBatchesPerProductionCycle,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.SETTINGS,
      key: 'templateBasedOn',
      value: scenarioSettingsState.templateBasedOn,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.SETTINGS,
      key: 'settingsOptions',
      value: CURRENT_TEMPLATE_PARAMETERS,
    })
  }
  /** End - Workcentre settings tab handlers */

  switch (step) {
    case 0:
      return (
        <WorkcenterSettingsView
          scenarioSettingsTitle="Scenario settings"
          salesRateTypeLabel="Sales rate type"
          salesRatePeriodLabel="Sales rate period"
          minBatchesPerCycleLabel="Min. average batches per production cycle"
          salesRateType={settings?.salesRateType}
          salesRatePeriod={settings?.salesRatePeriod}
          settingsOption={settingsOptions}
          minBatchesPerCycle={settings?.minimumAverageBatchesPerProductionCycle}
          handleApplyDefaultsClick={handleApplyDefaultsClick}
          updateWorkcentreSettingsState={updateWorkcentreSettingsState}
          snapshotDetails={snapshotDetails}
          setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
          planTemplateBasedOnLabel="Plan template for"
          planTemplateBasedOn={settings.templateBasedOn}
        />
      )
    case 1:
      return (
        <ProductSettingsView
          productSettingsTabledata={productSettings}
          batchGroupingSettingsData={batchGroupingSettings}
          updateWorkcentreSettingsState={updateWorkcentreSettingsState}
          dropdownOptions={dropdownOptions}
          batchGroupingCodes={batchGroupingCodes}
          settingsOption={settingsOptions}
        />
      )
    case 2:
      return (
        <ProductChangeoverView
          changeoverCountLabel="Changeover count"
          changeoverTimeLabel="Changeover time"
          changeoverCostLabel="Changeover cost"
          productChangeoverData={
            productChangeOversSummary?.productChangeOversSummaryData
          }
          isProductChangeoverSummaryLoading={
            productChangeOversSummary?.isProductChangeOversSummaryLoading
          }
          refetchProductChangeoverSummaryData={
            productChangeOversSummary?.refetchProductChangeoverSummaryData
          }
          productChangeOverTime={productChangeoverData?.productChangeOverTime}
          productChangeOverCost={productChangeoverData?.productChangeOverCost}
          productChangeOverId={productChangeoverData?.productChangeOverId}
          productChangeOverCarbonCost={
            productChangeoverData?.productChangeOverCarbonCost
          }
          updateWorkcentreSettingsState={updateWorkcentreSettingsState}
          updateProductChangeoverCells={handleEditableMatrixCellUpdate}
          uploadFileData={uploadFileData}
          uploadFile={uploadFile}
          isFileUploading={isFileUploading}
          setUploadedFile={setUploadedFile}
          isProductChangeoverFetched={isProductChangeoverFetched}
        />
      )
    case 3:
      return (
        <BatchSettingsView
          batchGroupingSettingsData={batchGroupingSettings}
          updateWorkcentreSettingsState={updateWorkcentreSettingsState}
          dropdownOptions={dropdownOptions}
          templateBasedOn={settings?.templateBasedOn}
        />
      )
    case 4:
      return (
        <BatchChangeoverView
          downloadFile={downloadFile}
          uploadFile={uploadFile}
          isFileUploading={isFileUploading}
          uploadFileData={uploadFileData}
          setUploadedFile={setUploadedFile}
          changeoverSummaryData={
            batchGroupingChangeOversSummary?.batchGroupingChangeOversSummaryData
          }
          isBatchGroupingChangeoverSummaryLoading={
            batchGroupingChangeOversSummary?.isBatchGroupingChangeoverSummaryLoading
          }
          refetchBatchChangeoverSummaryData={
            batchGroupingChangeOversSummary?.refetchBatchChangeoverSummaryData
          }
          batchGroupingChangeOverTime={
            batchChangeoverData?.batchGroupingChangeOverTime
          }
          batchGroupingChangeOverCost={
            batchChangeoverData?.batchGroupingChangeOverCost
          }
          batchGroupingChangeOverId={
            batchChangeoverData?.batchGroupingChangeOverId
          }
          updateWorkcentreSettingsState={updateWorkcentreSettingsState}
          updateBatchGroupingChangeoverCells={handleEditableMatrixCellUpdate}
          isBatchChangeoverFetched={isBatchChangeoverFetched}
        />
      )
    case 5:
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PlanTemplateGenerator />
        </div>
      )
    default:
      return <div>No content</div>
  }
}
