import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import {
  ImportSnapshotVariables,
  UseImportSnapshotParams,
  UpdateProjectParams,
  useCreateScenarioDataParams,
  useDeleteScenarioDataParams,
  CreateScenarioVariables,
  DeleteScenarioVariables,
  Scenario,
  INITIATED,
  PROCESSING,
  UpdateCostOfCapitalVariables,
  ProjectDetails,
  CloneScenarioParams,
  CLONING,
  DownloadDataPayload,
} from './ProjectDetails.types'

/* Begin fetch project details api */

const fetchProjectData = async (projectId: string) => {
  const { data } = await request({
    url: `/projects/${projectId}/details`,
  })
  return data
}

export const useProjectData = (projectId: string) => {
  // fetchProject is used to poll the project-details untill all the
  //safety stock calculations are COMPLETE or CLONING a scenario is COMPLETE
  const [shouldRefetch, setShouldRefetch] = useState(false)
  return useQuery(
    ['project-details', projectId],
    () => fetchProjectData(projectId),
    {
      select: (data) => data.data,
      onSuccess: (data) => {
        if (data) {
          const { scenariosList, isCloning } = data
          if (isCloning) {
            setShouldRefetch(true)
          }
          const status: Scenario | undefined = scenariosList.find(
            (scenario: Scenario) =>
              scenario.safetyStockCalculated === PROCESSING ||
              scenario.safetyStockCalculated === INITIATED ||
              scenario.status === CLONING
          )
          setShouldRefetch(!!status)
        }
      },
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 10000 : false,
    }
  )
}

/* End fetch project details api */

/* Begin update costOfCapital api */
const updateCostOfCapital = async ({
  projectId,
  snapshotId,
  costOfCapital,
}: UpdateCostOfCapitalVariables) => {
  if (!snapshotId) {
    return
  }
  const { data } = await request({
    url: `/projects/${projectId}/details`,
    method: 'put',
    data: {
      snapshotId,
      costOfCapital,
    },
  })
  return data
}

export const useUpdateCostOfCapital = ({
  onSuccess,
  onError,
}: UseImportSnapshotParams) => {
  const queryClient = useQueryClient()
  return useMutation(updateCostOfCapital, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('project-details')
      onSuccess(data.message)
    },
    onError: ({ response }) => {
      onError(response.data.error)
    },
  })
}

/* End update costOfCapitalapi */

/* Begin update project api */

const updateProject = async (updateProjectParams: UpdateProjectParams) => {
  const { data } = await request({
    url: `/projects`,
    method: 'put',
    data: {
      ...updateProjectParams,
    },
  })
  return data
}

export const useUpdateProject = ({
  onSuccess,
  onError,
}: UseImportSnapshotParams) => {
  const queryClient = useQueryClient()
  return useMutation(updateProject, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('project-details')
      onSuccess(data.message)
    },
    onError: ({ response }) => {
      onError(response.data.error)
    },
  })
}

/* End update project api */

/* Begin fetch work centres list api */
const fetchSiteWorkcentres = async (userId: string, projectId: string) => {
  const { data } = await request({
    url: `users/${userId}/projects/${projectId}/site-workcentres`,
  })
  return data
}

export const useWorkcentresData = (
  userId: string,
  projectId: string,
  snapshotId: string
) => {
  return useQuery(
    ['site-workcentres', projectId, userId, snapshotId],
    () => fetchSiteWorkcentres(userId, projectId),
    {
      select: (data) => data.data,
      refetchOnWindowFocus: false,
      enabled: !!userId && !!snapshotId,
    }
  )
}
/* End work centres list  api */

/* Begin Snapshot  modal api */

const fetchSnapshotList = async () => {
  const { data } = await request({
    url: `/snapshots`,
  })
  return data
}

export const useSnapshotData = () => {
  return useQuery(['snapshots'], () => fetchSnapshotList(), {
    select: (data) => data.data,
    refetchOnWindowFocus: false,
  })
}

const createSnapshot = async ({
  snapshotName,
  endDate,
  snapshotLength,
  projectId,
  projectUserId,
}: ImportSnapshotVariables) => {
  const dateString = format(endDate, 'yyyyMMdd')
  const { data } = await request({
    url: `/snapshots`,
    method: 'post',
    data: {
      projectId,
      projectUserId,
      dataSnapshotName: snapshotName,
      snapshotEndDate: dateString,
      snapshotLength: snapshotLength,
    },
  })
  return data
}

const fetchImportSnapshotStatus = async (snapshotId: string | null) => {
  if (!snapshotId) {
    return
  }
  const { data } = await request({
    url: `/snapshots/${snapshotId}/status`,
  })
  return data
}

export const useImportSnapshotData = ({
  onSuccess,
  onError,
}: UseImportSnapshotParams) => {
  const queryClient = useQueryClient()
  const [snapshotId, setSnapshotId] = useState<null | string>(null)
  const [shouldRefetch, setShouldRefetch] = useState(false)

  const mutation = useMutation(createSnapshot, {
    onSuccess: ({ data }) => {
      setSnapshotId(data.snapshotId)
      setShouldRefetch(true)
    },
    onError: ({ resposne }) => {
      onError(resposne.data.error)
    },
  })

  useQuery(
    ['importSnapshotStatus', snapshotId],
    () => fetchImportSnapshotStatus(snapshotId),
    {
      onSuccess: async (response) => {
        if (response.data.status === 'Completed') {
          setShouldRefetch(false)
          setSnapshotId(null)
          if (snapshotId) {
            await queryClient.invalidateQueries('project-details')
            onSuccess(response.message)
          }
        } else if (response.data.status === 'Failed') {
          setShouldRefetch(false)
          setSnapshotId(null)
          onError(response.message)
        }
      },
      onError: ({ response }) => {
        onError(response.data.error)
      },
      enabled: snapshotId !== null,
      refetchInterval: shouldRefetch ? 10000 : false,
    }
  )

  return mutation
}

/* End Snapshot  modal api */

/** Begin - Create Scenario API */

const createScenario = async ({
  projectId,
  projectUserId,
  scenarioName,
  snapshotId,
  snapshotUserId,
  sequenceId,
}: CreateScenarioVariables) => {
  const { data } = await request({
    url: `/scenarios`,
    method: 'post',
    data: {
      projectId,
      projectUserId,
      scenarioName,
      snapshotId,
      snapshotUserId,
      sequenceId,
    },
  })
  return data
}

export const useCreateScenarioData = ({
  onCreateScenarioSuccess,
  onCreateScenarioError,
}: useCreateScenarioDataParams) => {
  const queryClient = useQueryClient()

  return useMutation(createScenario, {
    onSuccess: async ({ message }) => {
      await queryClient.invalidateQueries('project-details')
      onCreateScenarioSuccess(message)
    },
    onError: ({ response }) => {
      queryClient.invalidateQueries('project-details')
      onCreateScenarioError(response.data.error)
    },
  })
}

/** End - Create Scenario API */

/** Begin - Delete Scenario API */

const deleteScenario = async ({
  projectId,
  projectUserId,
  scenarioId,
}: DeleteScenarioVariables) => {
  const { data } = await request({
    url: `/scenarios/${scenarioId}?project_id=${projectId}&project_user_id=${projectUserId}`,
    method: 'delete',
  })
  return data
}

export const useDeleteScenarioData = ({
  onDeleteScenarioSuccess,
}: useDeleteScenarioDataParams) => {
  const queryClient = useQueryClient()

  return useMutation(deleteScenario, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries('project-details')
      onDeleteScenarioSuccess(message)
    },
  })
}

/** End - Delete Scenario API */

const getDownloadData = async ({
  projectDetails,
  downloadMode,
}: DownloadDataPayload) => {
  const { data } = await request({
    method: 'post',
    data: { projectDetails: projectDetails, downloadMode: downloadMode },
    url: `/plan-templates/scenarios-download`,
  })

  return data
}

export const useDownloadData = (
  onSuccess: (data: any) => void,
  onError: () => void
) => {
  return useMutation(getDownloadData, {
    onSuccess: (data) => {
      onSuccess(data)
    },
    onError: () => {
      onError()
    },
  })
}


const cloneScenario = async (params: CloneScenarioParams) => {
  const { data } = await request({
    method: 'post',
    data: params,
    url: `/scenarios/clone-scenario`,
  })

  return data
}

export const useCloneScenario = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation(cloneScenario, {
    onSuccess: async (_, variables: CloneScenarioParams) => {
      await queryClient.invalidateQueries([
        'project-details',
        variables.projectId,
      ])
      onSuccess()
    },
    onError: () => {
      onError()
    },
    // onMutate: async () => {
    //await queryClient.invalidateQueries('project-details')
    // },
  })
}
