import React, { useEffect, useState, memo } from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { useStyles } from './PlanTemplateGeneration.styles'
import { useGeneratingStatuses } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.data'
import { useParams } from 'react-router-dom'

import {
  GeneratingStatus,
  PlanTemplateParams,
  StatusItem,
  ResponseType,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.types'
import { TemplateDetails } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.TemplateDetails.view'
import { CreateTemplate } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.CreateTemplate.view'

export const PlanTemplateGenerator = () => {
  const { planTemplateId, workcentreId } = useParams<PlanTemplateParams>()

  const classes = useStyles()

  const { data: statusData, isLoading } = useGeneratingStatuses(planTemplateId)

  const initialStatus: GeneratingStatus = {
    constrained: 'UNSTARTED',
    unconstrained: 'UNSTARTED',
    cost: 'UNSTARTED',
    campaign: 'UNSTARTED',
  }

  const [fetchedStatus, setFetchedStatus] =
    useState<GeneratingStatus>(initialStatus)

  const convertToGeneratingStatus = (
    response: ResponseType
  ): GeneratingStatus => {
    const statusMapping: Record<string, keyof GeneratingStatus> = {
      carbonCostGenerated: 'cost',
      unconstrainedTemplateGenerated: 'unconstrained',
      costOptimizedGenerated: 'campaign',
      templateGenerated: 'constrained',
    }

    const statusObject = response.Statuses.find(
      (status: StatusItem) => status.workcentreKey.toString() === workcentreId
    )

    const newGeneratingStatus: GeneratingStatus = Object.entries(
      statusMapping
    ).reduce(
      (acc, [key, generatingKey]) => {
        acc[generatingKey] = statusObject[key]
        return acc
      },
      { ...initialStatus }
    )

    return newGeneratingStatus
  }

  useEffect(() => {
    if (statusData) {
      const new_status = convertToGeneratingStatus(statusData)
      setFetchedStatus(new_status)
    }
  }, [statusData])

  type CreateTemplatePageParams = {
    fetchedStatus: GeneratingStatus
    isLoading: boolean
  }

  const CreateTemplatePage = ({
    fetchedStatus,
    isLoading,
  }: CreateTemplatePageParams) => {
    const [generatingStatus, setGeneratingStatus] =
      useState<GeneratingStatus>(fetchedStatus)

    return (
      <Box style={{ border: '0px dashed blue' }}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{ height: '100%', alignItems: 'center' }}
        >
          <Grid item xs={6}>
            <Typography variant="h4">Generate Templates</Typography>
            <Paper
              elevation={0}
              style={{
                border: '2px solid lightgrey',
                marginTop: '8px',
                maxWidth: '900px',
                minWidth: '800px',
              }}
            >
              <CreateTemplate setGeneratingStatus={setGeneratingStatus} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0}>
              <TemplateDetails
                generatingStatus={generatingStatus}
                isLoading={isLoading}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '1800px',
        paddingTop: '8px',
      }}
    >
      <CreateTemplatePage fetchedStatus={fetchedStatus} isLoading={isLoading} />
    </Box>
  )
}
