import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppInsightsProvider } from 'Features/Error/AppInsightsProvider'
import { AppThemeProvider } from 'Features/App/AppThemeProvider'
import { Toaster } from 'supplyvue-ui'
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
} from '@azure/msal-react'
import { msalInstance } from '../../'
import { InteractionType } from '@azure/msal-browser'

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  })

  const request = {
    scopes: ['User.Read'], // Add the scopes your app needs
    account: msalInstance?.getActiveAccount() ?? undefined, // Use the logged-in account
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <AppThemeProvider>
            <Toaster />
            <AppInsightsProvider>{children}</AppInsightsProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </AppThemeProvider>
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={request}
      ></MsalAuthenticationTemplate>
    </MsalProvider>
  )
}
