import { PlanTemplateBulkUpdateParams } from 'Features/PlanTemplateSummary/BulkUpdate/BulkUpdate.types'
import { Modal, Button, DropDown, Input } from 'supplyvue-ui'
import { useStyles } from './BulkUpdate.styles'
import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'

export const INITIAL_STATE = {
  preferredMethod: 'None',
  capacityHoursActionType: '0',
  capacityHours: null,
  productionCostPerHourActionType: '0',
  productionCostPerHour: null,
  crewCostPerHourActionType: '0',
  crewCostPerHour: null,
}

export const PlanTemplateBulkUpdateView = ({
  isModalOpen,
  handleModalClose,
  isUpdating,
  handleSaveClick,
  formData,
  setFormData,
}: PlanTemplateBulkUpdateParams) => {
  const classes = useStyles()

  const actionOptions = [
    { key: 'Set value to', value: '0' },
    { key: 'Increase by percentage', value: '1' },
    { key: 'Decrease by percentage', value: '2' },
  ]

  const preferredMethodOptions = [
    { key: 'None', value: 'None' },
    { key: 'Constrained', value: 'constrained' },
    { key: 'Unconstrained', value: 'unconstrained' },
    { key: 'Campaign', value: 'carbonCost' },
    { key: 'Cost Optimized', value: 'costOptimized' },
  ]

  const isDataUpdated = () => {
    return true
  }

  const handleClearButtonClick = () => {
    setFormData(INITIAL_STATE)
  }

  const handleSaveButtonClick = async () => {
    handleSaveClick(formData)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== 'preferredMethod') {
      const VALID_NUMBER_PATTERN = /^[+]?((\.\d{0,5})|(\d+(\.\d{0,5})?)|(\d+\.))$/
      if (event.target.value === '' || value.match(VALID_NUMBER_PATTERN)) {
        setFormData({
          ...formData,
          [name]: value,
        })
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as string
    setFormData({
      ...formData,
      [name]: event.target.value as string,
    })
  }

  return (
    <Modal
      disableBackdropClick
      open={isModalOpen}
      handleClose={handleModalClose}
      header={'Plan Template Bulk Edit'}
      body={
        <Box className={classes.modal}>
          <Box className={classes.row}>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Metric</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Action</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Value</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Divider flexItem className={classes.divider} />
          </Box>
          <Box className={classes.row}>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Preferred Method</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Input
                  fullWidth={true}
                  ariaLabel="Set value to"
                  value="Set value to"
                  name="setValueTo"
                  disabled={true}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml={3}>
              <Box width="15rem">
                <DropDown
                  fullWidth={true}
                  label="Preferred Method"
                  name="preferredMethod"
                  value={formData.preferredMethod ?? 'None'}
                  menuItems={preferredMethodOptions}
                  onChange={handleDropdownChange}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Capacity Hours</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <DropDown
                  fullWidth={true}
                  label="CapacityHoursAction"
                  name="capacityHoursActionType"
                  defaultLabel="None"
                  value={formData.capacityHoursActionType}
                  menuItems={actionOptions}
                  onChange={handleDropdownChange}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml={3}>
              <Box width="15rem">
                <Input
                  fullWidth={true}
                  ariaLabel="Capacity Hours"
                  value={formData.capacityHours}
                  onChange={handleInputChange}
                  name="capacityHours"
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Production cost per hour</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <DropDown
                  fullWidth={true}
                  label="ProductionCostPerHour"
                  name="productionCostPerHourActionType"
                  value={formData.productionCostPerHourActionType}
                  menuItems={actionOptions}
                  onChange={handleDropdownChange}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml={3}>
              <Box width="15rem">
                <Input
                  fullWidth={true}
                  ariaLabel="Production Cost Per Hour"
                  value={formData.productionCostPerHour}
                  onChange={handleInputChange}
                  name="productionCostPerHour"
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <Typography variant="h4">Crew cost per hour</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box width="15rem">
                <DropDown
                  fullWidth={true}
                  label="CrewCostPerHour"
                  name="crewCostPerHourActionType"
                  value={formData.crewCostPerHourActionType}
                  menuItems={actionOptions}
                  onChange={handleDropdownChange}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" ml={3}>
              <Box width="15rem">
                <Input
                  fullWidth={true}
                  ariaLabel="Crew Cost Per Hour"
                  name="crewCostPerHour"
                  value={formData.crewCostPerHour}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.buttonRow}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClearButtonClick}
              disabled={isEqual(INITIAL_STATE, formData)}
            >
              Clear
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveButtonClick}
              disabled={isUpdating || !isDataUpdated()}
            >
              {isUpdating && (
                <CircularProgress
                  color="inherit"
                  size={16}
                  className={classes.progress}
                />
              )}
              Apply changes
            </Button>
          </Box>
        </Box>
      }
    />
  )
}
