import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  ScenariosView: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  button: {
    borderRadius: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    minHeight: window.innerHeight - 580 > 300 ? window.innerHeight - 580 : 300,
  },
  emptyTable: {
    display: 'flex',
    width: window.outerWidth + 200,
  }
}))
