import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'Features/App/App'
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser'
import { msalConfig } from 'Features/Auth/auth'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  ReactDOM.render(<App />, document.getElementById('root'))
})
