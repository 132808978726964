import {
  GeneratingStatus,
  IconProps,
  PlanTemplateParams,
  PreferredMethod,
  Status,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.types'
import { Loader } from 'Common/Loader/Loader'
import {
  CheckOutlined,
  ErrorOutline,
  PauseCircleOutline,
} from '@material-ui/icons'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { Stack } from '@mui/material'
import { Button } from 'supplyvue-ui'
import React from 'react'
import { useStyles } from './PlanTemplateGeneration.styles'
import { useParams, useHistory } from 'react-router-dom'

const StatusIcon = ({ status }: IconProps) => {
  switch (status) {
    case 'COMPLETED':
      return <CheckOutlined style={{ color: 'green' }} />
    case 'FAILED':
      return <ErrorOutline style={{ color: 'red' }} />
    case 'UNSTARTED':
      return <PauseCircleOutline style={{ color: 'gray' }} />
    case 'GENERATING':
      return <CircularProgress style={{ color: 'black' }} size={20} />
    case 'PROCESSING':
      return <CircularProgress style={{ color: 'black' }} size={20} />
    default:
      return <CircularProgress style={{ color: 'black' }} size={20} />
  }
}

export const TemplateDetails = ({
  generatingStatus,
  isLoading,
}: {
  generatingStatus: GeneratingStatus
  isLoading: boolean
}) => {
  const classes = useStyles()
  const history = useHistory()

  const {
    planTemplateId,
    workcentreId,
    sourceSiteId,
    snapshotId,
    projectId,
    scenarioId,
  } = useParams<PlanTemplateParams>()

  if (isLoading) {
    return <Loader />
  }

  const handleViewResults = (preferredMethod: PreferredMethod) => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteId}/${workcentreId}/plan-details?optimization=${preferredMethod}`
    )
  }

  const TemplateOption = ({
    status,
    preferredMethod,
    title,
    information,
  }: {
    status: Status
    preferredMethod: PreferredMethod
    title: string
    information: string
  }) => {
    return (
      <Paper elevation={0} className={classes.templateResultsCard}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={2}>
            <Box className={classes.iconContainer}>
              <StatusIcon status={status} />
            </Box>
            <Box className={classes.textContainer}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="subtitle1" style={{ color: 'grey' }}>
                Summary Information stating if it has been completed or not
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="small"
              disabled={status != 'COMPLETED'}
              onClick={() => handleViewResults(preferredMethod)}
            >
              View Results
            </Button>
          </Box>
        </Stack>
      </Paper>
    )
  }

  return (
    <Grid
      item
      container
      spacing={1}
      style={{
        padding: '8px 12px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Template Results</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <TemplateOption
          status={generatingStatus.constrained}
          preferredMethod="constrained"
          title={'Constrained'}
          information={'test'}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <TemplateOption
          status={generatingStatus.unconstrained}
          preferredMethod="unconstrained"
          title={'Unconstrained'}
          information={'test'}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <TemplateOption
          status={generatingStatus.cost}
          preferredMethod="carboncost"
          title={'Cost Optimized'}
          information={'test'}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <TemplateOption
          status={generatingStatus.campaign}
          preferredMethod="campaign"
          title={'Campaign'}
          information={'test'}
        />
      </Grid>
    </Grid>
  )
}
