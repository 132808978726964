import {
  ViewScenariosTableProps, ViewScenarioTableColumn
} from "./ViewScenario.types";
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { useMemo } from "react";
import { searchTextOperator } from "Common/MUITable/SearchText.operator";
import { useStyles } from "./ViewScenario.styles"
import { Typography } from "@mui/material";

export const ViewScenariosTable = ({ data }: ViewScenariosTableProps) => {
  const classes = useStyles()

  const tableData = data.map((item: any, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const renderPct = (value: number) => {
    return <div>{value} %</div>
  }

  const displayValue = (row: any) => {

    return (
      <div>
        {row.row.maxHours <= row.row.capacityHours ?
          <Typography color="#59A14F">{row.value}</Typography>
          :
          <Typography color="#B22222">{row.value}</Typography>

        }
      </div>
    )

  }

  const sourceSites = [
    ...new Set(
      data.map((item: ViewScenarioTableColumn) => item.sourceSite))
  ]

  const scenarios = [
    ...new Set(
      data.map((item: ViewScenarioTableColumn) => item.scenario)
    )
  ]

  const workcentres = [...new Set(
    data.map((item: ViewScenarioTableColumn) => item.workcentreCode)
  )]

  const MUIColumns: any[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'scenario',
        headerName: 'Scenario',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: scenarios,
              label: 'scenario',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'sourceSite',
        headerName: 'Source Site',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: sourceSites,
              label: 'source site',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'workcentreCode',
        headerName: 'Work Centre',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: workcentres,
              label: 'workcentre',
            }
          }
        ],
        type: 'string',
        flex: 1
      },
      {
        field: 'productsCount',
        headerName: 'Products Count',
        type: 'number',
        flex: 1
      },
      {
        field: 'weeklyVolume',
        headerName: 'Weekly Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'capacityHours',
        headerName: 'Capacity Hours',
        type: 'number',
        flex: 1
      },
      {
        field: 'averageWeeklyHours',
        headerName: 'Average Weekly Hours',
        type: 'number',
        flex: 1
      },
      {
        field: 'maxHours',
        headerName: 'Max Hours',
        type: 'number',
        renderCell: (row: any) => displayValue(row),
        flex: 1
      },
      {
        field: 'utilisation',
        headerName: 'Utilisation %',
        type: 'number',
        renderCell: (row: any) => renderPct(row.row.utilisation),
        flex: 1
      },
      {
        field: 'changeoverPct',
        headerName: 'ChangeOver %',
        type: 'number',
        renderCell: (row: any) => renderPct(row.row.changeoverPct),
        flex: 1
      },
      {
        field: 'cycleSpeed',
        headerName: 'Cycle Speed',
        type: 'number',
        flex: 1
      },

      {
        field: 'cycle1Count',
        headerName: 'Cycle 1',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle2Count',
        headerName: 'Cycle 2',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle4Count',
        headerName: 'Cycle 4',
        type: 'number',
        flex: 1
      },
      {
        field: 'cycle8Count',
        headerName: 'Cycle 8',
        type: 'number',
        flex: 1
      },
      {
        field: 'FOQCount',
        headerName: 'FOQ Count',
        type: 'number',
        flex: 1
      },
      {
        field: 'serviceLevel',
        headerName: 'Service Level %',
        type: 'number',
        flex: 1
      },
      {
        field: 'inventoryVolume',
        headerName: 'Inventory Volume',
        type: 'number',
        flex: 1
      },
      {
        field: 'inventoryDuration',
        headerName: 'Inventory Duration',
        type: 'number',
        flex: 1
      }
    ]
  },[data])

  return (
    <div className={data ? classes.tableContainer : classes.emptyTable}>
      <MUITable
        rows={tableData}
        disableRowSelectionOnClick
        columns={MUIColumns}
        experimentalFeatures={{ lazyLoading: true }}
        hideFooter={true}
      />
    </div>


  )
}
