import { useReducer } from 'react'
import { Optimization } from './WorkcentreConstraints/WorkcentreConstraints.types'
import {
  CycleSpeedDataResponse,
  PlanTemplateGenerationState,
  PlanTemplateGererationAction,
  SummaryDataResposne,
  Validation,
} from './PlanTemplateGeneration.types'
import {
  SET_BUFFER,
  SET_DEFAULT_WORKCENTRE_CAPACITY,
  SET_WORKCENTRE_CAPACITY,
  SET_OPTIMIZATION,
  SET_IS_TEMPLATE_GENERATING,
  SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
  SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED,
  SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING,
  SET_CYCLE_SPEED_DATA,
  SET_SUMMARY_DATA,
  SET_COMBINED_BUFFER,
  SET_COMBINED_WORKCENTRE_CAPACITY,
} from './PlanTemplateGeneration.constants'

const reducer = (
  state: PlanTemplateGenerationState,
  action: PlanTemplateGererationAction
) => {
  switch (action.type) {
    case SET_BUFFER:
      return {
        ...state,
        buffer: action.payload as string,
      }
    case SET_COMBINED_BUFFER:
      return {
        ...state,
        combinedBuffer: action.payload as string,
      }
    case SET_DEFAULT_WORKCENTRE_CAPACITY:
      return {
        ...state,
        defaultWorkCenterCapacity: action.payload as string,
      }
    case SET_WORKCENTRE_CAPACITY:
      return {
        ...state,
        workcentreCapacity: action.payload as string,
      }

    case SET_COMBINED_WORKCENTRE_CAPACITY:
      return {
        ...state,
        combinedWorkcentreCapacity: action.payload as string,
      }
    case SET_OPTIMIZATION:
      return {
        ...state,
        optimization: action.payload as Optimization,
      }
    case SET_IS_TEMPLATE_GENERATING:
      return {
        ...state,
        isTemplateGenerating: action.payload as boolean,
      }
    case SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS:
      return {
        ...state,
        planTemplateGererationValidations: action.payload as Validation[],
      }
    case SET_IS_INITIAL_CYCLE_SPEED_AND_SUMMARY_DATA_FETCHED:
      return {
        ...state,
        isInitialCycleSpeedAndSummaryDataFetched: action.payload as boolean,
      }
    case SET_IS_CYCLE_SPEED_OR_SUMMARY_DATA_LOADING:
      return {
        ...state,
        isCycleSpeedOrSummaryDataLoading: action.payload as boolean,
      }
    case SET_CYCLE_SPEED_DATA:
      return {
        ...state,
        cycleSpeedData: action.payload as CycleSpeedDataResponse,
      }
    case SET_SUMMARY_DATA:
      return {
        ...state,
        summaryData: action.payload as SummaryDataResposne,
      }
    default:
      return { ...state }
  }
}

export const usePlanTemplateGenerationState = (
  initialState: PlanTemplateGenerationState
) => {
  return useReducer(reducer, initialState)
}

export type FormData = {
  capacity: number | null
  buffer: number | null
  settingsOption: number
  preferredMethod: number
  templates: {
    constrained: boolean
    unconstrained: boolean
    cost: boolean
    campaign: boolean
  }
}

type FormActionType =
  | { type: 'SET_CAPACITY'; payload: number | null }
  | { type: 'SET_BUFFER'; payload: number | null }
  | { type: 'SET_SETTINGS_OPTION'; payload: number }
  | { type: 'SET_PREFERRED_METHOD'; payload: number }
  | {
      type: 'SET_TEMPLATE'
      template: keyof FormData['templates']
      payload: boolean
    }

const formReducer = (state: FormData, action: FormActionType): FormData => {
  switch (action.type) {
    case 'SET_CAPACITY':
      return { ...state, capacity: action.payload }
    case 'SET_BUFFER':
      return { ...state, buffer: action.payload }
    case 'SET_SETTINGS_OPTION':
      return { ...state, settingsOption: action.payload }
    case 'SET_PREFERRED_METHOD':
      return { ...state, preferredMethod: action.payload }
    case 'SET_TEMPLATE':
      return {
        ...state,
        templates: { ...state.templates, [action.template]: action.payload },
      }
    default:
      return state
  }
}

export const useFormState = (initial_state: FormData) => {
  return useReducer(formReducer, initial_state)
}
