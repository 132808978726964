import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  summaryGraphWrapper: {
    border: `1px solid ${theme.palette.borders.grey}`,
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  paper: {
    boxShadow: theme.shadows[2],
  },
  grid: {
    marginTop: '20px',
  },
  templateGeneratingCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: '20px',
  },
  innerBox: {
    borderRadius: '20px',
    height: '28%',
    width: '40%',
  },
  templateBox: {
    borderRadius: '20px',
    height: 700,
  },
  templateIcon: {
    fontSize: 150,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  iconBox: {
    width: '35%',
    color: theme.palette.primary.main,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid lightgrey`,
    borderRadius: 6,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#f0f8ff',
    },
    paddingRight: '14px',
  },
  selectedCard: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f0f8ff',
    borderRadius: 6,
    paddingRight: '14px',
  },
  checkedColor: {
    color: theme.palette.primary.main,
  },
  templateResultsCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '900px',
    padding: '12px',
    height: '75%',
    border: '2px solid lightgrey',
  },
  iconContainer: {
    display: 'flex',
    width: '80px',
    height: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
