import { SnapshotDetails } from 'Features/Project/SnapshotModal/SnapshotModal.types'
export type UseImportSnapshotParams = {
  onSuccess: (message: string) => void
  onError: (message: string) => void
}

export type UseSnapshotParams = {
  onSnaptshotsLoad: (snapshotId: string, projectId: string) => void
}

export type ProjectDetails = {
  createdOn: string
  projectId: string
  projectName: string
  projectUserId: string
  scenariosCount: number
  snapshotDetails: SnapshotDetails
  workcentresCount: number
}

export type DownloadDataPayload = {
  projectDetails: ProjectDetails[] | []
  downloadMode: string
}

export type ProjectDetailsParams = {
  projectId: string
}

export type ImportSnapshotVariables = {
  snapshotName: string
  endDate: Date
  snapshotLength: number
  projectId: string
  projectUserId: string
}

export type UpdateSiteWorkcentreParams = {
  siteKey: number
  siteWorkcentres: number[]
}

export type UpdateProjectParams = {
  projectId: string
  projectUserId: string
  snapshotId?: string
  snapshotUserId?: string
  siteWorkcentres?: UpdateSiteWorkcentreParams[]
}

export type UpdateCostOfCapitalVariables = {
  projectId: string
  snapshotId?: string
  costOfCapital: number
}

export type CreateScenarioVariables = {
  projectId: string
  projectUserId: string
  scenarioName: string
  snapshotId: string
  snapshotUserId: string
  sequenceId: number
}

export type DeleteScenarioVariables = {
  projectId: string
  projectUserId: string
  scenarioId: string
}

export type useCreateScenarioDataParams = {
  onCreateScenarioSuccess: (message: string) => void
  onCreateScenarioError: (message: string) => void
}

export type useDeleteScenarioDataParams = {
  onDeleteScenarioSuccess: (message: string) => void
}

export type ValidationError = {
  error: string
  msg: string[]
  status: string
}

export type ValidationErrorParam = {
  response: {
    data: ValidationError
  }
}

export type Scenario = {
  scenarioId: string
  scenarioName: string
  createdOn: string
  planTemplateId: string
  planTemplateName: string
  replenishmentPolicyId: string
  replenishmentPolicyName: string
  templateGenerationComplete: number
  templateGenerationPending: number
  safetyStockCalculated: string
  demandScenarioCount: number
  demandScenarioId: string
  demandScenarioName: string
  planGenerationStatus: string
  status?: string | null
  access: 'public' | 'private'
}

export type TriggerCalculateSafetyStock = {
  snapshotId: string
  projectId: string
  scenarioId: string
  snapshotLength: number
  snapshotEndDate: string
}

export type CloneScenarioParams = {
  scenarioId: string
  projectId: string
  scenarioName: string
}

export const SAFETYSTOCK_CALCULATION_IDLE = 'SAFETYSTOCK_CALCULATION_IDLE'
export const SAFETYSTOCK_CALCULATION_STARTED = 'SAFETYSTOCK_CALCULATION_STARTED'
export const SAFETYSTOCK_CALCULATION_COMPLETED =
  'SAFETYSTOCK_CALCULATION_COMPLETED'
export const SAFETYSTOCK_CALCULATION_CALCULATED_PREVIOUSLY =
  'SAFETYSTOCK_CALCULATION_CALCULATED_PREVIOUSLY'
export const SAFETYSTOCK_CALCULATION_FAILED = 'SAFETYSTOCK_CALCULATION_FAILED'

export type TriggerSafetyStockCalculationParams = {
  onTriggerSafetyStockCalculationSuccess: () => void
  onTriggetSafetyStockCalculationFail: (message: string) => void
  projectId: string
  scenarioId: string
  snapshotId: string
  onCalculationComplete: () => void
  onCalculationFail: () => void
}

export const COMPLETED = 'COMPLETED'
export const INITIATED = 'INITIATED'
export const PROCESSING = 'PROCESSING'
export const UNSTARTED = 'UNSTARTED'
export const FAILED = 'FAILED'
export const CLONING = 'CLONING'
