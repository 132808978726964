import React from 'react'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { useStyles } from './ViewScenario.styles'
import { ViewScenariosTable } from './ScenarioComparison.table'
import { Loader } from 'Common/Loader/Loader'
import { ViewScenarioParams, ViewScenariosTableProps } from "Features/ViewScenario/ViewScenario.types";
import { useParams } from "react-router-dom";
import { Button } from "supplyvue-ui";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

export const ViewScenarioPage = ({
                                   data, isLoading
                                 }: ViewScenariosTableProps) => {
  const classes = useStyles({ innerWidth: window.innerWidth })
  const { projectId } = useParams<ViewScenarioParams>()
  useBreadcrumbsData({
    project_id: projectId,
  })

  /*** End new implementation */

  const convertToCSV = (data: any) => {
    // Step 1: Extract headers
    const headers = Object.keys(data[0]);

    // Step 2: Map the data
    const csvRows = data.map((row: any) => {
      return headers.map(header => {
        const escapedValue = ('' + row[header]).replace(/"/g, '""'); // Escape quotes
        return `"${escapedValue}"`; // Wrap values in quotes
      }).join(',');
    });

    // Step 3: Combine headers and rows
    csvRows.unshift(headers.join(',')); // Add headers at the top
    return csvRows.join('\n');
  }

  const handleDownload = () => {
    const fileName = `Detailed_Analysis__${format(new Date(), 'yyyyMMdd')}`
    const url = window.URL.createObjectURL(new Blob([convertToCSV(data)]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    toast.success('Successfully downloaded Sku Data')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
      <div className={classes.tabContainer}>
        <div className={classes.header}>
          <Typography variant="h4">
            Scenario Analysis
          </Typography>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleDownload}
            startIcon={
                <GetAppIcon />
            }
          >
            Download
          </Button>
        </div>
        <div className={classes.ScenariosView}>
          <ViewScenariosTable
            data={data ?? []}
            isLoading={isLoading}
          />
        </div>
      </div>
  )
}
