import { Box, CircularProgress } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Page } from 'Common/Page/Page'
import { Loader } from 'Common/Loader/Loader'
import { toast } from 'react-hot-toast'
import {
  BulkEditPlanTmplate,
  LoadingStatusType,
  PlanTemplateFormData,
  PlanTemplateSummaryParams,
  PlanTemplateSummaryTableType,
  UpdatePlanTemplateVariables,
  WorkcentreModeExports,
} from './PlanTemplateSummary.types'
import {
  usePlanTemplateSummaryData,
  useUpdatePlanTemplateSummary,
  usePlanTemplateSettingsData,
  useCalculateMultiplePlanTemplates,
  useGeneratingStatuses,
  useExportPlanTemplates,
} from './PlanTemplateSummary.data'
import { PlanTemplateSummaryTable } from './PlanTemplateSummary.table'
import { PageHeader } from 'Common/PageHeader/PageHeader'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import GlobalSettings from 'Assets/icons/globalsettingsborder.svg'
import { UpdatePlanTemplateModal } from './UpdatePlanTemplateSettingsModal/UpdatePlanTemplateSettingsModal.view'
import { Button } from 'supplyvue-ui'
import { useStyles } from './PlanTemplateSummary.styles'
import { Typography } from '@material-ui/core'
import { ExportTemplateModal } from 'Features/PlanTemplateSummary/ExportTemplateModal/ExportTemplateModal.view'
import { Toolbar } from 'Common/Toolbar'
import { PlanTemplateBulkUpdateView } from 'Features/PlanTemplateSummary/BulkUpdate/BulkUpdate.view'
import { INITIAL_STATE as FORM_INITIAL_STATE } from 'Features/PlanTemplateSummary/BulkUpdate/BulkUpdate.view'

export const PlanTemplateSummary = () => {
  const classes = useStyles()
  const { projectId, planTemplateId, scenarioId, snapshotId } =
    useParams<PlanTemplateSummaryParams>()

  useBreadcrumbsData({
    project_id: projectId,
    scenario_id: scenarioId,
    snapshot_id: snapshotId,
  })

  const [isModalOpen, setModalOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [formData, setFormData] =
    useState<PlanTemplateFormData>(FORM_INITIAL_STATE)

  const { isLoading, data } = usePlanTemplateSummaryData(
    projectId,
    planTemplateId
  )

  const [tableData, setTableData] = useState<PlanTemplateSummaryTableType[]>(
    data?.summary
  )

  const [dataToUpdate, setDataToUpdate] = useState<any[]>([])

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>()

  const { data: planTemplateSettings } = usePlanTemplateSettingsData(
    projectId,
    planTemplateId
  )

  const handleDropdownChange = (newValue: string, index: number) => {
    const newData = tableData.map(
      (item: PlanTemplateSummaryTableType, idx: number) => {
        if (idx === index) {
          return {
            ...item,
            preferredMethod: newValue,
          }
        } else {
          return item
        }
      }
    )

    setTableData(newData)

    const newDatatoUpdate = dataToUpdate.map((item, idx) => {
      if (idx === index) {
        if (item) {
          return {
            ...item,
            preferredMethod: newValue,
          }
        } else {
          return {
            preferredMethod: newValue,
            workcentreKey: tableData[index].workcentreKey,
            sourceSiteKey: tableData[index].sourceSiteKey,
          }
        }
      } else {
        return item
      }
    })

    setDataToUpdate(newDatatoUpdate)
  }

  const [exportTemplate, setExportTemplate] = useState<boolean>(false)

  const { data: generating_status } = useGeneratingStatuses(planTemplateId)

  const [templatesGeneratingStatus, setTemplatesGeneratingStatus] =
    useState<boolean>(!generating_status?.Completed)

  const InitialLoadingStatus = {
    isLoading: false,
    columnId: '',
    sourceSiteKey: null,
    workcentreKey: null,
  }

  const mapping = {
    Constrained: 'templateGenerated',
    Unconstrained: 'unconstrainedTemplateGenerated',
    ['Cost Optimized']: 'costOptimizedGenerated',
    ['Carbon Cost']: 'carbonCostGenerated',
  }

  const [selectedRows, setSelectedRows] = useState<
    PlanTemplateSummaryTableType[]
  >([])

  const onSuccess = (message: string) => {
    toast.success(message)
    setLoadingStatus(InitialLoadingStatus)
  }

  const onError = (message: string) => {
    toast.error(message)
    setLoadingStatus(InitialLoadingStatus)
  }

  const { mutate: updatePlanTemplateSummary, isLoading: isUpdating } =
    useUpdatePlanTemplateSummary({
      onSuccess,
      onError,
    })

  useEffect(() => {
    setTableData(data?.summary)
    setDataToUpdate(new Array(data?.summary.length).fill(null))
    setTemplatesGeneratingStatus(!generating_status?.Completed)
    setLoadingStatus(InitialLoadingStatus)
  }, [data])

  useEffect(() => {
    setTemplatesGeneratingStatus(!generating_status?.Completed)
    setTableData(data?.summary)
  }, [generating_status])

  useEffect(() => {
    if (tableData) {
      let exportTemplate: boolean = false

      tableData.forEach((row: PlanTemplateSummaryTableType) => {
        if (row.generatePlanTemplate) {
          // The Constrained Template must be generated in order for the template
          // to be exported
          if (
            // @ts-ignore
            row[mapping[row.preferredMethod]] === 'COMPLETED' &&
            row.templateGenerated === 'COMPLETED'
          ) {
            exportTemplate = true
          }
        }
      })
      setExportTemplate(exportTemplate)
    }
  }, [tableData])

  const handleConfigurePlanTemplateSettings = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleExportTemplateModalOpen = () => {
    setIsExportModalOpen(true)
  }

  const handleExportTemplateModalClose = () => {
    setIsExportModalOpen(false)
  }

  const onCalculateSuccess = () => {
    console.log('SUCCESS')
  }

  const onCalculateError = () => {
    console.log('ERROR')
  }

  const onExportError = () => {
    toast.error('Export Templates failed to trigger')
    setIsExportModalOpen(false)
  }
  const onExportSuccess = () => {
    toast.success('Export Templates triggered succesfully')
    setIsExportModalOpen(false)
  }

  const { mutate: calculatePlanTemplate, isLoading: isCalculating } =
    useCalculateMultiplePlanTemplates(onCalculateSuccess, onCalculateError)

  const { mutate: exportTemplates, isLoading: isExportLoading } =
    useExportPlanTemplates(onExportSuccess, onExportError)

  const handleRunTemplateClick = async () => {
    // Set the run status to initiated
    setTemplatesGeneratingStatus(true)

    const selected_workcentres = selectedRows.map(
      (item: PlanTemplateSummaryTableType) => {
        return item.workcentreKey
      }
    )

    const newTableData: PlanTemplateSummaryTableType[] = tableData.map(
      (item: PlanTemplateSummaryTableType) => {
        if (selected_workcentres.includes(item.workcentreKey)) {
          return { ...item, generatePlanTemplate: true }
        } else {
          return item
        }
      }
    )

    const summaryData = newTableData.map(
      (item: PlanTemplateSummaryTableType) => {
        if (item.generatePlanTemplate) {
          return { ...item, generatedFlag: 'INITIATED' }
        } else {
          return { ...item }
        }
      }
    )

    setTableData(summaryData)

    calculatePlanTemplate({
      snapshotId,
      projectId,
      planTemplateId,
      summaryData,
    })
  }

  const handleExportTemplateConfirm = (dateString: string) => {
    const workcentreExport: WorkcentreModeExports[] = tableData.reduce(
      (
        accumulator: WorkcentreModeExports[],
        item: PlanTemplateSummaryTableType
      ) => {
        if (
          item.generatePlanTemplate &&
          // @ts-ignore
          item[mapping[item.preferredMethod]] === 'COMPLETED'
        ) {
          accumulator.push({
            workcentreKey: item.workcentreKey,
            method: item.preferredMethod,
            date: dateString,
          })
        }
        return accumulator
      },
      []
    )

    exportTemplates({
      planTemplateId: planTemplateId,
      workcentreModeExports: workcentreExport,
    })
  }

  const EditOnClick = () => {
    setIsEditOpen(true)
  }

  const EditOnClose = () => {
    setIsEditOpen(false)
  }

  const handleSaveClick = (formData: PlanTemplateFormData) => {
    let updatedValues: { [key: string]: any } = {}
    if (formData.capacityHours) {
      updatedValues['workcentreCapacity'] = parseFloat(formData.capacityHours)
    }
    if (formData.crewCostPerHour) {
      updatedValues['crewCostPerHour'] = parseFloat(formData.crewCostPerHour)
    }
    if (formData.productionCostPerHour) {
      updatedValues['productionCostPerHour'] = parseFloat(
        formData.productionCostPerHour
      )
    }
    if (formData.preferredMethod != 'None') {
      updatedValues['preferredMethod'] = formData.preferredMethod
    }

    let updatedData: BulkEditPlanTmplate[] = []

    selectedRows.forEach((item: PlanTemplateSummaryTableType) => {
      updatedData.push({
        ...updatedValues,
        sourceSiteKey: item.sourceSiteKey,
        workcentreKey: item.workcentreKey,
      })
    })

    const payload: UpdatePlanTemplateVariables = {
      planTemplateId: planTemplateId,
      updatedData: updatedData,
    }

    updatePlanTemplateSummary(payload)
  }

  const handleSaveChanges = () => {
    const payload: UpdatePlanTemplateVariables = {
      planTemplateId: planTemplateId,
      updatedData: dataToUpdate.filter((item) => item !== null),
    }

    updatePlanTemplateSummary(payload)
  }

  if (isLoading) {
    return (
      <Page center>
        <Loader />
      </Page>
    )
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader
          title={'Create plan templates'}
          actionIcon={<GlobalSettings />}
          actionHandler={handleConfigurePlanTemplateSettings}
          actionTooltip="Default settings"
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
          mb={2}
        >
          <Box className={classes.bulkUpdate}>
            <Toolbar onEditClick={EditOnClick} />
          </Box>
          {templatesGeneratingStatus ? (
            <Box className={classes.runAllButton}>
              <Button
                color="primary"
                variant="outlined"
                endIcon={<CircularProgress color="primary" size={15} />}
                size="small"
              >
                Generating Templates
              </Button>
            </Box>
          ) : selectedRows.length === 0 ? (
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography color="primary">Select a Workcentre</Typography>
              </Box>
              <Box className={classes.runAllButton}>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={true}
                  size="small"
                >
                  Generate Plan Templates
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className={classes.buttonGroup}>
              <Box>
                <Button
                  color="primary"
                  label="Export Template"
                  aria-label="Export Template"
                  size="small"
                  variant="outlined"
                  disabled={!exportTemplate}
                  onClick={handleExportTemplateModalOpen}
                >
                  Export Templates
                </Button>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRunTemplateClick}
                  size="small"
                >
                  Generate Plan Templates
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {tableData && loadingStatus && (
          <Box>
            <PlanTemplateSummaryTable
              data={tableData}
              setSelectedRows={setSelectedRows}
              handleDropdownChange={handleDropdownChange}
              isUpdating={isUpdating}
            />
            <Box className={classes.saveChanges}>
              <Box className={classes.buttonGroup}>
                <Button disabled={true} variant="outlined">
                  Clear
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    isUpdating || dataToUpdate.every((value) => value === null)
                  }
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {planTemplateSettings && (
          <UpdatePlanTemplateModal
            isModalOpen={isModalOpen}
            heading="Plan template default settings"
            planTemplateSettings={planTemplateSettings}
            projectId={projectId}
            planTemplateId={planTemplateId}
            handleModalClose={handleModalClose}
            primaryButtonLabel="Save"
            secondaryButtonLabel="Cancel"
            salesRateTypeLabel="Sales rate type"
            salesRatePeriodLabel="Sales rate period"
            minBatchesPerCycleLabel="Min. average batches per production cycle"
            planTemplateBasedOnLabel="Plan template for"
          />
        )}
        <ExportTemplateModal
          isOpen={isExportModalOpen}
          handleClose={handleExportTemplateModalClose}
          handleExportTemplateConfirm={handleExportTemplateConfirm}
          isUpdating={isExportLoading}
        />
      </Box>
      <PlanTemplateBulkUpdateView
        isModalOpen={isEditOpen}
        handleModalClose={EditOnClose}
        isUpdating={isUpdating}
        handleSaveClick={handleSaveClick}
        formData={formData}
        setFormData={setFormData}
      />
    </Page>
  )
}
