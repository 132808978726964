import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request, requestV2 } from 'Features/Auth/axios-client'
import { format } from 'date-fns'
import {
  UpdateWorkcentreSettingsAPIResponseHandler,
  UpdateWorkcentreSettingsVariables,
  DownloadChangeoversParams,
  UploadChangeoversParams,
  UpdateBatchChangeoversParams,
  UpdateBatchChangeoversAPIResponseHandler,
  UpdateBatchChangeoversSingleEditParams,
  UseSaveProductSettingsFileParams,
} from './StepCard.types'
import { BulkUpdateParams } from '../ProductSettings/ProductSettings.types'

const updateWorkcentreSettings = async ({
  snapshotId,
  projectId,
  planTemplateId,
  sourceSiteKey,
  workcentreKey,
  step,
  stepData,
}: UpdateWorkcentreSettingsVariables) => {
  const { data } = await request({
    url: `/plan-template/workcentre-settings`,
    method: 'put',
    data: {
      snapshotId,
      projectId,
      planTemplateId,
      sourceSiteKey,
      workcentreKey,
      [step]: stepData,
    },
  })
  return data
}

export const useUpdateWorkcentreSettingsData = ({
  onSuccess,
  onError,
}: UpdateWorkcentreSettingsAPIResponseHandler) => {
  const queryClient = useQueryClient()

  return useMutation(updateWorkcentreSettings, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [
          'workcentre-settings',
          variables.snapshotId,
          variables.projectId,
          variables.planTemplateId,
          variables.sourceSiteKey,
          variables.workcentreKey,
        ],
        data
      )
      onSuccess(data.data, data.message)
    },
    onError: ({ response }) => onError(response.data.message),
  })
}

/** Begin - Batch changeover APIs */

const downloadChangeoversFile = async (
  changeoverParams: DownloadChangeoversParams
) => {
  const {
    planTemplateId,
    sourceSiteId,
    workcentreId,
    downloadType,
  } = changeoverParams
  const { data } = await requestV2({
    responseType: 'blob',
    url: `/plan-templates/${planTemplateId}/changeovers/download?source_site_key=${sourceSiteId}&workcentre_key=${workcentreId}&download_type=${downloadType}`,
  })
  const fileName = `changeovers_${format(new Date(), 'yyyyMMdd')}`
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  return data
}

export const useDownloadChangeoversFile = (
  changeoverParams: DownloadChangeoversParams
) => {
  return useQuery(
    ['changeovers-download', changeoverParams.planTemplateId],
    () => downloadChangeoversFile(changeoverParams),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (data) => data.data,
      enabled: false,
    }
  )
}

const uploadChangeoversFile = async (
  changeoverParams: UploadChangeoversParams
) => {
  const {
    planTemplateId,
    sourceSiteId = 0,
    workcentreId = 0,
    uploadType,
    file,
  } = changeoverParams
  const formData = new FormData()
  formData.append('source_site_key', sourceSiteId.toString())
  formData.append('workcentre_key', workcentreId.toString())
  formData.append('upload_type', uploadType)
  formData.append('changeovers_file', file)
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/changeovers/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUploadChangeoversFile = () => {
  return useMutation(uploadChangeoversFile)
}

const updateBatchChangeovers = async (
  changeoverParams: UpdateBatchChangeoversParams
) => {
  const {
    planTemplateId,
    sourceSiteId = 0,
    workcentreId = 0,
    uploadType,
    file,
    changeovers,
  } = changeoverParams
  const formData = new FormData()
  formData.append('source_site_key', sourceSiteId.toString())
  formData.append('workcentre_key', workcentreId.toString())
  formData.append('upload_type', uploadType)
  file && formData.append('changeovers_file', file)
  changeovers && formData.append('changeovers', changeovers)
  const { data } = await requestV2({
    method: 'put',
    url: `/plan-templates/${planTemplateId}/changeovers/save`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useUpdateBatchChangeovers = ({
  onSuccess,
  onError,
}: UpdateBatchChangeoversAPIResponseHandler) => {
  const queryClient = useQueryClient()

  return useMutation(updateBatchChangeovers, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries('workcentre-settings')
      if (variables.uploadType === 'product')
        queryClient.refetchQueries('product-change-over-data')
      else if (variables.uploadType === 'batch')
        queryClient.refetchQueries('batch-change-over-data')
      onSuccess(data.data, data.message)
    },
    onError: ({ response }) => onError(response.data.message),
  })
}
const updateBatchChangeoversSingleEdit = async (
  changeoverParams: UpdateBatchChangeoversSingleEditParams
) => {
  const { id, entity, valueType, value } = changeoverParams
  const { data } = await requestV2({
    url: `/plan-templates/changeovers`,
    method: 'put',
    data: {
      id,
      entity,
      valueType,
      value,
    },
  })
  return data
}

export const useUpdateBatchChangeoversSingleEdit = ({
  onSuccess,
  onError,
}: UpdateBatchChangeoversAPIResponseHandler) => {
  const queryClient = useQueryClient()

  return useMutation(updateBatchChangeoversSingleEdit, {
    onSuccess: (data, variables) => {
      if (variables.entity === 'batch')
        queryClient.refetchQueries('batch-change-over-data')
      else if (variables.entity === 'product')
        queryClient.refetchQueries('product-change-over-data')
      onSuccess(data.data, data.message)
    },
    onError: ({ response }) => onError(response.data.message),
  })
}
/** End - Batch changeover APIs */

/** Save product settings file. */

const saveProductSettingsFile = async (bulkUpdateParams: BulkUpdateParams) => {
  const {
    projectId,
    snapshotId,
    planTemplateId,
    sourceSiteId = 0,
    workcentreId = 0,
    uploadType,
    file,
  } = bulkUpdateParams
  const formData = new FormData()
  formData.append('source_site_key', sourceSiteId.toString())
  formData.append('workcentre_key', workcentreId.toString())
  formData.append('project_id', projectId ? projectId.toString() : '')
  formData.append('snapshot_id', snapshotId ? snapshotId.toString() : '')
  formData.append('upload_type', uploadType)
  formData.append('settings_file', file)
  const { data } = await request({
    method: 'post',
    url: `/plan-templates/${planTemplateId}/sku-group-settings/save`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })
  return data
}

export const useSaveProductSettingsFile = ({
  onSaveProductSettingsSuccess,
  onSaveProductSettingsFail,
}: UseSaveProductSettingsFileParams) => {
  const queryClient = useQueryClient()
  return useMutation(saveProductSettingsFile, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('workcentre-settings')
      onSaveProductSettingsSuccess(
        data?.data['productSettings'],
        data?.data['batchGroupingSettings']
      )
    },
    onError: (errorr: unknown) => {
      onSaveProductSettingsFail(errorr)
    },
  })
}
/** End save product settings file upload. */
