import { useState, useEffect, useMemo } from 'react'
import { MAXIMUM_MIN_CYCLE_VALUE, FOC } from 'Common/Constants/Constants'

export const useCycleValidation = (
  minCycle: string | number | null,
  maxCycle: string | number | null,
  salesweeksPerMinQuantity?: string | number,
  replenishmentType?: string | null,
  salesWeeksPerMinQuantityParameterName?: string
) => {
  const [replenishmentTypeName, setReplenishmentTypename] = useState(
    replenishmentType
  )
  const [
    salesweeksPerMinimumQuantity,
    setSalesweeksPerMinimumQuantity,
  ] = useState<string | number>(salesweeksPerMinQuantity || '')
  const [maximumCycle, setMaximumCycle] = useState<string | number | null>(
    maxCycle
  )
  const [minimumCycle, setMinimumCycle] = useState<string | number | null>(
    minCycle
  )
  const [minCycleError, setMinCycleError] = useState('')
  const [maxCycleError, setMaxCycleError] = useState('')
  const [replenishmentTypeError, setReplenishmentTypeError] = useState('')

  const REPLENISHMENT_TYPE_ERROR = `Replenishment type should be FOQ when ${salesWeeksPerMinQuantityParameterName} is greater than 8`
  const MIN_CYCLE_SALES_PER_MOQ_ERROR = `Minimum cycle should be greater than ${salesWeeksPerMinQuantityParameterName}`
  const MIN_CYCLE_ERROR_MESSAGE =
    'Minimum cycle should be less than or equal to maximum cycle'

  const MAX_CYCLE_ERROR_MESSAGE =
    'Maximum cycle should be greater than or equal to minimum cycle'

  const isInvalidCycleValues = useMemo(
    () =>
      maximumCycle !== null &&
      minimumCycle !== null &&
      maximumCycle < minimumCycle &&
      maximumCycle !== '' &&
      minimumCycle !== '',
    [maximumCycle, minimumCycle]
  )

  useEffect(() => {
    if (isInvalidCycleValues) {
      setMaxCycleError(MAX_CYCLE_ERROR_MESSAGE)
    } else {
      setMaxCycleError('')
      salesweeksPerMinQuantity && replenishmentTypeName
        ? minCycleError === MIN_CYCLE_ERROR_MESSAGE && setMinCycleError('')
        : setMinCycleError('')
    }
  }, [maximumCycle, MAX_CYCLE_ERROR_MESSAGE])

  useEffect(() => {
    if (isInvalidCycleValues && replenishmentTypeName) {
      setMinCycleError(MIN_CYCLE_ERROR_MESSAGE)
    } else {
      setMaxCycleError('')
      setMinCycleError('')
    }
  }, [minimumCycle, MIN_CYCLE_ERROR_MESSAGE])

  useEffect(() => {
    if (salesweeksPerMinQuantity && replenishmentTypeName) {
      if (salesweeksPerMinimumQuantity > MAXIMUM_MIN_CYCLE_VALUE) {
        if (replenishmentTypeName === FOC)
          setReplenishmentTypeError(REPLENISHMENT_TYPE_ERROR)
        else setReplenishmentTypeError('')
        if (
          replenishmentTypeName === FOC &&
          MIN_CYCLE_SALES_PER_MOQ_ERROR === minCycleError
        )
          setMinCycleError('')
      } else {
        if (
          salesweeksPerMinimumQuantity > (minimumCycle ?? '') &&
          replenishmentTypeName === FOC &&
          salesweeksPerMinimumQuantity <= MAXIMUM_MIN_CYCLE_VALUE
        ) {
          setReplenishmentTypeError('')
          setMinCycleError(MIN_CYCLE_SALES_PER_MOQ_ERROR)
        } else {
          !isInvalidCycleValues && setMinCycleError('')
          setReplenishmentTypeError('')
        }
      }
    }
  }, [salesweeksPerMinimumQuantity, minimumCycle, replenishmentTypeName])

  return {
    minCycleError,
    maxCycleError,
    replenishmentTypeError,
    setMaximumCycle,
    setMinimumCycle,
    setReplenishmentTypename,
    setSalesweeksPerMinimumQuantity,
  }
}
