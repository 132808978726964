import { styled } from '@material-ui/core'
import { DataGridPremium } from '@mui/x-data-grid-premium'

export const Table = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-checkboxInput': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-checkboxInput.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    backgroundColor: theme.palette.background.grey,
  },
  '& .MuiDataGrid-columnHeaderCheckbox.Mui-checked': {
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-cell': {
    outlineColor: 'none !important',
    outline: 'none !important',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  '& .MuiDataGrid-cell:focus': {
    outlineColor: 'none !important',
    outline: 'none !important',
  },
  '& .MuiDataGrid-colCell': {
    borderLeft: '1px solid rgba(224, 224, 224, 1)', // Adjust border color and thickness as needed
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderLeft: '1px solid rgba(224, 224, 224, 1)', // Adjust border color and thickness as needed
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
}))
