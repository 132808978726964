import { useMemo } from 'react'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { ProductSettingsTableProps } from './ProductSettings.types'
import { ProductSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { UnitOfMeasureLabel } from './UnitOfMeasureLabel/UnitOfMeasureLabel.view'
import { Typography } from '@material-ui/core'
import { get, has } from 'lodash'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { Loader } from 'Common/Loader/Loader'

export const ProductSettingsTable = ({
  data,
  setSelectedRows,
  unitOfMeasure,
  adjustedValues,
}: ProductSettingsTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 640
    const availableHeight = window.outerHeight - 240
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  if (!data) {
    return <Loader />
  }

  const skuCodes = [
    ...new Set(
      data.map((item: ProductSettings) => {
        return item.productCode
      })
    ),
  ]

  const tableData = data.map((item: ProductSettings, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const MUIColumns: GridColDefPremium[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'productCode',
        headerName: 'SKU group code',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        type: 'string',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        flex: 2,
      },
      {
        field: 'primaryWorkCentreCode',
        headerName: 'Primary work centre',
        type: 'string',
        flex: 1,
      },
      {
        field: 'segment',
        headerName: 'Segment',
        type: 'string',
        flex: 1,
      },
      {
        field: 'productFormat',
        headerName: 'SKU group format',
        type: 'string',
        flex: 1,
      },
      {
        field: 'batchGroupingCode',
        headerName: 'Batch group code',
        type: 'string',
        flex: 1,
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment type',
        type: 'string',
        renderCell: (params: any) => {
          const { skuGroupKey, replenishmentType } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'replenishmentType'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {replenishmentType ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'salesRate',
        headerName: 'Sales rate',
        type: 'number',
      },
      {
        field: 'runRate',
        headerName: 'Run rate',
        type: 'number',
      },
      {
        field: 'runRateUnitOfMeasure',
        headerName: 'Run rate UoM',
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.runRateUnitOfMeasureId}
            label={params.row.runRateUnitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
      },
      {
        field: 'productionUnitOfMeasure',
        headerName: 'UoM',
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.productionUnitOfMeasureId}
            label={params.row.productionUnitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
      },
      {
        field: 'minimumCycle',
        headerName: 'Min cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, minimumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'minimumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {minimumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'maximumCycle',
        headerName: 'Max cycle',
        type: 'number',
        renderCell: (params: any) => {
          const { skuGroupKey, maximumCycle } = params.row
          const isAdjusted = has(
            get(adjustedValues, `${skuGroupKey}`) ?? {},
            'maximumCycle'
          )

          return (
            <Typography color={isAdjusted ? 'error' : 'inherit'}>
              {maximumCycle ?? '-'}
            </Typography>
          )
        },
      },
      {
        field: 'include',
        headerName: 'Include',
        type: 'boolean',
      },
    ]
  }, [data, adjustedValues])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }
  return (
    <div style={{ height: getTableHeight() }}>
      <MUITable
        rows={tableData}
        checkboxSelection
        columns={MUIColumns}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
        pagination
        experimentalFeatures={{ lazyLoading: true }}
      />
    </div>
  )
}
