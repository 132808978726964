import { useEffect, useState } from 'react'
import { Paper, Box, Typography, Tab, Tabs } from '@material-ui/core'
import { toast } from 'react-hot-toast'
import { Button, Dialog } from 'supplyvue-ui'
import {
  getStepByIndex,
  getStepsLabel,
  haveWorkCentreSettingsChanged,
} from './StepCard.utils'
import { StepFooter } from 'Features/PlanTemplate/StepFooter/StepFooter.view'
import { StepContentView } from 'Features/PlanTemplate/StepContent/StepContent.view'
import {
  EditableMatrixCellUpdateHandler,
  StepCardProps,
} from './StepCard.types'
import { useWorkcentreSettingsState } from './StepCard.state'
import {
  useUpdateWorkcentreSettingsData,
  useDownloadChangeoversFile,
  useUploadChangeoversFile,
  useUpdateBatchChangeovers,
  useUpdateBatchChangeoversSingleEdit,
} from './StepCard.data'
import { useStyles } from './StepCard.styles'
import { WorkcentreSettingsResponse } from 'Features/PlanTemplate/TemplateSettings/TemplateSettings.types'
import { useCalculatePlanTemplate } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.data'
import {
  EnqueueErrorResponse,
  PlanTemplatePageParams,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.types'
import { useHistory, useParams } from 'react-router-dom'
import {
  SET_IS_TEMPLATE_GENERATING,
  SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.constants'
import { DEFAULT_STEP_NUMBER } from './StepCard.constants'
import { BatchGroupingToggle } from '../BatchChangeover/BatchChangeover.types'
import { UploadType } from '../BatchChangeover/ChangeoverMatrix/ChangeoverMatrix.types'
import {
  useBatchChangeOverData,
  useBatchChangeOverSummaryData,
  useProductChangeOverData,
  useProductChangeOverSummaryData,
} from '../PlanTemplate.data'

export const StepCardView = ({
  snapshotId,
  projectId,
  scenarioId,
  planTemplateId,
  sourceSiteId,
  workcentreId,
  scenarioSettingsState,
  workcentreSettingsData,
  dropdownOptions,
  planTemplateGenerationState,
  updatePlanTemplateGenerationState,
  graphData,
}: StepCardProps) => {
  const { sourceSiteId: baseSourceSiteId, workcentreId: baseWorkcenterId } =
    useParams<PlanTemplatePageParams>()

  const history = useHistory()
  const [activeStep, setActiveStep] = useState(DEFAULT_STEP_NUMBER)
  const [showDialog, setShowDialog] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const { batchGroupingCodes } = workcentreSettingsData
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  /** Begin - Maintain workcentre settings state with useReducer  */
  const [workcentreSettingsState, updateWorkcentreSettingsState] =
    useWorkcentreSettingsState(workcentreSettingsData)

  const templateBasedOn = workcentreSettingsState?.settings?.templateBasedOn

  /** End - Maintain workcentre settings state with useReducer  */

  /** Begin - Mutation to update workcentre settings */

  const onUpdateWorkcentreSettingsSuccess = (
    responseData: WorkcentreSettingsResponse,
    message: string
  ) => {
    /** Update state to match data to avoid difference when comparing.*/
    updateWorkcentreSettingsState({
      step: 'settings',
      value: responseData,
      type: 'RESET',
    })
    toast.success(message)
  }

  const { refetch: downloadFile } = useDownloadChangeoversFile({
    planTemplateId,
    sourceSiteId,
    workcentreId,
    downloadType: 'batch',
  })

  const {
    mutate: uploadFile,
    data: uploadFileData,
    isLoading: isFileUploading,
    reset: resetUploadFileData,
  } = useUploadChangeoversFile()

  const handleUploadFile = (file: File, uploadType: UploadType) => {
    uploadFile(
      {
        planTemplateId,
        sourceSiteId,
        workcentreId,
        uploadType: uploadType,
        file,
      },
      {
        onSuccess: (data) => {
          if (data.data.valid) {
            setUploadedFile(file)
          } else {
            toast.error(
              'File uploaded with error(s), please correct in your file and re-upload.'
            )
          }
        },
      }
    )
  }

  /*** changeover data */

  const {
    data: batchChangeoverData,
    isFetchedAfterMount: isBatchChangeoverFetched,
    refetch: refetchBatchChangeoverData,
  } = useBatchChangeOverData(
    planTemplateId,
    sourceSiteId ?? parseInt(baseSourceSiteId),
    workcentreId ?? parseInt(baseWorkcenterId)
  )

  const batchChangeovers = {
    batchChangeoverData,
    isBatchChangeoverFetched,
    refetchBatchChangeoverData,
  }

  const {
    data: productChangeoverData,
    isFetchedAfterMount: isProductChangeoverFetched,
    refetch: refetchProductChangeoverData,
  } = useProductChangeOverData(
    planTemplateId,
    sourceSiteId ?? parseInt(baseSourceSiteId),
    workcentreId ?? parseInt(baseWorkcenterId)
  )

  const productChangeovers = {
    productChangeoverData,
    isProductChangeoverFetched,
    refetchProductChangeoverData,
  }

  const {
    data: batchGroupingChangeOversSummaryData,
    isLoading: isBatchGroupingChangeoverSummaryLoading,
    refetch: refetchBatchChangeoverSummaryData,
  } = useBatchChangeOverSummaryData(
    planTemplateId,
    sourceSiteId ?? parseInt(baseSourceSiteId),
    workcentreId ?? parseInt(baseWorkcenterId)
  )

  const batchGroupingChangeOversSummary = {
    batchGroupingChangeOversSummaryData,
    isBatchGroupingChangeoverSummaryLoading,
    refetchBatchChangeoverSummaryData,
  }

  const {
    data: productChangeOversSummaryData,
    refetch: refetchProductChangeoverSummaryData,
    isLoading: isProductChangeOversSummaryLoading,
  } = useProductChangeOverSummaryData(
    planTemplateId,
    sourceSiteId ?? parseInt(baseSourceSiteId),
    workcentreId ?? parseInt(baseWorkcenterId)
  )

  const productChangeOversSummary = {
    productChangeOversSummaryData,
    isProductChangeOversSummaryLoading,
    refetchProductChangeoverSummaryData,
  }
  /** */
  const onUpdateBatchChangeOversSuccess = (
    responseData: WorkcentreSettingsResponse,
    message: string
  ) => {
    toast.success(message)
  }

  const onUpdateError = (message: string) => {
    toast.error(message)
  }

  const {
    isLoading: isUpdatingWorkcentreSettings,
    mutate: updateWorkcentreSettings,
  } = useUpdateWorkcentreSettingsData({
    onSuccess: onUpdateWorkcentreSettingsSuccess,
    onError: onUpdateError,
  })

  const {
    isLoading: isUpdatingBatchChangeOvers,
    mutate: updateBatchChangeOvers,
  } = useUpdateBatchChangeovers({
    onSuccess: onUpdateBatchChangeOversSuccess,
    onError: onUpdateError,
  })

  const { mutate: updateBatchChangeOversSingleEdit } =
    useUpdateBatchChangeoversSingleEdit({
      onSuccess: onUpdateBatchChangeOversSuccess,
      onError: onUpdateError,
    })

  // const onSaveProductSettingsSuccess = (
  //   productSettings: ProductSettings[],
  //   batchGroupingSettings: BatchGroupingSettings[]
  // ) => {
  //   updateWorkcentreSettingsState({
  //     step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
  //     value: productSettings,
  //   })

  //   updateWorkcentreSettingsState({
  //     step: WORKCENTER_STEPS.BATCH_GROUPING_SETTINGS,
  //     value: batchGroupingSettings,
  //   })

  //   setIsPrimaryButtonDisabled(true)
  //   setUploadedSkuGroupSettingsFile(null)
  //   toast.success('Successfully updated sku group settings')
  // }

  // const onSaveProductSettingsFail = () => {
  //   toast.success('Failed to update sku group settings')
  // }

  // const {
  //   mutate: saveProductSettingsFile,
  //   isLoading: isProductFileSaving,
  //   reset: resetProductSettingFileApi,
  // } = useSaveProductSettingsFile({
  //   onSaveProductSettingsSuccess,
  //   onSaveProductSettingsFail,
  // })

  const steps = getStepsLabel()

  const handleEditableMatrixCellUpdate: EditableMatrixCellUpdateHandler = (
    value: string | number,
    uploadType: UploadType,
    toggleValue: BatchGroupingToggle,
    id: number
  ) => {
    updateBatchChangeOversSingleEdit({
      entity: uploadType,
      valueType: toggleValue,
      value: typeof value === 'string' ? parseInt(value) : value,
      id,
    })
  }

  const handleSave = () => {
    const step = getStepByIndex(activeStep)
    const uploadType: UploadType =
      step === 'batchGroupingChangeOvers' ? 'batch' : 'product'
    if (step === 'batchGroupingChangeOvers' || step === 'productChangeOvers') {
      if (uploadedFile) {
        updateBatchChangeOvers(
          {
            projectId,
            snapshotId,
            planTemplateId,
            sourceSiteId,
            workcentreId,
            uploadType: uploadType,
            file: uploadedFile,
          },
          {
            onSuccess: () => {
              setUploadedFile(null)
              resetUploadFileData()
            },
          }
        )
      } else {
        toast.error('Changeovers file not uploaded')
      }
    } else {
      if (step !== 'generateTemplate') {
        updateWorkcentreSettings({
          snapshotId,
          projectId,
          planTemplateId,
          sourceSiteKey: sourceSiteId ?? 0,
          workcentreKey: workcentreId ?? 0,
          step,
          stepData: workcentreSettingsState[step],
        })
      }
    }
  }

  useEffect(() => {
    if (uploadFileData?.data) {
      const { valid } = uploadFileData?.data
      if (valid) {
        setIsPrimaryButtonDisabled(false)
      }
    } else {
      setIsPrimaryButtonDisabled(true)
    }
  }, [uploadFileData])
  /** End - Mutation to update workcentre settings */

  const onCalculatePlanTemplateSuccess = () => {
    updatePlanTemplateGenerationState({
      type: SET_IS_TEMPLATE_GENERATING,
      payload: true,
    })
  }

  /** Calculate plan template mutation begins */
  const {
    buffer,
    optimization,
    workcentreCapacity,
    defaultWorkCenterCapacity,
    combinedBuffer,
    combinedWorkcentreCapacity,
  } = planTemplateGenerationState

  const onCalculatePlanTemplateError = (
    errorResponse: EnqueueErrorResponse
  ) => {
    toast.error(errorResponse.message)
    updatePlanTemplateGenerationState({
      type: SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
      payload: errorResponse.validations,
    })
    updatePlanTemplateGenerationState({
      type: SET_IS_TEMPLATE_GENERATING,
      payload: false,
    })
  }

  const { mutate: calculatePlanTemplate, isLoading: isCalculating } =
    useCalculatePlanTemplate(
      onCalculatePlanTemplateSuccess,
      onCalculatePlanTemplateError
    )

  const handleCalculatePlanTemplate = () => {
    updatePlanTemplateGenerationState({
      type: SET_PLAN_TEMPLATE_GENERATION_VALIDATIONS,
      payload: [],
    })
    updatePlanTemplateGenerationState({
      type: SET_IS_TEMPLATE_GENERATING,
      payload: true,
    })
    if (typeof sourceSiteId === 'number' && typeof workcentreId === 'number')
      calculatePlanTemplate({
        snapshotId,
        projectId,
        planTemplateId,
        sourceSiteId,
        workcentreId,
        buffer: buffer ? parseInt(buffer) : 0,
        workcentreCapacity: parseInt(
          optimization === 'constrained'
            ? workcentreCapacity
            : defaultWorkCenterCapacity
        ),
        optimization,
        templateBasedOn,
        ...(templateBasedOn === 'Combined maker and line' && {
          makerBuffer: parseInt(combinedBuffer),
          makerWorkcentreCapacity: parseInt(combinedWorkcentreCapacity),
        }),
      })
  }

  /** Calculate plan template mutation ends */

  const handlePlanDetailsClick = () => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteId}/${workcentreId}/plan-details?optimization=${optimization}`
    )
  }

  const discardChanges = () => {
    const step = getStepByIndex(activeStep)
    if (step === 'batchGroupingChangeOvers' || step === 'productChangeOvers') {
      setUploadedFile(null)
      resetUploadFileData()
      setIsPrimaryButtonDisabled(true)
    } else {
      updateWorkcentreSettingsState({
        step: 'settings',
        value: workcentreSettingsData,
        type: 'RESET',
      })
    }
    setShowDialog(false)
    toast('Changes have been discarded')
  }
  useEffect(() => {
    if (activeStep === 2) {
      refetchProductChangeoverData()
    } else if (activeStep === 4) {
      refetchBatchChangeoverData()
    }
  }, [activeStep, refetchProductChangeoverData, refetchBatchChangeoverData])

  // Footer should not be shown for all tabs
  const isFooterVisible = !(
    activeStep === 5 ||
    activeStep === 3 ||
    activeStep === 1
  )

  const classes = useStyles()
  return (
    <>
      <Paper elevation={2} square className={classes.wrapper}>
        <Tabs
          value={activeStep}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            const step = getStepByIndex(activeStep)
            if (
              (step === 'batchGroupingChangeOvers' ||
                step === 'productChangeOvers') &&
              uploadFileData
            ) {
              setShowDialog(true)
            } else {
              setActiveStep(value)
            }
          }}
          aria-label="Plan template"
          variant="fullWidth"
          classes={{ root: classes.tabs }}
        >
          {steps.map((label) => {
            return <Tab key={label} label={label} />
          })}
        </Tabs>
        <Box py={1} px={4}>
          {activeStep === steps.length ? (
            <div>
              <Typography>All steps completed</Typography>
            </div>
          ) : (
            <Box className={classes.stepContentView}>
              <StepContentView
                step={activeStep}
                scenarioSettingsState={scenarioSettingsState}
                workcentreSettingsState={workcentreSettingsState}
                updateWorkcentreSettingsState={updateWorkcentreSettingsState}
                dropdownOptions={dropdownOptions}
                batchGroupingCodes={batchGroupingCodes}
                downloadFile={downloadFile}
                uploadFile={handleUploadFile}
                isFileUploading={isFileUploading}
                uploadFileData={uploadFileData?.data}
                setUploadedFile={setUploadedFile}
                setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
                snapshotId={snapshotId}
                projectId={projectId}
                scenarioId={scenarioId}
                planTemplateId={planTemplateId}
                sourceSiteId={sourceSiteId}
                workcentreId={workcentreId}
                planTemplateGenerationState={planTemplateGenerationState}
                updatePlanTemplateGenerationState={
                  updatePlanTemplateGenerationState
                }
                handleCalculatePlanTemplate={handleCalculatePlanTemplate}
                isCalculating={isCalculating}
                handlePlanDetailsClick={handlePlanDetailsClick}
                handleEditableMatrixCellUpdate={handleEditableMatrixCellUpdate}
                batchGroupingChangeOversSummary={
                  batchGroupingChangeOversSummary
                }
                productChangeOversSummary={productChangeOversSummary}
                batchChangeovers={batchChangeovers}
                productChangeovers={productChangeovers}
                graphData={graphData}
              />
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginRight: '32px',
            marginBottom: '5px',
          }}
        ></Box>
      </Paper>
      {isFooterVisible && (
        <StepFooter
          primaryButtonLabel="Save"
          handlePrimaryButtonClick={handleSave}
          isPrimaryButtonBusy={
            isUpdatingWorkcentreSettings || isUpdatingBatchChangeOvers
          }
          disablePrimaryButton={isPrimaryButtonDisabled}
        />
      )}
      <Dialog
        title="Unsaved changes will be lost"
        content="Are you sure you want to discard the changes?"
        primaryButtonLabel="Discard"
        secondaryButtonLabel="Cancel"
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        handlePrimaryButtonClick={discardChanges}
        handleSecondaryButtonClick={() => setShowDialog(false)}
      />
    </>
  )
}
