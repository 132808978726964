import { useRef, useState } from 'react'
import { Box } from '@material-ui/core'

import { BatchSettingsTable } from './BatchSettings.table'
//import { BatchSettingsForm } from './BatchSettingsForm/BatchSettingsForm.view'
import {
  BatchSettingsParametersType,
  PlanTemplateEditInputsParams,
} from './BatchSettingsForm/BatchSettingsForm.types'
import { BatchSettingsProps } from './BatchSettings.types'
import {
  BatchGroupingSettings,
  ProductSettings,
} from 'Features/PlanTemplate/StepCard/StepCard.types'
import { CYCLE_MENU_ITEMS } from './BatchSettingsForm/BatchSettings.constants'
import {
  mapDropdownMenuItemsInName,
  mapDropdownMenuItemsInLabel,
} from 'Common/Utils/utils'
import { WORKCENTER_STEPS } from 'Features/PlanTemplate/StepCard/StepCard.constants'
import {
  BatchSettingsBulkEditView,
  INITIAL_STATE as FORM_INITIAL_STATE,
} from './BatchSettingsBulkEdit/BatchSettingsBulkEdit.view'
import { useUpdatGroupSettings } from '../ProductSettings/ProductSettingsForm/ProductSettingsForm.data'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { TEMPLATE_FOR_COMBINED_MAKER_AND_LINE } from './BatchSettings.constants'
import { ErrorData } from '../ProductSettings/ProductSettingsForm/ProductSettingsForm.types'
import { SKUGroupSettingsError } from '../ProductSettings/ProductSettings.error.view'
import { Toolbar } from 'Common/Toolbar'
import { InstructionsCardView } from './InstructionsCard/InstructionsCard.view'
import { FileUploadErrorView } from './FileUploadErrorView/FileUploadError.view'
import {
  useDownloadBatchSettingsData,
  useUploadBatchFile,
} from './BatchSettings.data'
import { Loader } from 'Common/Loader/Loader'
const INITIAL_STATE = {
  minimumBatchSize: '',
  minimumBatchSizeIncrement: '',
  replenishmentTypeId: '',
  minimumCycle: '',
  maximumCycle: '',
  runRate: '',
}

export const BatchSettingsView = ({
  batchGroupingSettingsData,
  dropdownOptions,
  updateWorkcentreSettingsState,
  templateBasedOn,
}: BatchSettingsProps) => {
  const { snapshotId, projectId, planTemplateId, sourceSiteId, workcentreId } =
    useParams<PlanTemplateEditInputsParams>()
  const [data, setFormData] =
    useState<BatchSettingsParametersType>(FORM_INITIAL_STATE)
  const [tableData, setTableData] = useState(batchGroupingSettingsData)
  const [selectedRows, setSelectedRows] = useState<BatchGroupingSettings[]>()
  const bulkEditData = useRef(INITIAL_STATE)
  const [errorData, setErrorData] = useState<null | ErrorData>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fileUploadResponse, setFileUploadResponse] = useState<null | any>(null)

  const onSuccess = (
    message: string,
    updatedData: ProductSettings[],
    updatedBatchGrouping: BatchGroupingSettings[]
  ) => {
    setFormData(FORM_INITIAL_STATE)
    setErrorData(null)
    //handleSaveClick(updatedBatchGrouping)
    toast.success(message)
    setIsModalOpen(false)
    setTableData(updatedBatchGrouping)
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.BATCH_GROUPING_SETTINGS,
      value: updatedBatchGrouping,
    })
    updateWorkcentreSettingsState({
      step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
      value: updatedData,
    })
  }

  const onError = (errorInfo: ErrorData) => {
    setErrorData(errorInfo)
    //toast.error(message)
  }

  const { mutate: updateGroupSettings, isLoading: isUpdating } =
    useUpdatGroupSettings(onSuccess, onError)

  const { mutate: downloadBatchGroupSettings, isLoading: isDownloading } =
    useDownloadBatchSettingsData()

  const handleUploadFile = (file: File) => {
    uploadBatchFile(
      {
        planTemplateId,
        sourceSiteId,
        workcentreId,
        projectId,
        snapshotId,
        uploadType: 'batch',
        file,
      },
      {
        onSuccess: (data) => {
          if (data?.data.valid) {
            const { updatedData } = data.data
            if ('batchGroupingSettings' in updatedData) {
              setTableData(updatedData['batchGroupingSettings'])
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.PRODUCT_SETTINGS,
                value: updatedData['productSettings'],
              })
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.BATCH_GROUPING_SETTINGS,
                value: updatedData['batchGroupingSettings'],
              })
            }
            setFileUploadResponse(null)
            toast.success('File uploaded and data saved successfully.')
          } else {
            setFileUploadResponse(data.data)
            toast.error(
              'File uploaded with error(s), please correct in your file and re-upload.'
            )
          }
        },
        onError: () => {
          toast.error('Could not complete the request, Please try again.')
        },
      }
    )
  }

  const { mutate: uploadBatchFile, isLoading: isUploading } =
    useUploadBatchFile()

  const applyCalculations = (
    editedTableData: BatchSettingsParametersType,
    field: string,
    batchGroup: number | string
  ): number => {
    let value = parseFloat(
      editedTableData[field as keyof BatchSettingsParametersType] as string
    )
    if (
      editedTableData[
        `${field}ActionType` as keyof BatchSettingsParametersType
      ] === '1'
    ) {
      value =
        tableData.find((x) => x.batchGroupingCode === batchGroup)[field] *
        (editedTableData[`${field}`] / 100 + 1)
    } else if (
      editedTableData[
        `${field}ActionType` as keyof BatchSettingsParametersType
      ] === '2'
    ) {
      value =
        tableData.find((x) => x.batchGroupingCode === batchGroup)[field] *
        ((100 - editedTableData[`${field}`]) / 100)
    }
    return parseFloat(value.toFixed(5) as string)
  }

  const handleApplyParametersClick = (
    editedTableData: BatchSettingsParametersType
  ) => {
    bulkEditData.current = editedTableData

    const batchGroups =
      selectedRows &&
      selectedRows.map((row: BatchGroupingSettings) => {
        return {
          [row.batchGroupingCode as string]: {
            ...(editedTableData.minimumBatchSize && {
              minimumBatchSize: applyCalculations(
                editedTableData,
                'minimumBatchSize',
                row.batchGroupingCode as string | number
              ),
            }),
            ...(editedTableData.minimumBatchSizeIncrement && {
              minimumBatchSizeIncrement: applyCalculations(
                editedTableData,
                'minimumBatchSizeIncrement',
                row.batchGroupingCode as string | number
              ),
            }),

            ...(editedTableData.replenishmentTypeId && {
              replenishmentTypeId: parseInt(
                editedTableData.replenishmentTypeId as string
              ),
            }),
            ...(editedTableData.minimumCycle && {
              minimumCycle: parseInt(editedTableData.minimumCycle as string),
            }),
            ...(editedTableData.maximumCycle && {
              maximumCycle: parseInt(editedTableData.maximumCycle as string),
            }),
            ...(editedTableData.runRate && {
              runRate: applyCalculations(
                editedTableData,
                'runRate',
                row.batchGroupingCode as string | number
              ),
            }),
          },
        }
      })
    setErrorData(null)
    const fields = {
      ...(editedTableData.minimumBatchSize && {
        minimumBatchSize: parseFloat(
          editedTableData.minimumBatchSize as string
        ),
      }),
      ...(editedTableData.minimumBatchSizeIncrement && {
        minimumBatchSizeIncrement: parseFloat(
          editedTableData.minimumBatchSizeIncrement as string
        ),
      }),

      ...(editedTableData.replenishmentTypeId && {
        replenishmentTypeId: parseInt(
          editedTableData.replenishmentTypeId as string
        ),
      }),
      ...(editedTableData.minimumCycle && {
        minimumCycle: parseInt(editedTableData.minimumCycle as string),
      }),
      ...(editedTableData.maximumCycle && {
        maximumCycle: parseInt(editedTableData.maximumCycle as string),
      }),
      ...(editedTableData.runRate && {
        runRate: parseInt(editedTableData.runRate as string),
      }),
    }

    const batchGroupCodes =
      selectedRows && selectedRows.map((row) => row.batchGroupingCode as string)

    updateGroupSettings({
      planTemplateId,
      data: {
        snapshotId,
        projectId,
        sourceSiteKey: parseInt(sourceSiteId),
        workcentreKey: parseInt(workcentreId),
        batchGroupingCodes: batchGroupCodes,
        fields,
        batchGroups,
      },
    })
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  if (isUploading) {
    return <Loader />
  }
  // @ts-ignore
  return (
    <>
      <BatchSettingsBulkEditView
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        handleSaveClick={handleApplyParametersClick}
        selectedRows={selectedRows ?? []}
        maximumCycleTypes={CYCLE_MENU_ITEMS}
        minimumCycleTypes={CYCLE_MENU_ITEMS}
        unitOrderMeasureTypes={mapDropdownMenuItemsInName(
          dropdownOptions.unitOfMeasure
        )}
        replenishmentTypes={mapDropdownMenuItemsInLabel(
          dropdownOptions.replenishmentType
        )}
        isUpdating={isUpdating}
        hideRunRate={templateBasedOn !== TEMPLATE_FOR_COMBINED_MAKER_AND_LINE}
        data={data}
        setData={setFormData}
        //hideRunRate={templateBasedOn !== COMBINED_MAKER_AND_LINE}
      />
      <Box display="flex">
        <Box flexDirection="row">
          <Toolbar onEditClick={handleModalOpen} />
        </Box>
        <Box
          flexDirection="row"
          display="flex"
          alignSelf="flex-end"
          justifyContent="flex-end"
          flexGrow={1}
          mb={3}
        >
          <InstructionsCardView
            isUploadingFile={isUploading}
            isChangeoverDataLoaded={tableData ? true : false}
            handleDownloadFile={() =>
              downloadBatchGroupSettings({
                planTemplateId,
                sourceSiteId,
                workcentreId,
                projectId,
              })
            }
            handleUploadFile={handleUploadFile}
            isDownloadingFile={isDownloading}
          />
        </Box>
      </Box>

      {fileUploadResponse && (
        <FileUploadErrorView
          uploadFileData={fileUploadResponse}
          isLoading={isUploading}
        />
      )}
      {errorData !== null && (
        <Box>
          <SKUGroupSettingsError data={errorData} groupType="batch" />
        </Box>
      )}

      <Box mt={2}>
        <BatchSettingsTable
          data={tableData}
          setSelectedRows={setSelectedRows}
          unitOfMeasure={dropdownOptions.unitOfMeasure}
          templateBasedOn={templateBasedOn}
        />
      </Box>
    </>
  )
}
