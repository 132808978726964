import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: '17px',
    height: '17px',
  },
  bulkUpdate: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  capacity: {
    fontSize: '14px',
    marginRight: '20px',
  },
  configureButton: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  saveChanges: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  runAllButton: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '5px',
  },
  actions: {
    display: 'flex',
    fontSize: 30,
    cursor: 'pointer',
    borderRadius: 10,
    '&:hover': {
      transform: 'scale(1.5)',
      backgroundColor: 'transparent !important',
    },
  },
  table: {
    marginBottom: '15px',
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'transparent !important',
    },
    overflow: 'hidden',
  },
  popover: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  dropDown: {
    display: 'flex',
    margin: '5px',
    width: 175,
  },
}))
