import { makeStyles } from '@material-ui/core/styles'
import { plannerTheme } from 'supplyvue-ui'

export const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    backgroundColor: plannerTheme.palette.background.grey,
  },
}))

export const brandLogoStyles = {
  height: '58px',
  width: '150px',
}
