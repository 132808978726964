import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { Page } from 'Common/Page/Page'
import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PageHeader, Button } from 'supplyvue-ui'
import { PlanDetailsOptimizationDropDown } from './PlanDetailsDropdown'
import {
  PlanDetailsColumns,
  PlanDetailsParamTypes,
  RunRateTableRow,
  UpdatedRow,
} from './PlanDetails.types'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { optimizationTypes } from './PlanDetails.constants'
import {
  usePlanDetails,
  useDownloadPlanDetails,
  useSaveTemplateChanges,
} from './PlanDetails.data'
import { PlanDetailsTable } from './PlanDetails.table'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useStyles } from './PlanDetails.styles'
import { Loader } from 'Common/Loader/Loader'
import toast from 'react-hot-toast'
import { TableTabs } from './PlanDetailsTabs'
import { RunRateTable } from './RunRateTable'
import { CurrentComparisonTable } from 'Features/PlanDetails/CurrentComparisonTable'
import { ResultsTabView } from 'Features/PlanDetails/ResultsTab.view'
import { usePlanTemplateGenerationState } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.state'
import { useTemplateSummary } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.data'
import {
  SET_BUFFER,
  SET_CYCLE_SPEED_DATA,
  SET_IS_TEMPLATE_GENERATING,
  SET_OPTIMIZATION,
  SET_SUMMARY_DATA,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.constants'
import { mapSummaryObjectToArray } from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.utils'

export const PlanDetailsPage = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(search)
  const selectedOptimization = query.get('optimization') ?? 'constrained'
  const [optimization, setOptimization] = useState(selectedOptimization)

  const {
    projectId,
    scenarioId,
    sourceSiteId,
    workcentreId,
    planTemplateId,
    snapshotId,
  } = useParams<PlanDetailsParamTypes>()

  useBreadcrumbsData({
    project_id: projectId,
    work_center_id: workcentreId,
    scenario_id: scenarioId,
    plan_template_id: planTemplateId,
    base_source_site_id: sourceSiteId,
  })

  const { data, isLoading } = usePlanDetails(
    planTemplateId,
    sourceSiteId,
    workcentreId,
    optimization
  )

  const onMergeSuccess = (
    isGenerating: boolean,
    capacity: number,
    bufferValue: number,
    optimization: string
  ) => {
    if (isGenerating) {
      updatePlanTemplateGenerationState({
        type: SET_IS_TEMPLATE_GENERATING,
        payload: true,
      })
    } else {
      updatePlanTemplateGenerationState({
        type: SET_OPTIMIZATION,
        payload: optimization,
      })
      updatePlanTemplateGenerationState({
        type: SET_IS_TEMPLATE_GENERATING,
        payload: false,
      })
      updatePlanTemplateGenerationState({
        type: SET_BUFFER,
        payload: bufferValue,
      })
    }
  }

  const onMergeError = () => {
    updatePlanTemplateGenerationState({
      type: SET_IS_TEMPLATE_GENERATING,
      payload: false,
    })
  }

  const {
    data: mergeData,
    isLoading: isMergeLoading,
    isFetched: isMergeDataFetched,
  } = useTemplateSummary(
    projectId,
    planTemplateId,
    parseInt(sourceSiteId),
    parseInt(workcentreId),
    optimization,
    true,
    onMergeSuccess,
    onMergeError
  )

  const [tab, setTab] = useState<number>(0)

  const [tableData, setTableData] = useState<PlanDetailsColumns[] | undefined>(
    undefined
  )

  const [runRateData, setRunRateData] = useState<RunRateTableRow[] | null>(null)

  const [comparisonData, setComparisonData] = useState<any>(null)

  const [updatedRow, setUpdatedRow] = useState<UpdatedRow | undefined>(
    undefined
  )

  const [planTemplateGenerationState, updatePlanTemplateGenerationState] =
    usePlanTemplateGenerationState({
      buffer: '0',
      defaultWorkCenterCapacity: '',
      workcentreCapacity: '',
      combinedBuffer: '0',
      combinedWorkcentreCapacity: '',
      optimization: 'constrained',
      isTemplateGenerating: false,
      planTemplateGererationValidations: [],
      isInitialCycleSpeedAndSummaryDataFetched: false,
      isCycleSpeedOrSummaryDataLoading: false,
    })

  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    if (mergeData) {
      if (mergeData?.templateGenerationStatus == 'COMPLETED') {
        const summary = mapSummaryObjectToArray(
          mergeData.weeklySummary?.summary
        )

        let weeklySummary = { ...mergeData.weeklySummary, summary }
        updatePlanTemplateGenerationState({
          type: SET_SUMMARY_DATA,
          payload: weeklySummary,
        })

        setGraphData(mergeData.detailsGraph?.data)
        let cycleData = mergeData.cycles
        updatePlanTemplateGenerationState({
          type: SET_CYCLE_SPEED_DATA,
          payload: cycleData,
        })
        setGraphData(mergeData.detailsGraph?.data)
      } else {
        updatePlanTemplateGenerationState({
          type: SET_SUMMARY_DATA,
          payload: [],
        })

        setGraphData([])
      }
    }
  }, [mergeData])

  useEffect(() => {
    if (data) {
      setTableData(data?.details)
      setRunRateData(data?.runRateTable)
      setComparisonData(data?.currentComparison)
    }
  }, data)

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdatedRow(undefined)
    }, 1000)

    return () => clearTimeout(timer)
  }, [updatedRow])

  const { refetch: downloadPlanTemplate } = useDownloadPlanDetails(
    planTemplateId,
    sourceSiteId,
    workcentreId,
    optimization
  )

  const handleOptimizationChange = (optimization: string) => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteId}/${workcentreId}/plan-details?optimization=${optimization}`
    )
    setOptimization(optimization)
  }

  const onSuccess = () => {
    toast.success('Update succesful')
  }

  const onError = () => {
    toast.error('Update failed')
  }

  const { mutate: saveChanges, isLoading: savingChanges } =
    useSaveTemplateChanges(onSuccess, onError)

  const handleSaveChanges = () => {
    if (tableData) {
      const newTemplate = tableData.map(
        (row: PlanDetailsColumns, idx: number) => {
          return {
            ...row,
            id: idx,
          }
        }
      )
      if (tableData) {
        saveChanges({
          newTemplate: newTemplate,
          planTemplateId: planTemplateId,
          workcentreId: workcentreId,
          sourceSiteId: sourceSiteId,
          method: optimization,
        })
      }
    }
  }

  const handleDownload = () => {
    downloadPlanTemplate()
  }

  return (
    <Page>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 1350,
        }}
      >
        <PageHeader
          title="Template details"
          rightElement={
            <div style={{ display: 'flex' }}>
              <PlanDetailsOptimizationDropDown
                label="Optimisation type"
                menuItems={optimizationTypes}
                value={optimization}
                handleOptimizationChange={handleOptimizationChange}
              />
              <div className={classes.downloadWrapper}>
                <Tooltip title="Download CSV">
                  <IconButton
                    className={
                      !data?.length
                        ? classes.iconButtonDisabled
                        : classes.iconButton
                    }
                    color="primary"
                    aria-label="Download"
                    onClick={handleDownload}
                    disabled={!data?.length}
                  >
                    <GetAppIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          }
        />
        <div>
          {isLoading || !tableData || isMergeLoading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'centre:',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 550,
                maxHeight: 550,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <TableTabs tab={tab} setTab={setTab} />
                <div className={classes.saveButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveChanges}
                    disabled={savingChanges}
                  >
                    {savingChanges && (
                      <CircularProgress
                        color="inherit"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    Save Changes
                  </Button>
                </div>
              </div>
              <Paper>
                {tab === 0 ? (
                  <ResultsTabView
                    planTemplateGenerationState={planTemplateGenerationState}
                    graphData={graphData}
                  />
                ) : tab === 1 ? (
                  <PlanDetailsTable
                    data={tableData}
                    setData={setTableData}
                    updatedRow={updatedRow}
                    setUpdatedRow={setUpdatedRow}
                  />
                ) : tab === 2 ? (
                  <RunRateTable
                    data={runRateData}
                    updateData={setRunRateData}
                    currentPlanDetails={tableData}
                    setPlanDetails={setTableData}
                  />
                ) : (
                  <CurrentComparisonTable data={comparisonData} />
                )}
              </Paper>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}
