import { useMemo } from 'react'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { BatchSettingsTableProps } from './BatchSettings.types'
import { BatchGroupingSettings } from 'Features/PlanTemplate/StepCard/StepCard.types'
import { UnitOfMeasureLabel } from 'Features/PlanTemplate/ProductSettings/UnitOfMeasureLabel/UnitOfMeasureLabel.view'
import { TEMPLATE_FOR_COMBINED_MAKER_AND_LINE } from './BatchSettings.constants'
import { Loader } from 'Common/Loader/Loader'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'

export const BatchSettingsTable = ({
  data,
  setSelectedRows,
  unitOfMeasure,
  templateBasedOn,
}: BatchSettingsTableProps) => {
  const getTableHeight = () => {
    const MIN_HEIGHT = 640
    const availableHeight = window.outerHeight - 240
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  if (!data) {
    return <Loader />
  }

  const tableData = data.map((item, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const batchCodes = [
    ...new Set(
      data.map((item: BatchGroupingSettings) => {
        return item.batchGroupingCode
      })
    ),
  ]
  const repl_types = [
    ...new Set(
      data.map((item: BatchGroupingSettings) => {
        return item.replenishmentType
      })
    ),
  ]
  // @ts-ignore
  const MUIcolumns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }

    const defaultColumns = [
      {
        headerName: 'Batch group code',
        field: 'batchGroupingCode',
        minWidth: 100,
        type: 'string',
        flex: 1,
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'Search',
            },
          },
        ],
      },
      {
        headerName: 'Replenishment type',
        field: 'replenishmentType',
        minWidth: 100,
        type: 'string',
        flex: 1,
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'Search',
            },
          },
        ],
      },
      {
        headerName: 'Sales rate',
        field: 'salesRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Run rate',
        field: 'runRate',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'UoM',
        field: 'unitOfMeasure',
        minWidth: 100,
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.unitOfMeasureId}
            label={params.row.unitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
        flex: 1,
      },
      {
        headerName: 'Minimum batch size',
        field: 'minimumBatchSize',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Minimum batch increment',
        field: 'minimumBatchSizeIncrement',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'UoM',
        field: 'unitOfMeasure2',
        minWidth: 100,
        type: 'string',
        renderCell: (params: any) => (
          <UnitOfMeasureLabel
            id={params.row.unitOfMeasureId}
            label={params.row.unitOfMeasure}
            unitOfMeasure={unitOfMeasure}
          />
        ),
        flex: 1,
      },
      {
        headerName: 'Min cycle',
        field: 'minimumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
      {
        headerName: 'Max cycle',
        field: 'maximumCycle',
        minWidth: 100,
        type: 'number',
        flex: 1,
      },
    ]

    if (templateBasedOn === TEMPLATE_FOR_COMBINED_MAKER_AND_LINE) {
      return defaultColumns
    }

    return defaultColumns.filter((item) => item.field !== 'runRate')
  }, [data, templateBasedOn])

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  if (!data) {
    return <Loader />
  }

  return (
    <div style={{ height: getTableHeight() }}>
      <MUITable
        rows={tableData}
        checkboxSelection
        columns={MUIcolumns}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
        pagination
        pageSizeOptions={[10, 20, 30, 50]}
        experimentalFeatures={{ lazyLoading: true }}
      />
    </div>
  )
}
