import { Box, Typography } from '@material-ui/core'
import { InventoryDriverTable } from './InventoryDriverTable/InventoryDrivers.table'
import { InventoryOutputsTable } from './InventoryOutputsTable/InventoryOutputs.table'
import { StockVolumeGraph } from './StockVolume/StockVolume.graph'
import { StockValueGraph } from './StockValue/StockValue.graph'
import { InventoryAnalysisViewProps } from './InventoryAnalysis.types'

export const InventoryAnalysisView = ({
  volumeGraph,
  valueGraph,
  inventoryDrivers,
  inventoryOutputs,
}: InventoryAnalysisViewProps) => {
  return (
    <Box display="flex">
      <Box width={'40%'}>
        <Typography variant="h4">
          Stock levels for different scenarios by stock volume
        </Typography>
        <Box height="400px" mb={4}>
          <StockVolumeGraph
            xAxisData={volumeGraph.map((item) => item.label)}
            cycleStockVolume={volumeGraph.map((item) => item.cycleStockVolume)}
            safetyStockVolume={volumeGraph.map(
              (item) => item.safetyStockVolume
            )}
          />
        </Box>
        <Typography variant="h4">
          Stock levels for different scenarios by stock value
        </Typography>
        <Box height="400px">
          <StockValueGraph
            xAxisData={valueGraph.map((item) => item.label)}
            cycleStockValue={valueGraph.map((item) => item.cycleStockValue)}
            safetyStockValue={valueGraph.map((item) => item.safetyStockValue)}
          />
        </Box>
      </Box>
      <Box display="flex" width="60%" flexDirection="column">
        <Box mb={2} minHeight="438px">
          <Typography variant="h4">Inventory drivers</Typography>
          <InventoryDriverTable data={inventoryDrivers} isLoading={false} />
        </Box>
        <Box>
          <Typography variant="h4">Inventory outputs</Typography>
          <InventoryOutputsTable data={inventoryOutputs} isLoading={false} />
        </Box>
      </Box>
    </Box>
  )
}
