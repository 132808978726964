import {
  PlanDetailsTableProps,
  PlanDetailsColumns,
  WeekCount,
} from './PlanDetails.types'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { useMemo } from 'react'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import { Box, IconButton } from '@mui/material'
import { useStyles } from './PlanDetails.styles'

const _get_week_count = (data: PlanDetailsColumns[]): {} => {
  const week_count: WeekCount = {}

  for (let i = 1; i <= 8; i++) {
    week_count[i] = 0
  }

  data.forEach((obj: PlanDetailsColumns) => {
    week_count[obj.week] += 1
  })

  return week_count
}

const sortByFields = (array: PlanDetailsColumns[], fields: any[]) => {
  return [...array].sort((a, b) => {
    for (let { key, ascending } of fields) {
      if (a[key] < b[key]) return ascending ? -1 : 1
      if (a[key] > b[key]) return ascending ? 1 : -1
    }
    return 0
  })
}

export const PlanDetailsTable = ({
  data,
  setData,
  updatedRow,
  setUpdatedRow,
}: PlanDetailsTableProps) => {
  const classes = useStyles()

  const getTableHeight = () => {
    const MIN_HEIGHT = 640
    const availableHeight = window.outerHeight - 100
    return availableHeight > MIN_HEIGHT ? availableHeight : MIN_HEIGHT
  }

  const tableData = data.map((item: PlanDetailsColumns, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const skuCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.productCode
      })
    ),
  ]

  const weekCounts: WeekCount = _get_week_count(data)

  const handleUp = (row: PlanDetailsColumns) => {
    const { week, sequence } = row

    const newData: PlanDetailsColumns[] = tableData.map(
      (item: PlanDetailsColumns) => {
        if (item.week === week && item.sequence === sequence) {
          return { ...item, sequence: sequence + 1 }
        }

        if (item.week === week && item.sequence === sequence + 1) {
          return { ...item, sequence: sequence }
        }

        return item
      }
    )

    const sortedData = sortByFields(newData, [
      { key: 'week', ascending: true },
      { key: 'sequence', ascending: true },
    ])

    setData(sortedData)
    setUpdatedRow({
      week: week,
      sequence: sequence + 1,
    })
  }

  const handleDown = (row: PlanDetailsColumns) => {
    const { week, sequence } = row

    const newData: PlanDetailsColumns[] = tableData.map(
      (item: PlanDetailsColumns) => {
        if (item.week === week && item.sequence === sequence) {
          return { ...item, sequence: sequence - 1 }
        }

        if (item.week === week && item.sequence === sequence - 1) {
          return { ...item, sequence: sequence }
        }

        return item
      }
    )

    const sortedData = sortByFields(newData, [
      { key: 'week', ascending: true },
      { key: 'sequence', ascending: true },
    ])

    setData(sortedData)
    setUpdatedRow({
      week: week,
      sequence: sequence - 1,
    })
  }

  const renderMoveButtons = (params: any) => {
    const row: PlanDetailsColumns = params.row
    const { sequence, week } = row

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box>
          <IconButton disabled={sequence <= 1} onClick={() => handleDown(row)}>
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            disabled={sequence === weekCounts[week]}
            onClick={() => handleUp(row)}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Box>
        {sequence}
      </Box>
    )
  }

  const batchCodes = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.batchGroupCode
      })
    ),
  ]

  const repl_types = [
    ...new Set(
      data.map((item: PlanDetailsColumns) => {
        return item.replenishmentType
      })
    ),
  ]

  const MUIColumns: GridColDefPremium[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'productCode',
        headerName: 'SKU group code',
        sortable: true,
        filterble: true,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
      },
      {
        field: 'week',
        headerName: 'Week',
        type: 'number',
      },
      {
        field: 'sequence',
        headerName: 'Sequence',
        renderCell: renderMoveButtons,
        type: 'number',
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'search',
            },
          },
        ],
        type: 'string',
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
      },
      {
        field: 'cycle',
        headerName: 'Cycle',
        type: 'number',
      },
      {
        field: 'runRate',
        headerName: 'Run rate',
        type: 'number',
      },
      {
        field: 'runTime',
        headerName: 'Run time',
        type: 'number',
      },
      {
        field: 'changeoverTime',
        headerName: 'C/O time',
        type: 'number',
      },
      {
        field: 'inBatchChangeoverTime',
        headerName: 'InBatch C/O time',
        type: 'number',
      },
      {
        field: 'totalTime',
        headerName: 'Total time',
        type: 'number',
      },
      {
        field: 'batchGroupCode',
        headerName: 'Batch group code',
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'search',
            },
          },
        ],
      },
      {
        field: 'batchCycle',
        headerName: 'Batch cycle',
        type: 'number',
      },
      {
        field: 'batchStartWeek',
        headerName: 'Batch start week',
        type: 'number',
      },
    ]
  }, [data])

  return (
    <div style={{ height: getTableHeight() }}>
      <MUITable
        rows={tableData}
        checkboxSelection
        columns={MUIColumns}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pagination
        pageSizeOptions={[20, 30, 50]}
        disableRowSelectionOnClick
        getRowClassName={(params) => {
          const row = { week: params.row.week, sequence: params.row.sequence }
          return updatedRow &&
            row.week === updatedRow.week &&
            row.sequence === updatedRow.sequence
            ? classes.flash
            : ''
        }}
      />
    </div>
  )
}
