import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  heading: {
    borderBottom: `1px solid ${theme.palette.borders.grey}`,
    padding: '32px',
    marginBottom: '16px',
  },
  label: {
    marginBottom: '8px',
  },
  input: {
    width: '150px',
  },
  select: {
    minWidth: '150px',
  },
  formControl: {
    marginRight: '64px',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '64px',
  },
  calendarItem: {
    display: 'flex',
    width: '150px',
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '140px',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    alignSelf: 'center',
  },
  snapshotDates: {
    marginTop: '20px',
  },
}))
