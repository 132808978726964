import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  actionContainer: {
    padding: '15px 40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  secondaryButton: {
    marginRight: '20px',
  },
  actionButton: {
    marginLeft: '20px',
  },
  progress: {
    marginRight: '8px',
  },
})
