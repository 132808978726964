import { Box, Divider, Paper, Typography } from '@material-ui/core'
import { SummaryGraphToggleView } from 'Features/PlanTemplateGeneration/Summary/SummaryGraphToggle.view'
import { SummaryGraph } from 'Features/PlanTemplateGeneration/Summary/Summary.graph'
import { SequenceOfEventsGraph } from 'Features/PlanTemplateGeneration/Summary/SequenceOfEvents/SequenceOfEvents.graph'
import { Grid } from '@material-ui/core'
import { useStyles } from '../PlanTemplateGeneration/PlanTemplateGeneration.styles'
import { CycleSpeedView } from 'Features/PlanTemplateGeneration/CycleSpeed/CycleSpeed.view'
import { SummaryList } from 'Features/PlanTemplateGeneration/Summary/SummaryList.view'
import {
  PlanTemplateGenerationState,
  WeeklyData,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.types'
import { SummaryItem } from 'Features/PlanTemplateGeneration/Summary/Summary.types'
import { useMemo } from 'react'
import { isInteger } from 'Common/Utils/utils'
import { Loader } from 'Common/Loader/Loader'

type ResultsTabViewProps = {
  planTemplateGenerationState: PlanTemplateGenerationState
  graphData: any
}

export const ResultsTabView = ({
  planTemplateGenerationState,
  graphData,
}: ResultsTabViewProps) => {
  if (!graphData) {
    return <Loader />
  }

  if (!planTemplateGenerationState) {
    return <Loader />
  }
  const classes = useStyles()

  const { cycleSpeedData, summaryData } = planTemplateGenerationState

  const getAverageUtilisationHours = () => {
    return summaryData?.summary?.filter(
      (summary: SummaryItem) => summary.label === 'Average utilised hours'
    )[0].value
  }

  const summaryGraphXAxisData = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.weekNo
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataOne = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalProductionTime
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataTwo = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalChangeoverTime
      ),
    [summaryData]
  )

  const summaryGraphYAxisDataThree = useMemo(
    () =>
      summaryData?.graphData?.weeklyData?.map(
        (data: WeeklyData) => data.totalBatchChangeoverTime
      ),
    [summaryData]
  )

  const getAverageUtilisation = () => {
    const utilisation = summaryData?.graphData?.averageUtilisation
    return isInteger(utilisation ?? 0) ? utilisation : utilisation?.toFixed(1)
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Paper className={classes.paper}>
          <SummaryGraphToggleView
            TotalledGraphView={
              <SummaryGraph
                traceOneName={'Total production time'}
                traceTwoName={'Total changeover time'}
                traceThreeName={'Total Batch changeover time'}
                xAxisData={summaryGraphXAxisData ?? []}
                yAxisDataOne={summaryGraphYAxisDataOne ?? []}
                yAxisDataTwo={summaryGraphYAxisDataTwo ?? []}
                yAxisDataThree={summaryGraphYAxisDataThree ?? []}
                capacityReferenceValue={
                  summaryData?.graphData?.workcentreCapacity
                }
                foqAdjustedCapacity={
                  summaryData?.graphData?.foqAdjustedCapacity ?? 0
                }
                averageUtilisationPercentage={getAverageUtilisation()}
                averageUtilisationHours={getAverageUtilisationHours()}
                tooltipText={summaryData?.graphData?.weeklyData.map(
                  (data: WeeklyData) => data.percentageOfUtilisation.toFixed(1)
                )}
              />
            }
            SequencedGraphView={
              <SequenceOfEventsGraph
                graphData={graphData}
                capacityReferenceValue={
                  summaryData?.graphData?.workcentreCapacity ?? 0
                }
                averageUtilisationPercentage={getAverageUtilisation()}
                averageUtilisationHours={getAverageUtilisationHours()}
              />
            }
          />
        </Paper>
      </Grid>
      <Grid item md={6}>
        <Paper className={classes.paper}>
          <Box
            pt="16px"
            pb="24px"
            px={3}
            display="flex"
            flexDirection="column"
            height="800px"
          >
            <Box marginBottom="10px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="10px"
              >
                <Box>
                  <Typography variant="h3">Plan template summary</Typography>
                </Box>
              </Box>
              <Divider />
            </Box>

            <>
              <SummaryList summaryListData={summaryData?.summary ?? []} />
              <Box className={classes.summaryGraphWrapper}>
                <CycleSpeedView
                  data={cycleSpeedData?.data}
                  optimization={
                    cycleSpeedData?.constrained
                      ? 'constrained'
                      : 'unconstrained'
                  }
                />
              </Box>
            </>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
