import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { PageProps } from './Page.types'

export const useStyles = makeStyles<Theme, PageProps>(() => ({
  wrapper: (props) => ({
    display: 'flex',
    flex: 'auto',
    padding: '32px 36px',
    ...(props.center ? { justifyContent: 'center', alignItems: 'center' } : {}),
  }),
}))
