import {
  Configuration,
  InteractionRequiredAuthError,
  LogLevel,
} from '@azure/msal-browser'
import { msalInstance } from '../../'
const tenantID: string = process.env.TENANT_ID as string
const applicationID: string = process.env.APP_ID as string
const redirectUri: string = `${window.location.origin}/tenants` as string
const signInAuthority = `https://login.microsoftonline.com/${tenantID}`

export const msalConfig: Configuration = {
  auth: {
    clientId: applicationID,
    authority: signInAuthority,
    redirectUri: redirectUri,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

export const getUserEmail = async () => {
  const account = msalInstance.getActiveAccount()
  return account?.username
}

export const getUsername = async () => {
  // Get username from the msInstance
  const account = msalInstance.getActiveAccount()
  return account?.name
}

export const getToken = async () => {
  const tokenRequest = {
    scopes: ['User.Read'], // Add the scopes your app needs
    account: msalInstance?.getActiveAccount() ?? undefined, // Use the logged-in account
  }
  try {
    // Attempt to get the token silently
    const response = await msalInstance.acquireTokenSilent(tokenRequest)
    return response
  } catch (error) {
    console.error('Silent token acquisition failed:', error)

    if (error instanceof InteractionRequiredAuthError) {
      try {
        // Fallback to popup interaction
        const response = await msalInstance.acquireTokenRedirect(tokenRequest)
        return response
      } catch (popupError) {
        console.error('Token acquisition via popup failed:', popupError)
      }
    }
  }
  return null
}
