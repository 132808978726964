import axios from 'axios'
import { getToken } from 'Features/Auth/auth'
import { toast } from 'react-hot-toast'
import { msalInstance } from '../../'

const client = axios.create({
  baseURL: process.env.MODELLING_BASE_URL,
})

export const request = async ({ ...options }) => {
  const token = await getToken()
  client.defaults.headers.common.Authorization = `Bearer ${token?.idToken}`
  const tenantId = localStorage.getItem('tenantId')
  if (tenantId) {
    client.defaults.headers.common.Tenant = tenantId
  }
  return client(options)
}

client.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response?.status === 401) {
      toast.dismiss()
      toast.error('You are not authorized to access this resource.')
      const tokenRequest = {
        scopes: ['User.Read'], // Add the scopes your app needs
        account: msalInstance?.getActiveAccount() ?? undefined, // Use the logged-in account
      }
      try {
        await msalInstance.acquireTokenRedirect(tokenRequest)
      } catch (error) {
        console.error('Token acquisition failed:', error)
      }
    }

    return error.response.status
  }
)

const clientV2 = axios.create({
  baseURL: process.env.V2_MODELLING_BASE_URL,
})

export const requestV2 = async ({ ...options }) => {
  const token = await getToken()
  clientV2.defaults.headers.common.Authorization = `Bearer ${token?.idToken}`
  const tenantId = localStorage.getItem('tenantId')
  if (tenantId) {
    clientV2.defaults.headers.common.Tenant = tenantId
  }
  return clientV2(options)
}
