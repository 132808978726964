import { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Typography, Popover, Divider } from '@material-ui/core'
import { PageHeader, Button } from 'supplyvue-ui'
import { Page } from 'Common/Page/Page'
import { EmptyState } from 'Common/EmptyState/EmptyState'
import { SnapshotModal } from 'Features/Project/SnapshotModal/SnapshotModal.view'
import { WorkcentreModal } from 'Features/Project/WorkcentreModal/WorkcentreModal.view'
import SnapshotIcon from 'Assets/react-icons/SnapshotIcon'
import WorkcentreIcon from 'Assets/react-icons/WorkcentreIcon'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { SourceSiteDetails } from 'Features/Project/WorkcentreModal/WorkcentreModal.types'
import { useProjectDetails } from './useProjectDetails'
import { useStyles } from './ProjectDetails.styles'
import {
  ProjectDetailsParams,
  Scenario,
  ProjectDetails,
  ProjectDataType,
  TemplateSelectionOption,
} from './ProjectDetails.types'
import { Loader } from 'Common/Loader/Loader'
import { getSnapshotDetailsInString } from 'Features/Project/SnapshotModal/SnapshotModal.utils'
import { SnapshotDetails } from 'Features/Project/SnapshotModal/SnapshotModal.types'
import { CreateScenarioModal } from 'Features/Project/Scenario/CreateScenarioModal/CreateScenarioModal.view'
import { ScenarioCard } from 'Features/Project/Scenario/ScenarioCard/ScenarioCard.view'
import { useBreadcrumbsData } from 'Common/Breadcrumbs/Breadcrumbs.data'
import { HeaderActions } from './HeaderActions.view'
import { toast } from 'react-hot-toast'
import { CostOfCapital } from '../CostOfCapital/CostOfCapital.view'
import { MAX_SCENARIOS_ALLOWED } from './ProjectDetails.constants'
import { emptyScenario } from './ProjectDetails.utils'

import {
  useCloneScenario,
  useDownloadData,
  useProjects,
} from './ProjectDetails.data'
import { format } from 'date-fns'
import { useUpdateProjectAccess } from '../ProjectList/ProjectList.data'
import { getUserEmail } from 'Features/Auth/auth'
import { DownloadDataModalView } from 'Features/Project/DownloadDataModal/DownloadDataModal.view'
import { DOWNLOAD_VERSION } from 'Features/Project/DownloadDataModal/DownloadData.constants'

export const ProjectDetailsPage = () => {
  const { projectId } = useParams<ProjectDetailsParams>()
  useBreadcrumbsData({ project_id: projectId })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { snapshot, workcentre, projectDetails, scenario } =
    useProjectDetails(projectId)

  const disableConfirm = (option: TemplateSelectionOption): boolean => {
    // Disable the confirm button if existing option is chosen but no scenario is selected
    if (option.option == 2) {
      if (!option.scenarioId) {
        return true
      }
    }
    return false
  }

  const { data: projectsDataResponse, isLoading: isDropdownLoading } =
    useProjects()

  const [projects, setProjects] = useState<ProjectDataType[] | undefined>(
    undefined
  )

  useEffect(() => {
    if (projectsDataResponse) {
      setProjects(projectsDataResponse)
    }
  }, [projectsDataResponse])

  const [userEmail, setUserEmail] = useState<string | null>(null)
  useEffect(() => {
    if (userEmail === null) {
      getUserEmail().then((email: string) => {
        setUserEmail(email ? email.toLocaleLowerCase() : '')
      })
    }
    return () => {
      setUserEmail(null)
    }
  }, [])

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false)

  const [downloadVersion, setDownloadVersion] = useState<string>(
    DOWNLOAD_VERSION[0].value
  )

  const onDownloadSuccess = (downloadData: any) => {
    const fileName = `DataDownload__${format(new Date(), 'yyyyMMdd')}`
    const url = window.URL.createObjectURL(new Blob([downloadData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const onDownloadError = () => {
    toast.error('Error in download')
  }
  const { mutate: downloadData, isLoading: isDownloading } = useDownloadData(
    onDownloadSuccess,
    onDownloadError
  )

  const handleDownloadModalSave = () => {
    downloadData({
      projectDetails: projectDetails.scenariosList.filter(
        (item: ProjectDetails) => item.createdOn !== null
      ),
      downloadMode: downloadVersion,
    })
    setIsDownloadModalOpen(false)
  }

  const handleDownloadModalCancel = () => {
    setIsDownloadModalOpen(false)
  }

  const [currentScenarioAccessChangeIdState, setCurrentScenarioAccessChangeId] =
    useState<string | null>(null)

  const onCloneSuccess = () => {
    setCloneScenarioName('')
    toast.success('Clone process initiated successfully')
  }

  const onCloneError = () => {
    toast.error('Clone process failed to initiate')
  }

  const { mutate: cloneScenario, isLoading: isCloning } = useCloneScenario(
    onCloneSuccess,
    onCloneError
  )

  const projectAccess = useMemo(() => {
    if (projectDetails?.scenariosList) {
      if (projectDetails?.projectAccess) {
        return projectDetails.projectAccess
      }
      if (!projectDetails?.projectAccess) {
        const sa: Record<string, 'public'> = {}
        for (const scenario of projectDetails.scenariosList) {
          sa[scenario.scenarioId as string] = 'public'
        }
        return {
          projectAccess: { visibility: 'public' },
          scenarioAccess: sa,
        }
      }
    }
  }, [projectDetails])

  /******************Change access */

  const onProjectAccessUpdateSuccess = () => {
    setCurrentScenarioAccessChangeId(null)
    toast.success('Project access updated successfully')
  }

  const onProjectAccessUpdateFail = () => {
    toast.error('Failed to update project access')
  }

  const { mutate: updateProjectAccess, isLoading: isProjectAccessUpdating } =
    useUpdateProjectAccess(
      onProjectAccessUpdateSuccess,
      onProjectAccessUpdateFail,
      ['project-details', projectId]
    )

  const handleScenarioAccess = (access: string, scenarioId: string) => {
    const pa = { ...projectAccess }
    pa.scenarioAccess = {
      ...pa.scenarioAccess,
      [scenarioId]: access,
    }
    setCurrentScenarioAccessChangeId(scenarioId)
    updateProjectAccess({
      projectId,
      projectAccess: pa,
    })
  }

  /******************End change access */

  const history = useHistory()

  const open = Boolean(anchorEl)
  const id = open ? 'snapshot-popover' : undefined
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleSnapshotInfoClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleWorkCentresInfoClick = () => {
    workcentre.setIsModalOpen(true)
  }

  const handleCreateSnapshotClick = () => {
    snapshot.setIsModalOpen(true)
  }

  const handleSelectWorkcentresClick = () => {
    workcentre.setIsModalOpen(true)
  }

  const handleNewScenarioClick = (
    scenarioIndex: number,
    scenariosListCount?: number
  ) => {
    if (!!scenariosListCount && scenariosListCount >= MAX_SCENARIOS_ALLOWED) {
      toast.error(
        `Cannot create more than ${MAX_SCENARIOS_ALLOWED} scenarios for a project`
      )
    } else {
      scenario.setScenarioIndex(scenarioIndex)
      scenario.setIsModalOpen(true)
    }
  }

  const handleCompareScenarioClick = () => {
    history.push(`/projects/${projectId}/compare-all-scenarios`)
  }

  const handleSnapshotModalOkButtonClick = (
    snapshotName: string,
    endDate: Date,
    snapshotLengthValue: number,
    selectedSnapshot: SnapshotDetails
  ) => {
    snapshot.handleOkButtonClick(
      snapshotName,
      endDate,
      snapshotLengthValue,
      selectedSnapshot,
      projectDetails.projectUserId
    )
  }

  const handleSelectedPlanTemplateClick = (
    scenarioId: string,
    planTemplateId: string
  ) => {
    history.push(
      `/projects/${projectId}/snapshots/${projectDetails.snapshotDetails.snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}`
    )
  }

  const handleSelectedReplenishmentPolicyClick = (
    scenarioId: string,
    replenishmentPolicyId: string
  ) => {
    history.push(
      `/projects/${projectId}/scenarios/${scenarioId}/replenishment-policy-inputs/${replenishmentPolicyId}/network-replenishment-settings`
    )
  }

  const handleViewScenarioClick = (scenarioId: string) => {
    history.push(
      `/projects/${projectId}/compare-all-scenarios/view-scenario/${scenarioId}`
    )
  }
  const handleDeleteScenarioClick = (scenarioId: string) => {
    scenario.handleDeleteScenarioClick(scenarioId)
  }

  const [cloneScenarioName, setCloneScenarioName] = useState<string>('')

  const handleCloneScenarioNameChange = (value: string) => {
    setCloneScenarioName(value)
  }

  const handleCloneScenarioClick = (scenarioId: string) => {
    cloneScenario({ scenarioId, projectId, scenarioName: cloneScenarioName })
  }

  const classes = useStyles()

  const handlePlanGenarionClick = () => {
    const { snapshotId } = projectDetails.snapshotDetails
  }

  const currentTimeStamp = Date.now()

  if (projectDetails.isLoading) {
    return (
      <Page center>
        <Loader />
      </Page>
    )
  }

  if (isDropdownLoading) {
    return (
      <Page center>
        <Loader />
      </Page>
    )
  }

  return (
    <Page>
      <Box display="flex" flexDirection="column" flex={1}>
        <PageHeader
          title="Scenario setup"
          rightElement={
            projectDetails.snapshotDetails &&
            !!projectDetails.workcentresCount &&
            projectDetails.scenariosList.length ? (
              <HeaderActions
                handleCompareScenarioClick={handleCompareScenarioClick}
                downloadDisabled={
                  !projectDetails.scenariosList.filter(
                    (item: ProjectDetails) => item.createdOn !== null
                  ).length
                }
                isDownloading={isDownloading}
                handleDownloadModalOpen={setIsDownloadModalOpen}
              />
            ) : null
          }
        />
        {/** No snapshot selected */}
        {!projectDetails.snapshotDetails && (
          <EmptyState
            icon={<SnapshotIcon width="200px" height="200px" />}
            text="Please select an existing snapshot or import a new data snapshot for the project"
            actionText="Select snapshot"
            handleClick={handleCreateSnapshotClick}
          />
        )}
        {/** No work centre selected */}
        {projectDetails.snapshotDetails && !projectDetails.workcentresCount && (
          <EmptyState
            icon={<WorkcentreIcon width="200px" height="200px" />}
            text="Please select one or more work centres to model as part of this project"
            actionText="Select work centres"
            handleClick={handleSelectWorkcentresClick}
          />
        )}

        {/** Scenario List */}

        {projectDetails.snapshotDetails && !!projectDetails.workcentresCount && (
          <Box className={classes.cardContainer}>
            {projectDetails?.scenariosList?.map(
              (scenario: Scenario, index: number) => {
                return (
                  <Box
                    style={{ position: 'relative', zIndex: index * -1 }}
                    key={`index-${
                      scenario.scenarioId
                        ? scenario.scenarioId
                        : `${index}-${currentTimeStamp}`
                    }`}
                  >
                    <ScenarioCard
                      key={`scenario-card-${
                        scenario.scenarioId
                          ? scenario.scenarioId
                          : `${index}-${currentTimeStamp}`
                      }`}
                      index={index}
                      scenario={scenario?.createdOn ? scenario : emptyScenario}
                      status={scenario.status}
                      projectId={projectId}
                      snapshotId={projectDetails.snapshotDetails.snapshotId}
                      handleNewScenarioClick={handleNewScenarioClick}
                      handleSelectedPlanTemplateClick={
                        handleSelectedPlanTemplateClick
                      }
                      handleSelectedReplenishmentPolicyClick={
                        handleSelectedReplenishmentPolicyClick
                      }
                      handleViewScenarioClick={handleViewScenarioClick}
                      handleDeleteScenarioClick={handleDeleteScenarioClick}
                      handleCloneScenarioClick={handleCloneScenarioClick}
                      handleCloneScenarioNameChange={
                        handleCloneScenarioNameChange
                      }
                      isCloning={isCloning}
                      cloneScenarioName={cloneScenarioName}
                      handlePlanGenarionClick={handlePlanGenarionClick}
                      access={scenario.access}
                      projectAccess={projectAccess}
                      handleScenarioAccess={handleScenarioAccess}
                      isProjectAccessUpdating={
                        isProjectAccessUpdating &&
                        currentScenarioAccessChangeIdState ===
                          scenario.scenarioId
                      }
                      enableLock={userEmail === projectDetails.projectUserId}
                    />
                  </Box>
                )
              }
            )}
          </Box>
        )}
        <Box display="flex">
          {projectDetails.snapshotDetails && (
            <Box marginLeft={1}>
              <Button
                className={classes.textbutton}
                aria-describedby={id}
                color="primary"
                startIcon={<InfoOutlinedIcon />}
                onClick={handleSnapshotInfoClick}
              >
                Snapshot
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={classes.popperText} variant="h2">
                  {getSnapshotDetailsInString(projectDetails.snapshotDetails)}
                </Typography>
              </Popover>
            </Box>
          )}
          {projectDetails.snapshotDetails && !!projectDetails.workcentresCount && (
            <>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <Button
                className={classes.textbutton}
                aria-describedby={id}
                color="primary"
                startIcon={<InfoOutlinedIcon />}
                onClick={handleWorkCentresInfoClick}
              >
                Work centres
              </Button>
            </>
          )}
          {projectDetails.snapshotDetails &&
            (projectDetails?.costOfCapital ||
              typeof projectDetails?.costOfCapital === 'number') && (
              <>
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
                <CostOfCapital
                  snapshotId={projectDetails?.snapshotDetails?.snapshotId}
                  costOfCapital={projectDetails?.costOfCapital}
                  handleCostOfCapitalUpdate={
                    projectDetails.handleCostOfCapitalUpdate
                  }
                  isCostOfCapitalLoading={
                    projectDetails?.isCostOfCapitalLoading
                  }
                />
              </>
            )}
        </Box>
      </Box>
      <SnapshotModal
        snapshotData={snapshot.data}
        isModalOpen={snapshot.isModalopen}
        handleModalClose={snapshot.handleModalClose}
        importStatus={snapshot.importStatus}
        handleOkButtonClick={handleSnapshotModalOkButtonClick}
      />
      <WorkcentreModal
        isUpdating={workcentre.isUpdating}
        isModalOpen={workcentre.isModalopen}
        handleModalClose={workcentre.handleModalClose}
        sourceSiteData={workcentre.data}
        handleOkbuttonClick={(sourceSiteData: SourceSiteDetails[]) =>
          workcentre.handleOkButtonClick(sourceSiteData)
        }
        readOnly={
          !!projectDetails.workcentresCount &&
          projectDetails.scenariosList.length
        }
      />
      <CreateScenarioModal
        isModalOpen={scenario.isModalOpen}
        handleModalClose={scenario.handleModalClose}
        heading="Create scenario"
        scenarioNameLabel="Create a new Scenario"
        scenarioName={scenario.name}
        handleScenarioNameChange={(e) => scenario.setName(e.target.value)}
        actionButtonLabel="Create"
        handleActionClick={scenario.handleCreateButtonClick}
        isActionDisabled={
          !scenario.name ||
          scenario.isCreatingScenario ||
          disableConfirm(scenario.templateSelectionOption)
        }
        isLoading={scenario.isCreatingScenario}
        errorMessage={scenario.errorMessage}
        selectionOption={scenario.templateSelectionOption}
        updateSelection={scenario.setTemplateSelectionOption}
        existingProjects={projects ?? []}
      />
      <DownloadDataModalView
        isModalOpen={isDownloadModalOpen}
        handleModalSave={handleDownloadModalSave}
        handleModalCancel={handleDownloadModalCancel}
        updateDownloadVersion={setDownloadVersion}
        downloadVersion={downloadVersion}
      />
    </Page>
  )
}
