import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '20px',
  },
  drawerContainer: {
    width: '750px',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  accordion: {
    border: `1px solid ${theme.palette.borders.grey}`,
  },
  accordionDetails: {
    flexDirection: 'column',
  },
}))
