import { useMemo } from 'react'
import { Graph } from 'Common/Graph/Graph'
import { StockValueGraphProps } from './StockValue.types'

export const StockValueGraph = ({
  xAxisData,
  cycleStockValue,
  safetyStockValue,
}: StockValueGraphProps) => {
  const hovertemplate = (
    yLabel: 'Cycle stock value' | 'Safety stock value'
  ) => {
    return (
      ` <b>Scenario: </b>%{x}<br>` +
      ` <b>${yLabel}: </b> $%{y}<br>` +
      '<extra></extra>'
    )
  }

  const data = useMemo(() => {
    const trace1 = {
      x: xAxisData,
      y: cycleStockValue,
      name: 'Cycle stock value ($)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('Cycle stock value'),
      marker: {
        color: '#58a14e',
      },
    }

    const trace2 = {
      x: xAxisData,
      y: safetyStockValue,
      name: 'Safety stock value ($)',
      type: 'bar',
      textfont: { color: 'transparent' },
      width: 0.65,
      hovertemplate: hovertemplate('Safety stock value'),
      marker: {
        color: '#4E79A7',
      },
    }

    return [trace2, trace1]
  }, [xAxisData, cycleStockValue, safetyStockValue])

  const layout = {
    yaxis: {
      showgrid: true,
      automargin: true,
      title: {
        text: 'Stock value ($)',
        standoff: 5,
      },
    },
    xaxis: {
      zeroline: true,
      nticks: 10,
    },
    barmode: 'stack',
    legend: { orientation: 'h', x: 0.05, y: -0.2 },
  }
  return <Graph data={data} layout={layout} />
}
