import _ from 'lodash'
import { WorkcentreSettingsResponse } from 'Features/PlanTemplate/TemplateSettings/TemplateSettings.types'
import { getInitialSettings } from './StepCard.state'
import { StepLabelType, WorkcentreSettingsState } from './StepCard.types'
export function getStepsLabel() {
  return [
    'Work centre settings',
    'SKU group settings',
    'SKU group changeover',
    'Batch group settings',
    'Batch group changeover',
    'Generate template',
  ]
}

export function getStepByIndex(index: number) {
  const steps = [
    'settings',
    'productSettings',
    'productChangeOvers',
    'batchGroupingSettings',
    'batchGroupingChangeOvers',
    'generateTemplate',
  ]
  return steps[index] as StepLabelType
}

export const haveWorkCentreSettingsChanged = (
  data: WorkcentreSettingsResponse,
  state: WorkcentreSettingsState
) => {
  const initialSettings = getInitialSettings(data)
  if (!_.isEqual(initialSettings, state)) {
    return true
  }
  return false
}

export const getSettingsInitialState = (
  workcentreSettingsData: WorkcentreSettingsResponse
) => {
  const { settings } = workcentreSettingsData
  /** Set default values for settings */
  const salesRateType = settings.salesRateType ?? 'forecast'
  const salesRatePeriod = settings.salesRatePeriod ?? ''
  const minimumAverageBatchesPerProductionCycle =
    settings.minimumAverageBatchesPerProductionCycle ?? 1

  const templateBasedOn = settings.templateBasedOn ?? 'Line only'
  return {
    salesRateType,
    salesRatePeriod,
    minimumAverageBatchesPerProductionCycle,
    templateBasedOn,
  }
}
