import { Box, Typography } from '@material-ui/core'
import { TotalCostGraph } from './TotalCost/TotalCost.graph'
import { DetailedAnalysisViewProps } from './DetailedAnalysis.types'
import { CompareScenariosTable } from 'Features/CompareScenarios/CompareScenarios.table'
import { InventoryDurationGraph } from './InventoryDuration/InventoryDuration.graph'

export const ScenarioSummary = ({
  totalCostGraph,
  compareScenariosData,
  disabledTableRows,
  handleRowClick,
  costOutputs,
  inventoryOutputs,
}: DetailedAnalysisViewProps) => {
  // Decided to use total scenario cost from cost outputs data for total cost graph
  const totalCostData = costOutputs?.map((item) =>
    item?.totalScenarioCost ? item?.totalScenarioCost : 0
  )

  if (totalCostData?.length <= 8) {
    for (let i = totalCostData?.length; i <= 8; i++) {
      totalCostData.push(0)
    }
  }

  const scenarioNames = totalCostGraph?.map((item) =>
    item?.label ? item?.label : ''
  )

  const bauDays = inventoryOutputs?.map((item) =>
    item?.bauDays ? item?.bauDays : 0
  )
  if (bauDays?.length <= 8) {
    for (let i = bauDays?.length; i <= 8; i++) {
      bauDays.push(0)
    }
  }

  return (
    <Box>
      <Box display="flex">
        <Box width="50%">
          <Typography variant="h4">Total cost</Typography>
          <Box height="400px">
            <TotalCostGraph
              xAxisData={scenarioNames}
              totalCost={totalCostData}
            />
          </Box>
        </Box>
        <Box width="50%">
          <Typography variant="h4">Inventory duration(BAU)</Typography>
          <Box height="400px">
            <InventoryDurationGraph
              xAxisData={scenarioNames}
              bauDays={bauDays}
            />
          </Box>
        </Box>
      </Box>

      <Box minHeight="100px" marginTop="20px">
        <CompareScenariosTable
          data={compareScenariosData}
        />
      </Box>
    </Box>
  )
}
