import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import { Button, DropDown, Input, Modal } from 'supplyvue-ui'
import {
  BatchSettingsFormProps,
  BatchSettingsParametersType,
  BatchSettingsParametersKeysType,
} from '../BatchSettingsForm/BatchSettingsForm.types'
import { useStyles } from './BatchSettingsBulkEdit.styles'

export const INITIAL_STATE: BatchSettingsParametersType = {
  batchGroupingCode: '',
  minimumBatchSize: '',
  minimumBatchSizeIncrement: '',
  unitOfMeasure: '',
  unitOfMeasureId: '',
  replenishmentType: '',
  replenishmentTypeId: '',
  salesRate: '',
  minimumCycle: '',
  maximumCycle: '',
  runRate: '',
  minimumBatchSizeActionType: '0',
  minimumBatchSizeIncrementActionType: '0',
  runRateActionType: '0',
}

const excludedProperties = {
  minimumBatchSizeActionType: true,
  minimumBatchSizeIncrementActionType: true,
  runRateActionType: true,
}
const actionOptions = [
  { key: 'Set value to', value: '0' },
  { key: 'Increase by percentage', value: '1' },
  { key: 'Decrease by percentage', value: '2' },
]

export const BatchSettingsBulkEditView = ({
  isModalOpen,
  handleSaveClick,
  handleModalClose,
  minimumCycleTypes,
  maximumCycleTypes,
  replenishmentTypes,
  isUpdating,
  hideRunRate,
  data,
  setData,
}: BatchSettingsFormProps) => {
  const classes = useStyles()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== 'batchGroupingCode') {
      const VALID_NUMBER_PATTERN =
        /^[+]?((\.\d{0,5})|(\d+(\.\d{0,5})?)|(\d+\.))$/
      if (event.target.value === '' || value.match(VALID_NUMBER_PATTERN)) {
        setData({
          ...data,
          [name]: value,
        })
      }
    } else {
      setData({
        ...data,
        [name]: value,
      })
    }
  }

  const handleDropdownChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof data
    if (name === 'replenishmentTypeId') {
      const replenishmentType = replenishmentTypes.filter(
        (type) => type.value === event.target.value
      )
      setData({
        ...data,
        replenishmentType:
          replenishmentType.length > 0 ? replenishmentType[0].key : '',
        [name]: event.target.value as string,
      })
    } else {
      setData({
        ...data,
        [name]: event.target.value as string,
      })
    }
  }

  const handleSaveButtonClick = () => {
    handleSaveClick(data)
  }

  const handleClearButtonClick = () => {
    setData(INITIAL_STATE)
  }

  const isDataUpdated = () => {
    for (const key in data) {
      if (
        !excludedProperties[key as keyof typeof excludedProperties] &&
        data[key as BatchSettingsParametersKeysType] !== ''
      ) {
        return true
      }
    }
    return false
  }

  return (
    <Box>
      <Box>
        <Modal
          disableBackdropClick
          open={isModalOpen}
          handleClose={handleModalClose}
          header={'Edit Selection'}
          body={
            <Box mt={2} mb={2} display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Metric</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Action</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Value</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Divider flexItem className={classes.divider} />
              </Box>
              {/* minimum batch */}
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Minimum batch size</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectMinimumBatchSizeAction"
                      name="minimumBatchSizeActionType"
                      menuItems={actionOptions}
                      value={data.minimumBatchSizeActionType}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="minimum batch size"
                      value={data.minimumBatchSize}
                      onChange={handleInputChange}
                      name="minimumBatchSize"
                    />
                  </Box>
                </Box>
              </Box>

              {/* minimum batch increment */}
              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">
                      Minimum batch increment
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="SelectMinimumBatchIncrementAction"
                      name="minimumBatchSizeIncrementActionType"
                      menuItems={actionOptions}
                      value={data.minimumBatchSizeIncrementActionType}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="minimum batch increment"
                      value={data.minimumBatchSizeIncrement}
                      onChange={handleInputChange}
                      name="minimumBatchSizeIncrement"
                    />
                  </Box>
                </Box>
              </Box>

              {/* minimum cycle */}

              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Minimum cycle</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Minimum cycle"
                      name="minimumCycle"
                      defaultLabel="None"
                      menuItems={minimumCycleTypes}
                      value={data.minimumCycle as string}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
              </Box>

              {/* maximum cycle */}

              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Maximum cycle</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Maximum cycle"
                      name="maximumCycle"
                      defaultLabel="None"
                      menuItems={maximumCycleTypes}
                      value={data.maximumCycle as string}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
              </Box>

              {/* Replenishment type */}

              <Box
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Typography variant="h4">Replenishment type</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box width="15rem">
                    <Input
                      fullWidth={true}
                      ariaLabel="Set value to"
                      value="Set value to"
                      name="setValueTo"
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" ml={3}>
                  <Box width="15rem">
                    <DropDown
                      fullWidth={true}
                      label="Replenishment type"
                      name="replenishmentTypeId"
                      defaultLabel="None"
                      menuItems={replenishmentTypes}
                      value={data.replenishmentTypeId}
                      onChange={handleDropdownChange}
                    />
                  </Box>
                </Box>
              </Box>
              {/* Run rate */}

              {!hideRunRate && (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="column">
                    <Box width="15rem">
                      <Typography variant="h4">Run rate</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box width="15rem">
                      <DropDown
                        fullWidth={true}
                        label="RunRateAction"
                        name="runRateActionType"
                        menuItems={actionOptions}
                        value={data.runRateActionType}
                        onChange={handleDropdownChange}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" ml={3}>
                    <Box width="15rem">
                      <Input
                        fullWidth={true}
                        ariaLabel="run rate"
                        value={data.runRate}
                        onChange={handleInputChange}
                        name="runRate"
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              <Box display="flex" alignSelf="flex-end" mt={4}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleClearButtonClick}
                  disabled={isEqual(INITIAL_STATE, data)}
                >
                  Clear
                </Button>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSaveButtonClick}
                  disabled={isUpdating || !isDataUpdated()}
                >
                  {isUpdating && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      className={classes.progress}
                    />
                  )}
                  Apply changes
                </Button>
              </Box>
            </Box>
          }
          className={classes.modal}
        />
      </Box>
    </Box>
  )
}
