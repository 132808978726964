import { useMemo, Dispatch, SetStateAction } from 'react'
import {
  PlanDetailsColumns,
  RunRateTableRow,
} from 'Features/PlanDetails/PlanDetails.types'
import { searchTextOperator } from 'Common/MUITable/SearchText.operator'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { Box, IconButton, Slider, TableContainer } from '@mui/material'
import { useStyles } from './PlanDetails.styles'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import clsx from 'clsx'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { groupBy, round } from 'lodash'

type RunRateTableProps = {
  data: RunRateTableRow[]
  updateData: Dispatch<SetStateAction<RunRateTableRow[]>>
  currentPlanDetails: PlanDetailsColumns[]
  setPlanDetails: Dispatch<SetStateAction<PlanDetailsColumns[]>>
}

export const RunRateTable = ({
  data,
  updateData,
  currentPlanDetails,
  setPlanDetails,
}: RunRateTableProps) => {
  const classes = useStyles()

  const skuCodes = [
    ...new Set(
      data.map((item: RunRateTableRow) => {
        return item.productCode
      })
    ),
  ]

  const batchCodes = [
    ...new Set(
      data.map((item: RunRateTableRow) => {
        return item.batchGroupCode
      })
    ),
  ]

  const repl_types = [
    ...new Set(
      data.map((item: RunRateTableRow) => {
        return item.replenishmentType
      })
    ),
  ]

  const renderZero = (value: number) => {
    if (value === 0) {
      return <div></div>
    } else {
      return <div>{value}</div>
    }
  }

  const updatePlanDetails = (runRateRows: RunRateTableRow[]) => {
    const newPlanDetails = runRateRows.flatMap((row) => {
      const { productCode, cycle, startWeek } = row

      const existingDetails = currentPlanDetails.filter(
        (detail) => detail.productCode === productCode
      )

      const updatedOccurrences: PlanDetailsColumns[] = []

      let idx = 0

      for (let i = startWeek; i <= 8; i += cycle) {
        const existingDetail = existingDetails.find(
          (detail) => detail.week === i
        )

        updatedOccurrences.push(
          existingDetail
            ? {
                ...existingDetail,
                week: i,
                cycle: cycle,
                startWeek: startWeek,
                id: idx,
              }
            : {
                ...existingDetails[0],
                week: i,
                cycle: cycle,
                startWeek: startWeek,
                id: idx,
              }
        )
        idx += 1
      }

      return updatedOccurrences
    })

    const groupedByWeek = groupBy(newPlanDetails, (detail) => detail.week)

    const updatedPlanDetails = Object.entries(groupedByWeek).flatMap(
      ([week, details]) => {
        const sortedDetails = details.sort((a, b) =>
          a.batchGroupCode.localeCompare(b.batchGroupCode)
        )

        return sortedDetails.map((detail, index) => ({
          ...detail,
          sequence: index + 1,
        }))
      }
    )

    const sortedPlanDetails = updatedPlanDetails.sort((a, b) => {
      if (a.week !== b.week) {
        return a.week - b.week
      }
      return a.sequence - b.sequence
    })

    setPlanDetails(sortedPlanDetails)
  }

  const handleUp = (row: RunRateTableRow) => {
    const { startWeek, cycle } = row

    const newStartWeek = startWeek + 1

    const weekKeys = [
      'week_1',
      'week_2',
      'week_3',
      'week_4',
      'week_5',
      'week_6',
      'week_7',
      'week_8',
    ] as const

    const totalSum = weekKeys.reduce((sum, key) => sum + row[key], 0)

    const newWeeks = Object.fromEntries(
      weekKeys.map((key) => [key, 0])
    ) as Record<keyof RunRateTableRow, number>

    for (let i = newStartWeek - 1; i < 8; i += cycle) {
      const weekIndex = Math.floor(i)
      const key = weekKeys[weekIndex]
      newWeeks[key] = round((totalSum * cycle) / 8, 2)
    }

    for (let i = startWeek - 1; i < 8; i += cycle) {
      const weekIndex = Math.floor(i)
      const key = weekKeys[weekIndex]
      newWeeks[key] = 0
    }

    const updatedRow = {
      ...row,
      ...newWeeks,
      startWeek: newStartWeek,
    }

    const newRows = data.map((r) => (r.id === row.id ? updatedRow : r))

    updateData(newRows)
    updatePlanDetails(newRows)
  }

  const handleDown = (row: RunRateTableRow) => {
    const { startWeek, cycle } = row

    const newStartWeek = startWeek - 1

    const weekKeys = [
      'week_1',
      'week_2',
      'week_3',
      'week_4',
      'week_5',
      'week_6',
      'week_7',
      'week_8',
    ] as const

    const totalSum = weekKeys.reduce((sum, key) => sum + row[key], 0)

    const newWeeks = Object.fromEntries(
      weekKeys.map((key) => [key, 0])
    ) as Record<keyof RunRateTableRow, number>

    for (let i = newStartWeek - 1; i < 8; i += cycle) {
      const weekIndex = Math.floor(i)
      const key = weekKeys[weekIndex]
      newWeeks[key] = round((totalSum * cycle) / 8, 2)
    }

    for (let i = startWeek - 1; i < 8; i += cycle) {
      const weekIndex = Math.floor(i)
      const key = weekKeys[weekIndex]
      newWeeks[key] = 0
    }

    const updatedRow = {
      ...row,
      ...newWeeks,
      startWeek: newStartWeek,
    }

    const newRows = data.map((r) => (r.id === row.id ? updatedRow : r))

    updateData(newRows)
    updatePlanDetails(newRows)
  }

  const renderMoveButtons = (params: GridCellParams) => {
    const row: RunRateTableRow = params.row
    const { startWeek, cycle } = row

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <IconButton
            disabled={startWeek >= cycle}
            onClick={() => handleUp(row)}
          >
            <KeyboardArrowUp />
          </IconButton>
          <IconButton disabled={startWeek >= 8} onClick={() => handleDown(row)}>
            <KeyboardArrowDown />
          </IconButton>
        </Box>
        {startWeek}
      </Box>
    )
  }

  const renderCycleCell = (params: GridCellParams) => {
    const row: RunRateTableRow = params.row

    const handleCycleChange = (value: number | number[]) => {
      const newCycle = Array.isArray(value) ? value[0] : value

      if (newCycle === row.cycle) return

      const weekKeys = [
        'week_1',
        'week_2',
        'week_3',
        'week_4',
        'week_5',
        'week_6',
        'week_7',
        'week_8',
      ] as const

      const totalSum = weekKeys.reduce((sum, key) => sum + row[key], 0)

      const newWeeks = Object.fromEntries(
        weekKeys.map((key) => [key, 0])
      ) as Record<keyof RunRateTableRow, number>

      for (let i = 0; i < 8; i += newCycle) {
        const weekIndex = Math.floor(i)
        const key = weekKeys[weekIndex]
        newWeeks[key] = round((totalSum * newCycle) / 8, 2)
      }

      const updatedRow = {
        ...row,
        ...newWeeks,
        cycle: newCycle,
        startWeek: 1,
      }

      const newRows = data.map((r) => (r.id === row.id ? updatedRow : r))

      updateData(newRows)
      updatePlanDetails(newRows)
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '5px',
        }}
      >
        <Slider
          value={Math.log2(row.cycle)}
          min={0}
          max={3}
          track={false}
          step={1}
          marks={[
            { value: 0, label: '' },
            { value: 1, label: '' },
            { value: 2, label: '' },
            { value: 3, label: '' },
          ]}
          valueLabelDisplay="off"
          onChangeCommitted={(_, value) => {
            const newCycle = 2 ** (value as number)
            handleCycleChange(newCycle)
          }}
          sx={{
            '& .MuiSlider-mark': {
              height: 6,
              width: 2,
              borderRadius: 0,
              backgroundColor: 'secondary',
            },
            '& .MuiSlider-thumb': {
              height: 15,
              width: 5,
              borderRadius: 0,
              backgroundColor: '#007bff',
            },
          }}
        />
      </Box>
    )
  }

  const MUIColumns: GridColDef[] = useMemo(() => {
    if (!data) {
      return []
    }

    const baseColumns = [
      {
        field: 'workcentreCode',
        headerName: 'Workcentre',
        type: 'string',
        width: 200,
      },
      {
        field: 'productCode',
        headerName: 'SKU group code',
        sortable: true,
        filterable: true,
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: skuCodes,
              label: 'Sku codes',
            },
          },
        ],
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        renderCell: (params: any) => {
          const { description } = params.row
          if (description === 'nan') {
            return <div></div>
          } else {
            return <div>{description}</div>
          }
        },
        type: 'string',
        flex: 2,
      },
      {
        field: 'batchGroupCode',
        headerName: 'Batch group code',
        type: 'string',
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: batchCodes,
              label: 'search',
            },
          },
        ],
      },
      {
        field: 'replenishmentType',
        headerName: 'Replenishment Type',
        cellClassName: (params: GridCellParams) => {
          return clsx('table', {
            foq: params.row.replenishmentType === 'FOQ',
          })
        },
        filterOperators: [
          {
            ...searchTextOperator[0],
            InputComponentProps: {
              data: repl_types,
              label: 'search',
            },
          },
        ],
        type: 'string',
        width: 175,
      },
      {
        field: 'minimumOrderQuantity',
        headerName: 'MOQ',
        type: 'number',
        flex: 1,
      },
      {
        field: 'minimumOrderIncrement',
        headerName: 'MOI',
        type: 'number',
        flex: 1,
      },
      {
        field: 'cycle',
        headerName: 'Cycle',
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow') {
            return <div></div>
          } else {
            return renderCycleCell(params)
          }
        },

        type: 'number',
        width: 170,
      },
      {
        field: 'startWeek',
        headerName: 'Start Week',
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow') {
            return <div></div>
          } else {
            return renderMoveButtons(params)
          }
        },
        type: 'number',
        width: 140,
      },
    ]

    const weekColumns = [...Array(8)].map((_, index) => {
      const weekNumber = index + 1
      return {
        field: `week_${weekNumber}`,
        headerName: `Week ${weekNumber}`,
        cellClassName: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow') {
            return classes.aggregatedCell
          }
          return clsx('table', {
            zero: params.value !== 0,
          })
        },
        renderCell: (params: GridCellParams) => {
          if (params.rowNode.type === 'pinnedRow') {
            const value = params.value as number
            return (
              <strong style={{ color: '#007bff' }}>{value.toFixed(2)}</strong>
            )
          }
          return renderZero(params.value as number)
        },
        type: 'number',
      }
    })

    return [...baseColumns, ...weekColumns]
  }, [data])

  return (
    <TableContainer className={classes.runTimeTable}>
      <MUITable
        rows={data}
        columns={MUIColumns}
        rowHeight={40}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
          aggregation: {
            model: {
              week_1: 'sum',
              week_2: 'sum',
              week_3: 'sum',
              week_4: 'sum',
              week_5: 'sum',
              week_6: 'sum',
              week_7: 'sum',
              week_8: 'sum',
            },
          },
        }}
        pagination
        pageSizeOptions={[20, 30, 50]}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            overflowY: 'hidden',
          },
        }}
      />
    </TableContainer>
  )
}
