import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  select: {
    marginLeft: '20px',
    backgroundColor: 'white',
    width: '200px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '16px',
    marginLeft: '30px',
  },
  downloadButton: {
    backgroundColor: theme.palette.common.white,
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
  },
  tableWrapper: {
    width: '70%',
    paddingRight: '20px',
  },
  graphWrapper: {
    width: '30%',
  },
  iconButtonDisabled: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.common.white,
  },
  sequenceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  '@keyframes flash': {
    '0%': { backgroundColor: theme.palette.secondary.main },
    '100%': { backgroundColor: 'white' },
  },
  flash: {
    animation: '$flash 1.5s',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '3px',
  },
}))
