import { useMemo, useState, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { DropDown } from 'supplyvue-ui'
import {
  Box,
  CircularProgress,
  Tooltip,
  Popover,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import {
  PlanTemplateSummaryTableType,
  PlanTemplateSummaryTableProps,
  PlanTemplateSummaryParams,
} from './PlanTemplateSummary.types'
import { useStyles } from './PlanTemplateSummary.styles'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { MoreHorizOutlined } from '@material-ui/icons'

import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

export const PlanTemplateSummaryTable = ({
  data,
  setSelectedRows,
  handleDropdownChange,
  isUpdating,
}: PlanTemplateSummaryTableProps) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    projectId,
    scenarioId,
    snapshotId,
    planTemplateId,
  } = useParams<PlanTemplateSummaryParams>()

  const prefferedMethodTypes = [
    { key: 'Constrained', value: 'constrained' },
    { key: 'Unconstrained', value: 'unconstrained' },
    { key: 'Cost Optimized', value: 'costOptimized' },
    { key: 'Campaign', value: 'carbonCost' },
  ]

  const tableData = data.map((item: PlanTemplateSummaryTableType, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const handleGenerateTemplate = (
    sourceSiteKey: string,
    workcentreId: string
  ) => {
    history.push(
      `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteKey}/${workcentreId}`
    )
  }

  const getTemplateGeneratedIcon = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return (
          <Tooltip title="Completed">
            <CheckCircleIcon color="primary" />
          </Tooltip>
        )
      case 'UNSTARTED':
        return <RadioButtonUncheckedIcon color="disabled" />
      case 'INITIATED':
        return (
          <Tooltip title="Processing">
            <CircularProgress color="primary" size={20} />
          </Tooltip>
        )
      case 'PROCESSING':
        return (
          <Tooltip title="Processing">
            <CircularProgress color="primary" size={20} />
          </Tooltip>
        )
      case 'FAILED':
        return (
          <Tooltip title="Failed">
            <CancelOutlinedIcon color="error" />
          </Tooltip>
        )
      default:
        return <RadioButtonUncheckedIcon color="disabled" />
    }
  }

  const MUIColumns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'sourceSiteLabel',
        headerName: 'Source site',
        minWidth: 75,
        type: 'string',
        flex: 4,
      },
      {
        field: 'workcentreLabel',
        headerName: 'Workcentre',
        minWidth: 75,
        type: 'string',
        flex: 4,
      },
      {
        field: 'workcentreCapacity',
        headerName: 'Capacity hours',
        type: 'number',
        flex: 2,
        editable: true,
      },
      {
        field: 'productionCostPerHour',
        headerName: 'Production cost per hour',
        type: 'number',
        flex: 2,
        editable: true,
      },
      {
        field: 'crewCostPerHour',
        headerName: 'Crew cost per hour',
        type: 'number',
        flex: 2,
        editable: true,
      },
      {
        field: 'productsCount',
        headerName: 'Number of SKU groups',
        type: 'number',
        flex: 2,
      },
      {
        field: 'isEdited',
        headerName: 'Inputs edited',
        type: 'boolean',
        renderCell: (params: any) => {
          const rowData = params.row
          if (rowData.isEdited) {
            return <CheckCircleIcon color="primary" />
          } else {
            return <RadioButtonUncheckedIcon color="disabled" />
          }
        },
        flex: 1,
      },
      {
        field: 'generatedFlag',
        headerName: 'Template generated',
        type: 'boolean',
        renderCell: (params: any) => {
          const rowData = params.row
          return getTemplateGeneratedIcon(rowData.generatedFlag)
        },
        flex: 1,
      },
      {
        field: 'preferredMethod',
        headerName: 'Preferred Plan Template',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        align: 'center',
        minWidth: 200,
        renderCell: (params: any) => {
          const rowData = params.row
          return (
            // @ts-ignore
            <Box
              className={classes.dropDown}
              onClick={(e) => e.stopPropagation()}
            >
              <DropDown
                menuItems={prefferedMethodTypes}
                label={''}
                fullWidth={true}
                value={rowData.preferredMethod ?? 'constrained'}
                variant="outlined"
                onChange={(e) =>
                  handleDropdownChange(e.target.value, rowData.id)
                }
              />
            </Box>
          )
        },
        flex: 2,
      },
      {
        field: 'sourceSiteKey',
        headerName: 'Actions',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params: any) => <ActionsMenu row={params.row} />,
      },
    ]
  }, [data])

  const ActionsMenu = (row: any) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleMenuItemClick = (menuItem: string, row) => {
      // Handle menu item click here
      handleGenerateTemplate(row.row.sourceSiteKey, row.row.workcentreKey)
      handleClose()
    }

    const open = Boolean(anchorEl)

    return (
      <Fragment>
        <IconButton onClick={handleClick} className={classes.actions}>
          <MoreHorizOutlined />
        </IconButton>
        <Popover
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick('Edit', row)}>
            Edit Template
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('Delete', row)}>
            View Template
          </MenuItem>
        </Popover>
      </Fragment>
    )
  }

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.table}>
        <MUITable
          rows={tableData}
          checkboxSelection
          disableRowSelectionOnClick
          columns={MUIColumns}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          experimentalFeatures={{ lazyLoading: true }}
          hideFooter={true}
          loading={isUpdating}
        />
      </Box>
    </Box>
  )
}
