import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  divider: {
    height: '3px',
    background: '#006286',
    width: '100%',
    marginTop: '1rem',
  },
  select: {
    minWidth: '150px',
  },
  contentWrapper: {
    minWidth: '600px',
    maxWidth: '900px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    height: '40px',
  },
}))