import { Box, Typography, CircularProgress } from '@material-ui/core'
import { Input, Button, Modal } from 'supplyvue-ui'
import { useStyles } from './CreateScenarioModal.styles'
import { CreateScenarioModalProps } from './CreateScenarioModal.types'

export const CreateScenarioModal = ({
  isModalOpen,
  handleModalClose,
  heading,
  scenarioNameLabel,
  scenarioName,
  handleScenarioNameChange,
  actionButtonLabel,
  handleActionClick,
  isActionDisabled,
  isLoading,
  errorMessage = '',
}: CreateScenarioModalProps) => {
  const classes = useStyles()

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleActionClick()
    }
  }

  const Body = (
    <Box className={classes.contentWrapper}>
      <label>
        <Typography className={classes.label} variant="h4">
          {scenarioNameLabel}
        </Typography>
        <Input
          error={!!errorMessage}
          helperText={errorMessage}
          fullWidth
          value={scenarioName}
          onChange={handleScenarioNameChange}
          onKeyDown={handleOnKeyDown}
          disabled={isLoading}
          ariaLabel={scenarioNameLabel}
        />
      </label>
    </Box>
  )

  const Footer = (
    <Box className={classes.footer}>
      <Button
        onClick={handleActionClick}
        className={classes.modalActionButton}
        label={actionButtonLabel}
        disabled={isActionDisabled}
        variant="contained"
      >
        {isLoading && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        {actionButtonLabel}
      </Button>
    </Box>
  )

  return (
    <Modal
      aria-labelledby={heading}
      header={heading}
      open={isModalOpen}
      handleClose={handleModalClose}
      body={Body}
      footer={Footer}
    />
  )
}
