import { CircularProgress, Paper } from '@material-ui/core'
import { Button } from 'supplyvue-ui'

import { useStyles } from './StepFooter.styles'
import { StepFooterProps } from './StepFooter.types'

export const StepFooter = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  actionButtonLabel,
  disablePrimaryButton,
  disableSecondaryButton,
  disableActionButton,
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  handleActionButtonClick,
  isPrimaryButtonBusy = false,
  isActionButtonBusy = false,
}: StepFooterProps) => {
  const classes = useStyles()
  return (
    <Paper className={classes.actionContainer} square>
      {handleSecondaryButtonClick && secondaryButtonLabel && (
        <Button
          className={classes.secondaryButton}
          onClick={handleSecondaryButtonClick}
          variant="outlined"
          disabled={disableSecondaryButton}
        >
          {secondaryButtonLabel}
        </Button>
      )}
      <Button
        variant={actionButtonLabel ? 'outlined' : 'contained'}
        onClick={handlePrimaryButtonClick}
        disabled={disablePrimaryButton || isPrimaryButtonBusy}
      >
        {isPrimaryButtonBusy && (
          <CircularProgress
            color="inherit"
            size={16}
            className={classes.progress}
          />
        )}
        {primaryButtonLabel}
      </Button>
      {actionButtonLabel && (
        <Button
          className={classes.actionButton}
          variant="contained"
          onClick={handleActionButtonClick}
          disabled={disableActionButton || isActionButtonBusy}
        >
          {isActionButtonBusy && (
            <CircularProgress
              color="inherit"
              size={16}
              className={classes.progress}
            />
          )}
          {actionButtonLabel}
        </Button>
      )}
    </Paper>
  )
}
