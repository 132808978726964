import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request } from 'Features/Auth/axios-client'
import { mapSummaryObjectToArray } from './PlanTemplateGeneration.utils'
import {
  CalculatePlanTemplateVariables,
  EnqueueErrorResponse,
  GetPreferredMethodParams,
} from './PlanTemplateGeneration.types'

export const fetchSummaryData = async (
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string
) => {
  const { data } = await request({
    url: `plan-templates/${planTemplateId}/weekly-summary?source_site_key=${sourceSiteId}&work_centre_key=${workcentreId}&optimization=${optimization}`,
  })
  return data
}

export const useSummaryData = (
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string
) => {
  return useQuery(
    ['summary-data', planTemplateId, sourceSiteId, workcentreId, optimization],
    () =>
      fetchSummaryData(
        planTemplateId,
        sourceSiteId,
        workcentreId,
        optimization
      ),
    {
      select: (data) => {
        if (Array.isArray(data.data) && data.data.length === 0) return data.data
        const summary = mapSummaryObjectToArray(data.data.summary)
        return { ...data.data, summary }
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
}

export const fetchMergeTestData = async (
  projectId: string,
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string,
  lineOnly: boolean
) => {
  const { data } = await request({
    url: `/projects/${projectId}/plan-templates/${planTemplateId}/plan-template-summary?source_site_key=${sourceSiteId}&work_centre_key=${workcentreId}&optimization=${optimization}&line_only=${lineOnly}`,
  })
  return data?.data
}

export const useMergeTestData = (
  projectId: string,
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string,
  lineOnly: boolean,
  onSuccess: (
    isGenerating: boolean,
    capacity: number,
    bufferValue: number,
    optimization: string
  ) => void,
  onError: () => void
) => {
  const [shouldRefetch, setShouldRefetch] = useState(true)

  return useQuery(
    [
      'merge-test',
      projectId,
      planTemplateId,
      sourceSiteId,
      workcentreId,
      optimization,
      lineOnly,
    ],
    () =>
      fetchMergeTestData(
        projectId,
        planTemplateId,
        sourceSiteId,
        workcentreId,
        optimization,
        lineOnly
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 100000 : false,
      onSuccess: async (response: any) => {
        console.log('Merge test response')
        console.log(response)

        if (
          ['UNSTARTED', 'COMPLETED', 'FAILED'].includes(
            response.templateGenerationStatus
          )
        ) {
          setShouldRefetch(false)
          onSuccess(
            false,
            response?.workcentreCapacity,
            response?.buffer ?? 0,
            response.optimization
          )
        } else {
          onSuccess(true, 0, 0, optimization)
          setShouldRefetch(true)
        }
      },
      onError: () => {
        setShouldRefetch(false)
        onError()
      },
    }
  )
}

export const fetchCycleSpeedGraphData = async (
  projectId: string,
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string,
  lineOnly: boolean
) => {
  const { data } = await request({
    url: `/projects/${projectId}/plan-templates/${planTemplateId}/cycles?source_site_key=${sourceSiteId}&work_centre_key=${workcentreId}&optimization=${optimization}&line_only=${lineOnly}`,
  })
  return data
}

export const useCycleSpeedGraphData = (
  projectId: string,
  planTemplateId: string,
  sourceSiteId: number,
  workcentreId: number,
  optimization: string,
  lineOnly: boolean,
  onSuccess: (
    isGenerating: boolean,
    capacity: number,
    bufferValue: number,
    optimization: string
  ) => void,
  onError: () => void
) => {
  const queryClient = useQueryClient()
  const [shouldRefetch, setShouldRefetch] = useState(true)

  return useQuery(
    [
      'cycle-speed-graph',
      projectId,
      planTemplateId,
      sourceSiteId,
      workcentreId,
      optimization,
      lineOnly,
    ],
    () =>
      fetchCycleSpeedGraphData(
        projectId,
        planTemplateId,
        sourceSiteId,
        workcentreId,
        optimization,
        lineOnly
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchInterval: shouldRefetch ? 1000 : false,
      onSuccess: async (response) => {
        if (response.templateGenerationStatus === 'COMPLETED') {
          queryClient.invalidateQueries('summary-data')
        }
        if (
          ['UNSTARTED', 'COMPLETED', 'FAILED'].includes(
            response.templateGenerationStatus
          )
        ) {
          setShouldRefetch(false)
          onSuccess(
            false,
            response?.workcentreCapacity,
            response?.buffer ?? 0,
            response.optimization
          )
        } else {
          onSuccess(true, 0, 0, response.optimization)
          setShouldRefetch(true)
        }
      },
      onError: () => {
        setShouldRefetch(false)
        onError()
      },
    }
  )
}

const calculatePlanTemplate = async (
  calculatePlanTemplateVariables: CalculatePlanTemplateVariables
) => {
  const { data } = await request({
    url: `/plan-templates/run-templates`,
    method: 'put',
    data: {
      queueType: 'PlanTemplateGenerator',
      data: {
        snapshotId: calculatePlanTemplateVariables.snapshotId,
        projectId: calculatePlanTemplateVariables.projectId,
        planTemplateId: calculatePlanTemplateVariables.planTemplateId,
        sourceSiteKey: calculatePlanTemplateVariables.sourceSiteId,
        workcentreKey: calculatePlanTemplateVariables.workcentreId,
        workcentreCapacity: calculatePlanTemplateVariables.workcentreCapacity,
        buffer: calculatePlanTemplateVariables.buffer,
        optimization: calculatePlanTemplateVariables.optimization,
        templateBasedOn: calculatePlanTemplateVariables.templateBasedOn,
        ...(calculatePlanTemplateVariables.templateBasedOn ===
          'Combined maker and line' && {
          makerBuffer: calculatePlanTemplateVariables.makerBuffer,
          makerWorkcentreCapacity:
            calculatePlanTemplateVariables.makerWorkcentreCapacity,
        }),
      },
    },
  })
  return data
}

export const useCalculatePlanTemplate = (
  onSuccess: () => void,
  onError: (errorResponse: EnqueueErrorResponse) => void
) => {
  const queryClient = useQueryClient()
  return useMutation(calculatePlanTemplate, {
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries('cycle-speed-graph')
    },
    onError: ({ response }) => {
      onError(response.data)
    },
  })
}

const fetchPlanDetailsGraph = async (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string
) => {
  const { data } = await request({
    url: `/plan-templates/${planTemplateId}/details-graph?source_site_key=${sourceSiteKey}&workcentre_key=${workCentreKey}&optimization=${optimization}`,
  })
  return data
}

export const usePlanDetailsGraph = (
  planTemplateId: string,
  sourceSiteKey: string,
  workCentreKey: string,
  optimization: string,
  generationstatus: boolean
) => {
  return useQuery(
    [
      'details-graph',
      planTemplateId,
      sourceSiteKey,
      workCentreKey,
      optimization,
    ],
    () =>
      fetchPlanDetailsGraph(
        planTemplateId,
        sourceSiteKey,
        workCentreKey,
        optimization
      ),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (response) => response.data,
      enabled: generationstatus,
    }
  )
}

const fetchPreferredMethod = async (
  preferredMethodParams: GetPreferredMethodParams
) => {
  const { data } = await request({
    url: `/plan-templates/${preferredMethodParams.planTemplateId}/source-sites/${preferredMethodParams.sourceSiteId}/${preferredMethodParams.workcentreId}/preferred-method`,
    method: 'get',
  })

  return data
}

export const useFetchPreferredMethod = (
  planTemplateId: string,
  sourceSiteId: string,
  workcentreId: string
) => {
  const params: GetPreferredMethodParams = {
    planTemplateId: planTemplateId,
    sourceSiteId: sourceSiteId,
    workcentreId: workcentreId,
  }

  return useQuery(
    ['preferred-method', planTemplateId, sourceSiteId, workcentreId],
    () => fetchPreferredMethod(params),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      select: (data) => data?.data.preferredMethod ?? 'constrained',
    }
  )
}
