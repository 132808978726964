import { Box, Typography } from '@material-ui/core'
import { Input, DropDown, Button } from 'supplyvue-ui'
import { useStyles } from './WorkcenterSettings.styles'
import {
  MIN_AVG_BATCHES_PER_CYCLE,
  SALES_RATE_TYPE,
  PLAN_TEMPLETE_BASED_ON_OPTIONS,
} from './WorkcenterSettings.constants'
import { WorkcenterSettingsProps } from './WorkcenterSettings.types'
import { WORKCENTER_STEPS } from '../StepCard/StepCard.constants'
import { getSnapshotDetailsInString } from 'Features/Project/SnapshotModal/SnapshotModal.utils'
import { useEffect, useState } from 'react'

export const WorkcenterSettingsView = ({
  salesRateTypeLabel,
  salesRatePeriodLabel,
  minBatchesPerCycleLabel,
  salesRateType,
  salesRatePeriod,
  minBatchesPerCycle,
  handleApplyDefaultsClick,
  updateWorkcentreSettingsState,
  setIsPrimaryButtonDisabled,
  snapshotDetails,
  planTemplateBasedOn,
  planTemplateBasedOnLabel,
}: WorkcenterSettingsProps) => {
  const classes = useStyles()
  const [isSalesRatePeriodValid, setIsSalesRatePeriodValid] = useState(true)
  const handleSalesRatePeriodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numberRegex = /^([0-9]){0,8}$/i

    if (event.target.value === '') {
      updateWorkcentreSettingsState({
        step: WORKCENTER_STEPS.SETTINGS,
        key: 'salesRatePeriod',
        value: event.target.value,
      })
      setIsSalesRatePeriodValid(true)
    } else if (numberRegex.test(event.target.value)) {
      updateWorkcentreSettingsState({
        step: WORKCENTER_STEPS.SETTINGS,
        key: 'salesRatePeriod',
        value: parseInt(event.target.value),
      })
    }
  }

  useEffect(() => {
    setIsSalesRatePeriodValid(
      snapshotDetails?.snapshotLength
        ? salesRatePeriod <= snapshotDetails?.snapshotLength
        : true
    )
  }, [salesRatePeriod, snapshotDetails?.snapshotLength])

  useEffect(() => {
    setIsPrimaryButtonDisabled(!isSalesRatePeriodValid)
  }, [isSalesRatePeriodValid, setIsPrimaryButtonDisabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.formWrapper}>
        <Box className={classes.formControl}>
          <label htmlFor={salesRateTypeLabel}>
            <Typography className={classes.label} variant="h4">
              {salesRateTypeLabel}
            </Typography>
          </label>
          <DropDown
            id={salesRateTypeLabel}
            label={salesRateTypeLabel}
            menuItems={SALES_RATE_TYPE}
            value={salesRateType}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'salesRateType',
                value: e.target.value as string,
              })
            }
            className={classes.select}
          />
        </Box>
        <Box className={classes.formControl}>
          <label htmlFor={salesRatePeriodLabel}>
            <Typography className={classes.label} variant="h4">
              {salesRatePeriodLabel}
            </Typography>
          </label>
          <Input
            id={salesRatePeriodLabel}
            value={salesRatePeriod}
            onChange={handleSalesRatePeriodChange}
            className={classes.input}
            error={!isSalesRatePeriodValid}
            helperText={
              !isSalesRatePeriodValid
                ? 'Sales rate period cannot be greater than the number of weeks in the snapshot'
                : ''
            }
          />
        </Box>
        <Box>
          <label htmlFor={minBatchesPerCycleLabel}>
            <Typography className={classes.label} variant="h4">
              {minBatchesPerCycleLabel}
            </Typography>
          </label>
          <DropDown
            id={minBatchesPerCycleLabel}
            label={minBatchesPerCycleLabel}
            menuItems={MIN_AVG_BATCHES_PER_CYCLE}
            value={minBatchesPerCycle}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'minimumAverageBatchesPerProductionCycle',
                value: e.target.value as number,
              })
            }
            className={classes.select}
          />
        </Box>

        <Box ml={3}>
          <label htmlFor={planTemplateBasedOnLabel}>
            <Typography className={classes.label} variant="h4">
              {planTemplateBasedOnLabel}
            </Typography>
          </label>
          <DropDown
            id={planTemplateBasedOnLabel}
            label={planTemplateBasedOnLabel}
            menuItems={PLAN_TEMPLETE_BASED_ON_OPTIONS}
            value={planTemplateBasedOn}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'templateBasedOn',
                value: e.target.value as string,
              })
            }
            className={classes.select}
          />
        </Box>

        <Box className={classes.button}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleApplyDefaultsClick}
          >
            Apply default settings
          </Button>
        </Box>
      </Box>
      <Box className={classes.snapshotDates}>
        {snapshotDetails && (
          <Typography>{getSnapshotDetailsInString(snapshotDetails)}</Typography>
        )}
      </Box>
    </Box>
  )
}
