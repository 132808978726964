import { useReducer } from 'react'
import _ from 'lodash'
import { WorkcentreSettingsResponse } from 'Features/PlanTemplate/TemplateSettings/TemplateSettings.types'
import {
  WorkcentreSettingsState,
  WorkcentreSettingStateAction,
} from './StepCard.types'
import { getSettingsInitialState } from './StepCard.utils'

export const getInitialSettings = (
  workcentreSettingsData: WorkcentreSettingsResponse
) => {
  const {
    productChangeOvers,
    productSettings,
    batchGroupingSettings,
    batchGroupingChangeOvers,
    snapshotDetails,
    productChangeOverTime,
    productChangeOverCost,
    batchGroupingChangeOverTime,
    batchGroupingChangeOverCost,
  } = workcentreSettingsData

  return {
    settings: getSettingsInitialState(workcentreSettingsData),
    productChangeOvers,
    productSettings,
    batchGroupingSettings,
    batchGroupingChangeOvers,
    snapshotDetails,
    productChangeOverTime,
    productChangeOverCost,
    batchGroupingChangeOverTime,
    batchGroupingChangeOverCost,
  }
}

const reducer = (
  state: WorkcentreSettingsState,
  action: WorkcentreSettingStateAction
) => {
  const stateCopy = _.cloneDeep(state)
  if (action.type === 'RESET') {
    const initialState = getInitialSettings(
      action.value as WorkcentreSettingsResponse
    )
    return initialState
  }
  const updateParameterName = action.key
    ? [action.step, action.key]
    : action.step
  _.set(stateCopy, updateParameterName, action.value)
  return stateCopy
}

export const useWorkcentreSettingsState = (
  initialSettings: WorkcentreSettingsResponse
) => {
  return useReducer(reducer, initialSettings, getInitialSettings)
}
