import { useMemo, useState, Fragment } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { DropDown } from 'supplyvue-ui'
import {
  Box,
  CircularProgress,
  Tooltip,
  Popover,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import {
  PlanTemplateSummaryTableType,
  PlanTemplateSummaryTableProps,
  PlanTemplateSummaryParams,
} from './PlanTemplateSummary.types'
import { useStyles } from './PlanTemplateSummary.styles'
import { Table as MUITable } from 'Common/MUITable/MUITable'
import { GridColDefPremium } from '@mui/x-data-grid-premium/typeOverloads'
import { MoreHorizOutlined } from '@material-ui/icons'

import {
  GridCellParams,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium'

type PreferredMethod = 'constrained' | 'unconstrained' | 'carboncost'
type Status = 'COMPLETED' | 'FAILED' | 'UNSTARTED' | 'RUNNING'

// Define the structure of the `row` data (the actual content inside `row.row`)
type RowData = {
  preferredMethod: PreferredMethod
  templateGenerated: Status
  unconstrainedTemplateGenerated: Status
  carbonCostTemplateGenerated: Status
  sourceSiteKey: string
  workcentreKey: string
}

export const PlanTemplateSummaryTable = ({
  data,
  setSelectedRows,
  handleDropdownChange,
  isUpdating,
}: PlanTemplateSummaryTableProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { projectId, scenarioId, snapshotId, planTemplateId } =
    useParams<PlanTemplateSummaryParams>()

  const preferredMethodTypes = [
    { key: 'Constrained', value: 'constrained' },
    { key: 'Unconstrained', value: 'unconstrained' },
    { key: 'Cost Optimized', value: 'costOptimized' },
    { key: 'Campaign', value: 'carbonCost' },
  ]

  const settingsOptionsItems = [
    {
      key: 'Current Template Parameters',
      value: 'Current Template Parameters',
    },
    { key: 'Nudged Template Parameters', value: 'Nudged Template Parameters' },
    {
      key: 'Flexible Template Parameters',
      value: 'Flexible Template Parameters',
    },
  ]

  const tableData = data.map((item: PlanTemplateSummaryTableType, idx) => {
    return {
      ...item,
      id: idx,
    }
  })

  const handleGenerateTemplate = (
    sourceSiteKey: string,
    workcentreId: string,
    menuOption: string,
    preferredMethod: string
  ) => {
    if (menuOption === 'View') {
      history.push(
        `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteKey}/${workcentreId}/plan-details?optimization=${preferredMethod}`
      )
    } else {
      history.push(
        `/projects/${projectId}/snapshots/${snapshotId}/summary/${planTemplateId}/scenarios/${scenarioId}/${sourceSiteKey}/${workcentreId}`
      )
    }
  }

  const getTemplateGeneratedIcon = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return (
          <Tooltip title="Completed">
            <CheckCircleIcon color="primary" />
          </Tooltip>
        )
      case 'UNSTARTED':
        return <RadioButtonUncheckedIcon color="disabled" />
      case 'INITIATED':
        return (
          <Tooltip title="Processing">
            <CircularProgress color="primary" size={20} />
          </Tooltip>
        )
      case 'PROCESSING':
        return (
          <Tooltip title="Processing">
            <CircularProgress color="primary" size={20} />
          </Tooltip>
        )
      case 'FAILED':
        return (
          <Tooltip title="Failed">
            <CancelOutlinedIcon color="error" />
          </Tooltip>
        )
      default:
        return <RadioButtonUncheckedIcon color="disabled" />
    }
  }

  const MUIColumns: GridColDefPremium[] | any[] = useMemo(() => {
    if (!data) {
      return []
    }

    return [
      {
        field: 'sourceSiteLabel',
        headerName: 'Source site',
        minWidth: 75,
        type: 'string',
        flex: 4,
      },
      {
        field: 'workcentreLabel',
        headerName: 'Workcentre',
        minWidth: 75,
        type: 'string',
        flex: 4,
      },
      {
        field: 'workcentreCapacity',
        headerName: 'Capacity hours',
        type: 'number',
        flex: 2,
        editable: true,
      },
      {
        field: 'productsCount',
        headerName: 'Number of SKU groups',
        type: 'number',
        flex: 2,
      },
      {
        field: 'isEdited',
        headerName: 'Inputs edited',
        type: 'boolean',
        renderCell: (params: any) => {
          const rowData = params.row
          if (rowData.isEdited) {
            return <CheckCircleIcon color="primary" />
          } else {
            return <RadioButtonUncheckedIcon color="disabled" />
          }
        },
        flex: 1,
      },
      {
        field: 'generatedFlag',
        headerName: 'Template generated',
        type: 'boolean',
        renderCell: (params: any) => {
          const rowData = params.row
          return getTemplateGeneratedIcon(rowData.generatedFlag)
        },
        flex: 1,
      },
      {
        field: 'preferredMethod',
        headerName: 'Preferred Plan Template',
        headerAlign: 'center',
        type: 'string',
        editable: true,
        align: 'center',
        minWidth: 200,
        width: 300,
        renderCell: (params: GridCellParams) => {
          const { id, preferredMethod } = params.row
          return (
            // @ts-ignore
            <Box
              className={classes.dropDown}
              onClick={(e) => e.stopPropagation()}
            >
              <DropDown
                menuItems={preferredMethodTypes}
                label={'preferredMethod'}
                name={'preferredMethod'}
                fullWidth={true}
                value={preferredMethod ?? 'constrained'}
                variant="outlined"
                onChange={(e) => handleDropdownChange(e, id)}
              />
            </Box>
          )
        },
      },
      {
        field: 'settingsOptions',
        headerName: 'Settings Options',
        headerAlign: 'center',
        width: 300,
        renderCell: (params: GridCellParams) => {
          const { settingsOptions, id } = params.row
          return (
            <Box
              className={classes.dropDown}
              onClick={(e) => e.stopPropagation()}
            >
              <DropDown
                menuItems={settingsOptionsItems}
                label={'settingsOptions'}
                name={'settingsOptions'}
                onChange={(e) => handleDropdownChange(e, id)}
                value={settingsOptions ?? 'Flexible Template Parameters'}
                fullWidth={true}
              />
            </Box>
          )
        },
      },
      {
        field: 'none',
        headerName: 'Actions',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params: GridCellParams) => <ActionsMenu params={params} />,
      },
    ]
  }, [data])
  type ActionsMenuProps = {
    params: GridCellParams
  }

  const ActionsMenu = ({ params }: ActionsMenuProps) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const { row } = params

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleMenuItemClick = (menuItem: string, row: RowData) => {
      // Handle menu item click here

      handleGenerateTemplate(
        row.sourceSiteKey,
        row.workcentreKey,
        menuItem,
        row.preferredMethod
      )
      handleClose()
    }

    const { preferredMethod } = row

    const generatedStatus: Record<PreferredMethod, Status> = {
      constrained: row.templateGenerated,
      unconstrained: row.unconstrainedTemplateGenerated,
      carboncost: row.carbonCostTemplateGenerated,
    }

    const generated: boolean =
      generatedStatus[preferredMethod as PreferredMethod] === 'COMPLETED'

    const open = Boolean(anchorEl)

    return (
      <Fragment>
        <IconButton onClick={handleClick} className={classes.actions}>
          <MoreHorizOutlined />
        </IconButton>
        <Popover
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick('Edit', row)}>
            Edit Template
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('Generate', row)}>
            Generate Template
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemClick('View', row)}
            disabled={!generated}
          >
            View Results
          </MenuItem>
        </Popover>
      </Fragment>
    )
  }

  const getSelectedRows = (ids: GridRowSelectionModel) => {
    const selectedIds = new Set(ids)
    setSelectedRows(tableData.filter((row) => selectedIds.has(row.id)))
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.table}>
        <MUITable
          rows={tableData}
          checkboxSelection
          disableRowSelectionOnClick
          columns={MUIColumns}
          onRowSelectionModelChange={(ids) => getSelectedRows(ids)}
          experimentalFeatures={{ lazyLoading: true }}
          hideFooter={true}
          loading={isUpdating}
        />
      </Box>
    </Box>
  )
}
