import { useQuery } from 'react-query'
import { requestV2 } from 'Features/Auth/axios-client'

const fetchViewScenarios = async (projectId: string, scenarioId: string) => {
  const { data } = await requestV2({
    url: `/projects/${projectId}/scenarios/${scenarioId}/workcentres`,
  })
  return data
}

export const useViewScenariosData = (projectId: string, scenarioId: string) => {
  return useQuery(
    ['view-scenarios', projectId, scenarioId],
    () => fetchViewScenarios(projectId, scenarioId),
    {
      select: (data) => data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchStockPolicyDurationData = async (
  projectId: string,
  scenarioId: string,
  workcentreKey: number
) => {
  const { data } = await requestV2({
    url: `projects/${projectId}/scenarios/${scenarioId}/graph-data${
      workcentreKey ? '?workcentre_key=' + workcentreKey : ''
    }`,
  })
  return data
}

export const useStockPolicyDurationData = (
  projectId: string,
  scenarioId: string,
  workcentreKey: number
) => {
  return useQuery(
    ['stock-policy-duration', projectId, scenarioId, workcentreKey],
    () => fetchStockPolicyDurationData(projectId, scenarioId, workcentreKey),
    {
      select: (data) => data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchTotalTableData = async (projectId: string, scenarioId: string) => {
  const data = await requestV2({
    url: `projects/${projectId}/scenarios/${scenarioId}/workcentres`,
  })
  return data
}

export const useTotalTableData = (projectId: string, scenarioId: string) => {
  return useQuery(
    ['total-data', projectId, scenarioId],
    () => fetchTotalTableData(projectId, scenarioId),
    {
      select: (data) => data.data?.totalTableData,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchCompareScenarioData = async (
  projectId: string,
) => {

  const data = await requestV2({
    url: `projects/${projectId}/view-scenarios`,
  })
  return data
}

export const useCompareScenarioData = (
  projectId: string,
) => {
  return useQuery(
    ['view-scenarios', projectId],
    () => fetchCompareScenarioData(projectId),
    {
      select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  )
}

const fetchCompareScenarioGraphData = async (
  projectId: string,
  scenarioId: string,
  comparatorId?: string
) => {
  const comparator = comparatorId
    ? `?comparator_scenario_id=${comparatorId}`
    : ''

  const data = await requestV2({
    url: `projects/${projectId}/scenarios/${scenarioId}/graph-data${comparator}`,
  })
  return data
}

export const useCompareScenarioGraphData = (
  projectId: string,
  scenarioId: string,
  comparatorId?: string
) => {
  return useQuery(
    ['compare-scenarios-graph-data', projectId, scenarioId, comparatorId],
    () => fetchCompareScenarioGraphData(projectId, scenarioId, comparatorId),
    {
      select: (data) => data?.data,
      refetchOnWindowFocus: false,
    }
  )
}
