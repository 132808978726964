import React, { Dispatch, memo, SetStateAction } from 'react'
import {
  CircularCheckboxProps,
  GeneratingStatus,
  PlanTemplateParams,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.types'
import {
  FormData,
  useFormState,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.state'
import { Checkbox } from '@mui/material'
import {
  CheckBox,
  CheckBoxOutlined,
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from '@material-ui/icons'
import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  GenerateParams,
  useGenerateTemplates,
} from 'Features/PlanTemplateGeneration/PlanTemplateGeneration.data'
import { Button, Input } from 'supplyvue-ui'
import { toast } from 'react-hot-toast'
import { useStyles } from './PlanTemplateGeneration.styles'
import { useParams } from 'react-router-dom'

type CreateTemplateParams = {
  setGeneratingStatus: Dispatch<SetStateAction<GeneratingStatus>>
}

export const CreateTemplate = memo(
  ({ setGeneratingStatus }: CreateTemplateParams) => {
    const classes = useStyles()
    const { planTemplateId, workcentreId } = useParams<PlanTemplateParams>()

    const FORM_INITIAL_STATE: FormData = {
      capacity: null,
      buffer: null,
      settingsOption: 0,
      preferredMethod: 0,
      templates: {
        constrained: false,
        unconstrained: false,
        cost: false,
        campaign: false,
      },
    }

    const [formState, updateState] = useFormState(FORM_INITIAL_STATE)

    const onSuccess = () => {
      toast.success('Templates Generated')
    }

    const onError = () => {}

    const { mutate: generateTemplates, isLoading: isGenerating } =
      useGenerateTemplates(onSuccess, onError)
    const handleCapacityChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = Number(event.target.value)

      updateState({
        type: 'SET_CAPACITY',
        payload: value,
      })
    }

    const CircularCheckbox = ({
      checked,
      handleOnChange,
    }: CircularCheckboxProps) => {
      return (
        <Checkbox
          className={classes.checkedColor}
          checked={checked}
          onChange={handleOnChange}
          icon={<RadioButtonUncheckedRounded style={{ color: 'lightgrey' }} />}
          checkedIcon={<RadioButtonCheckedRounded />}
          disableRipple={true}
        />
      )
    }

    const SquareCheckbox = ({
      checked,
      handleOnChange,
    }: CircularCheckboxProps) => {
      return (
        <Checkbox
          className={classes.checkedColor}
          color="primary"
          checked={checked}
          onChange={handleOnChange}
          icon={<CheckBoxOutlined style={{ color: 'lightgrey' }} />}
          checkedIcon={<CheckBox />}
          disableRipple={true}
        />
      )
    }

    const SettingsOption = ({
      option,
      title,
    }: {
      option: number
      title: string
    }) => {
      const handleChange = () => {
        updateState({
          type: 'SET_SETTINGS_OPTION',
          payload: option,
        })
      }

      return (
        <Card
          className={
            formState.settingsOption === option
              ? classes.selectedCard
              : classes.cardContainer
          }
          onClick={handleChange}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: '8px',
              paddingBottom: '8px',
              width: '150px',
              height: '32px',
            }}
          >
            <CircularCheckbox
              checked={formState.settingsOption === option}
              handleOnChange={handleChange}
            />
            <Typography variant="h5" color="primary">
              {title}
            </Typography>
          </div>
        </Card>
      )
    }

    const PreferredOption = ({
      option,
      title,
    }: {
      option: number
      title: string
    }) => {
      const handleChange = () => {
        updateState({
          type: 'SET_PREFERRED_METHOD',
          payload: option,
        })
      }

      return (
        <Card
          className={
            formState.preferredMethod === option
              ? classes.selectedCard
              : classes.cardContainer
          }
          onClick={handleChange}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: '8px',
              paddingBottom: '8px',
              width: '150px',
              height: '32px',
            }}
          >
            <CircularCheckbox
              checked={formState.preferredMethod === option}
              handleOnChange={handleChange}
            />
            <Typography variant="h5" color="primary">
              {title}
            </Typography>
          </div>
        </Card>
      )
    }

    const TemplateOption = ({
      option,
      title,
      isChecked,
    }: {
      option: number
      title: string
      isChecked: boolean
    }) => {
      const handleChange = () => {
        const templateKeys = [
          'constrained',
          'unconstrained',
          'cost',
          'campaign',
        ] as const
        const templateKey = templateKeys[option]

        updateState({
          type: 'SET_TEMPLATE',
          template: templateKey,
          payload: !formState.templates[templateKey],
        })
      }

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '12px',
            width: '175px',
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <SquareCheckbox checked={isChecked} handleOnChange={handleChange} />
        </div>
      )
    }

    const handleGenerate = () => {
      const modeInputs = Object.entries(formState.templates)
        .filter(([, value]) => value)
        .map(([key]) => ({
          workcentreId,
          templateMethod: key,
        }))

      const generateParams: GenerateParams = {
        planTemplateId,
        modeInputs,
      }

      generateTemplates(generateParams)
      setGeneratingStatus((prevStatus) => {
        const updatedStatus = { ...prevStatus }

        Object.entries(formState.templates).forEach(([key, value]) => {
          if (value) {
            updatedStatus[key as keyof GeneratingStatus] = 'GENERATING'
          }
        })

        return updatedStatus
      })
    }

    return (
      <Grid item container spacing={2} style={{ padding: '16px 18px' }}>
        <Grid item xs={12}>
          <Typography variant="h5">Hours</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            item
            container
            xs={6}
            style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}
          >
            <Grid item>
              <Typography variant="body1">Capacity</Typography>
            </Grid>
            <Grid item>
              <Input
                size="small"
                style={{ width: '125px' }}
                value={formState.capacity || ''}
                onChange={handleCapacityChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            style={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}
          >
            <Grid item>
              <Typography variant="body1">Buffer</Typography>
            </Grid>
            <Grid item style={{ width: '125px' }}>
              <Input size="small" />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Settings Options</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '12px',
          }}
        >
          <SettingsOption option={0} title={'Current'} />
          <SettingsOption option={1} title={'Nudged'} />
          <SettingsOption option={2} title={'Flexible'} />
        </Grid>
        <Grid xs={12} item>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Preferred Method</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '12px',
          }}
        >
          <PreferredOption option={0} title={'Constrained'} />
          <PreferredOption option={1} title={'Unconstrained'} />
          <PreferredOption option={2} title={'Cost Optimized'} />
          <PreferredOption option={3} title={'Campaign'} />
        </Grid>
        <Grid xs={12} item>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Templates</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '8px',
          }}
        >
          <TemplateOption
            option={0}
            title={'Constrained'}
            isChecked={formState.templates.constrained}
          />
          <TemplateOption
            option={1}
            title={'Unconstrained'}
            isChecked={formState.templates.unconstrained}
          />
          <TemplateOption
            option={2}
            title={'Cost Optimized'}
            isChecked={formState.templates.cost}
          />
          <TemplateOption
            option={3}
            title={'Campaign'}
            isChecked={formState.templates.campaign}
          />
        </Grid>
        <Grid xs={12} item>
          <Divider />
        </Grid>
        <Grid
          xs={12}
          item
          container
          style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleGenerate}
              disabled={
                !Object.values(formState.templates).some((value) => value) ||
                isGenerating
              }
            >
              {isGenerating && (
                <CircularProgress
                  color="inherit"
                  size={16}
                  style={{ marginRight: '8px' }}
                />
              )}
              Generate
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)
