import { Box, Typography } from '@material-ui/core'
import { Input, DropDown, Button } from 'supplyvue-ui'
import { useStyles } from './WorkcenterSettings.styles'
import {
  MIN_AVG_BATCHES_PER_CYCLE,
  SALES_RATE_TYPE,
  PLAN_TEMPLETE_BASED_ON_OPTIONS,
  SETTINGS_OPTIONS,
} from './WorkcenterSettings.constants'
import { WorkcenterSettingsProps } from './WorkcenterSettings.types'
import { WORKCENTER_STEPS } from '../StepCard/StepCard.constants'
import { getSnapshotDetailsInString } from 'Features/Project/SnapshotModal/SnapshotModal.utils'
import { useEffect, useState } from 'react'
import { CalendarInput } from 'supplyvue-ui'
import { addWeeks, differenceInDays, parse } from 'date-fns'
import { useUpdateWorkcentreSettingsData } from 'Features/PlanTemplate/StepCard/StepCard.data'
import { toast } from 'react-hot-toast'
import { WorkcentreSettingsResponse } from 'Features/PlanTemplate/TemplateSettings/TemplateSettings.types'

export const WorkcenterSettingsView = ({
  salesRateTypeLabel,
  salesRatePeriodLabel,
  minBatchesPerCycleLabel,
  salesRateType,
  salesRatePeriod,
  settingsOption,
  minBatchesPerCycle,
  handleApplyDefaultsClick,
  updateWorkcentreSettingsState,
  setIsPrimaryButtonDisabled,
  snapshotDetails,
  planTemplateBasedOn,
  planTemplateBasedOnLabel,
}: WorkcenterSettingsProps) => {
  const classes = useStyles()
  const [isSalesRatePeriodValid, setIsSalesRatePeriodValid] = useState(true)

  const handleSalesRatePeriodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const numberRegex = /^([0-9]){0,8}$/i

    if (event.target.value === '') {
      updateWorkcentreSettingsState({
        step: WORKCENTER_STEPS.SETTINGS,
        key: 'salesRatePeriod',
        value: event.target.value,
      })
      setIsSalesRatePeriodValid(true)
    } else if (numberRegex.test(event.target.value)) {
      updateWorkcentreSettingsState({
        step: WORKCENTER_STEPS.SETTINGS,
        key: 'salesRatePeriod',
        value: parseInt(event.target.value),
      })
    }
  }

  const onUpdateError = (message: string) => {
    toast.error(message)
  }

  const onUpdateWorkcentreSettingsSuccess = (
    responseData: WorkcentreSettingsResponse,
    message: string
  ) => {
    /** Update state to match data to avoid difference when comparing.*/
    updateWorkcentreSettingsState({
      step: 'settings',
      value: responseData,
      type: 'RESET',
    })
    toast.success(message)
  }

  const {
    isLoading: isUpdatingWorkcentreSettings,
    mutate: updateWorkcentreSettings,
  } = useUpdateWorkcentreSettingsData({
    onSuccess: onUpdateWorkcentreSettingsSuccess,
    onError: onUpdateError,
  })

  const endDate = parse(
    snapshotDetails.snapshotEndDate,
    'd MMMM yyyy',
    new Date()
  )
  const startDate = addWeeks(endDate, -snapshotDetails.snapshotLength)

  const [selectedStartDate, setSelectedStartDate] = useState(startDate)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)

  const [weeksDifference, setWeeksDifference] = useState(
    Math.floor(differenceInDays(endDate, startDate) / 7)
  )

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      const diffInDays = differenceInDays(selectedEndDate, selectedStartDate)
      const diffInWeeks = Math.floor(diffInDays / 7) // Divide by 7 and round down
      setWeeksDifference(diffInWeeks)

      updateWorkcentreSettingsState({
        step: WORKCENTER_STEPS.SETTINGS,
        key: 'salesRatePeriod',
        value: diffInWeeks,
      })
    }
  }, [selectedStartDate, selectedEndDate])

  const shouldDisableEndDate = (date: Date) => {
    return date <= selectedStartDate || date > endDate
  }
  const shouldDisableStartDate = (date: Date) => {
    return date >= selectedEndDate || date < startDate
  }

  const handleStartDateChange = (date: Date) => {
    if (date <= endDate && date >= startDate) {
      setSelectedStartDate(date)
    } else {
      alert('Start date must be within the snapshot range!')
    }
  }

  const handleEndDateChange = (date: Date) => {
    if (date >= startDate && date <= endDate) {
      setSelectedEndDate(date)
    } else {
      alert('End date must be within the snapshot range!')
    }
  }
  useEffect(() => {
    setIsSalesRatePeriodValid(
      snapshotDetails?.snapshotLength
        ? salesRatePeriod <= snapshotDetails?.snapshotLength
        : true
    )
  }, [salesRatePeriod, snapshotDetails?.snapshotLength])

  useEffect(() => {
    setIsPrimaryButtonDisabled(!isSalesRatePeriodValid)
  }, [isSalesRatePeriodValid, setIsPrimaryButtonDisabled])

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.formWrapper}>
        <Box className={classes.formControl}>
          <label htmlFor={salesRateTypeLabel}>
            <Typography className={classes.label} variant="h4">
              {salesRateTypeLabel}
            </Typography>
          </label>
          <DropDown
            id={salesRateTypeLabel}
            label={salesRateTypeLabel}
            menuItems={SALES_RATE_TYPE}
            value={salesRateType}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'salesRateType',
                value: e.target.value as string,
              })
            }
            className={classes.select}
          />
        </Box>
        <Box className={classes.calendarContainer}>
          <label htmlFor={salesRatePeriodLabel}>
            <Typography className={classes.label} variant="h4">
              {salesRatePeriodLabel}
            </Typography>
          </label>
          {/*
          <Input
            id={salesRatePeriodLabel}
            value={salesRatePeriod}
            onChange={handleSalesRatePeriodChange}
            className={classes.input}
            error={!isSalesRatePeriodValid}
            helperText={
              !isSalesRatePeriodValid
                ? 'Sales rate period cannot be greater than the number of weeks in the snapshot'
                : ''
            }
          />
            * */}
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <div className={classes.calendarItem}>
              <CalendarInput
                label="Start date"
                format="dd-MM-yyyy"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                shouldDisableDate={shouldDisableStartDate} // Disable invalid dates
              />
            </div>
            <div className={classes.calendarItem}>
              <CalendarInput
                label="End date"
                format="dd-MM-yyyy"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                shouldDisableDate={shouldDisableEndDate} // Disable invalid dates
              />
            </div>
          </div>
        </Box>
        <Box>
          <label htmlFor={minBatchesPerCycleLabel}>
            <Typography className={classes.label} variant="h4">
              {minBatchesPerCycleLabel}
            </Typography>
          </label>
          <DropDown
            id={minBatchesPerCycleLabel}
            label={minBatchesPerCycleLabel}
            menuItems={MIN_AVG_BATCHES_PER_CYCLE}
            value={minBatchesPerCycle}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'minimumAverageBatchesPerProductionCycle',
                value: e.target.value as number,
              })
            }
            className={classes.select}
          />
        </Box>

        <Box ml={3}>
          <label htmlFor={planTemplateBasedOnLabel}>
            <Typography className={classes.label} variant="h4">
              {planTemplateBasedOnLabel}
            </Typography>
          </label>
          <DropDown
            id={planTemplateBasedOnLabel}
            label={planTemplateBasedOnLabel}
            menuItems={PLAN_TEMPLETE_BASED_ON_OPTIONS}
            value={planTemplateBasedOn}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'templateBasedOn',
                value: e.target.value as string,
              })
            }
            className={classes.select}
          />
        </Box>

        <Box ml={3}>
          <label htmlFor={planTemplateBasedOnLabel}>
            <Typography className={classes.label} variant="h4">
              Settings Options
            </Typography>
          </label>
          <DropDown
            id="Settings Options"
            label="Settings Options"
            menuItems={SETTINGS_OPTIONS}
            value={settingsOption}
            onChange={(e) =>
              updateWorkcentreSettingsState({
                step: WORKCENTER_STEPS.SETTINGS,
                key: 'settingsOptions',
                value: e.target.value as string,
              })
            }
            className={classes.select}
          />
        </Box>
        <Box className={classes.button}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleApplyDefaultsClick}
          >
            Apply default settings
          </Button>
        </Box>
      </Box>
      <Box className={classes.snapshotDates}>
        {snapshotDetails && (
          <Typography>{getSnapshotDetailsInString(snapshotDetails)}</Typography>
        )}
      </Box>
    </Box>
  )
}
